import React, { useReducer, useContext } from 'react';
import axios from 'axios';
import AppContext from './appContext';
import appReducer from './appReducer';
import TaskContext from '../tasks/taskContext';
import Email from '../../components/templates/email';
import FosterEmail from '../../components/templates/fosterEmail';

import {
  PREV_PAGE,
  NEXT_PAGE,
  GO_HOME,
  GET_APPS,
  GET_APP,
  APP_TAB,
  DELETE_APP,
  CHECK_CAPTCHA,
} from '../types';

function formatDate(string) {
  var options = {
    year: 'numeric',
    month: 'numeric',
    day: 'numeric',
  };
  return new Date(string).toLocaleDateString([], options);
}

const AppState = (props) => {
  const initialstate = {
    app: null,
    apps: null,
    current: null,
    isVerified: false,
    submitComplete: false,
    loading: true,
    step: 1,
    appPageID: 'profile',
  };
  const taskContext = useContext(TaskContext);
  const { createTask } = taskContext;

  const [state, dispatch] = useReducer(appReducer, initialstate);

  const config = {
    headers: {
      'Content-Type': 'application/json',
    },
  };

  // SUBMIT APPLICATION
  const submitApp = async (data) => {
    // ! ********* SUBMIT APP IS BUILT INTO SINGLE PAGE FORM ____ DON'T EDIT HERE *********
    await axios.post('/api/createJSON', data, config);

    if (data.Application_Type === 'Adoption') {
      const message = Email(data);
      axios.post('/api/createPDF', data, config);
      axios.post('/api/sendEmail', message, config);
      axios.post('/api/thankyouAdoption', data, config);
    } else {
      const message = FosterEmail(data);
      axios.post('/api/createFosterPDF', data, config);
      axios.post('/api/sendEmail', message, config);
      axios.post('/api/thankyouFoster', data, config);
    }
    try {
      const dbResults = await axios.post('/api/database', data, config);
      createTask(dbResults.data._id);
      if (data.Application_Type === 'Adoption') {
        const fileData = {
          appID: dbResults.data._id,
          data: data,
        };
        await axios.post('/api/attachApplication', fileData, config);
      } else {
        const fileData = {
          appID: dbResults.data._id,
          data: data,
        };
        await axios.post('/api/attachFosterApplication', fileData, config);
      }
    } catch (err) {
      console.log(err);
    }
  };

  // Get APPS List
  const getApps = async () => {
    try {
      const res = await axios.get('/api/database');
      dispatch({
        type: GET_APPS,
        payload: res.data,
      });
    } catch (err) {
      console.log(err);
    }
  };

  // Get APP

  const getApp = async (id) => {
    try {
      const res = await axios.get(`/api/database/${id}`);
      const formatedDate = formatDate(res.data.submit_date);
      res.data.submit_date = formatedDate;
      dispatch({
        type: GET_APP,
        payload: res.data,
      });
    } catch (err) {
      console.log(err);
    }
  };

  // UPDATE APP
  const updateApp = async (id, data) => {
    try {
      axios.put(`/api/database/${id}`, data, config);
    } catch (err) {
      console.log(err);
    }
  };

  // DELETE APP

  const deleteApp = async (id) => {
    await axios.delete(`/api/database/${id}`);
    dispatch({
      type: DELETE_APP,
      payload: id,
    });
  };

  // Proceed to next step
  const nextStep = (step) => {
    dispatch({
      type: NEXT_PAGE,
      payload: step,
    });
  };

  // Go back to prev step
  const prevStep = () => {
    dispatch({
      type: PREV_PAGE,
    });
  };

  const goHome = () => {
    dispatch({
      type: GO_HOME,
    });
  };

  const addStep = () => {
    console.log('CLICKED');
  };

  const appTab = (tab) => {
    dispatch({
      type: APP_TAB,
      payload: tab,
    });
  };

  const checkCaptcha = async (data) => {
    const resBody = {
      response: data,
    };
    try {
      const res = await axios.post('/api/captcha', resBody, config);
      dispatch({
        type: CHECK_CAPTCHA,
        payload: res.data,
      });
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <AppContext.Provider
      value={{
        app: state.app,
        apps: state.apps,
        current: state.current,
        submitComplete: state.submitComplete,
        step: state.step,
        loading: state.loading,
        appPageID: state.appPageID,
        isVerified: state.isVerified,
        submitApp,
        prevStep,
        nextStep,
        addStep,
        getApps,
        goHome,
        getApp,
        appTab,
        deleteApp,
        checkCaptcha,
        updateApp,
      }}
    >
      {props.children}
    </AppContext.Provider>
  );
};

export default AppState;
