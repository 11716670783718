import React, { useReducer } from 'react';
import axios from 'axios';
import PetfinderContext from './petfinderContext';
import PetfinderReducer from './petfinderReducer';
import { GET_PETS, VIEW_PET } from '../types';

const PetfinderState = (props) => {
  const initalState = {
    loadingPets: true,
    pets: [],
    pet: {},
    step: 1,
  };

  const [state, dispatch] = useReducer(PetfinderReducer, initalState);

  //GET PPR ADOPTABLE PETS
  const getPets = () => {
    const config = {
      headers: { Authorization: 'Bearer ' + localStorage.getItem('token') },
    };

    axios
      .get(
        'https://api.petfinder.com/v2/animals?organization=MD411&status=adoptable&limit=100',
        config
      )
      .then(function (res) {
        dispatch({
          type: GET_PETS,
          payload: res.data.animals,
        });
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  //VIEW PPR PET'S DETAIL PAGE
  const viewPets = (step) => {
    dispatch({
      type: VIEW_PET,
    });
  };

  return (
    <PetfinderContext.Provider
      value={{
        pets: state.pets,
        pet: state.pet,
        step: state.step,
        loadingPets: state.loadingPets,
        getPets,
        viewPets,
      }}
    >
      {props.children}
    </PetfinderContext.Provider>
  );
};

export default PetfinderState;
