const FosterEmail = data => {
  const htmlMessage = `
  <br>
  A New Foster Application have been Submitted 
  <br>
  <br>
  
  FOSTER INFORMATION: <br>
  Name: ${data.First_Name} ${data.Last_Name}<br>
  Phone: ${data.Phone}<br>
  Email: ${data.Email}<br>
  
  
  `;
  return {
    subject: 'New Foster Application',
    html: htmlMessage,
    text: 'See Attached Foster Application',
    attachments: [
      {
        filename: data.Last_Name + ' Foster App.pdf',
        path: './pdf_files/' + data.Last_Name + ' Foster App.pdf'
      }
    ]
  };
};

export default FosterEmail;
