import React, { Fragment, useEffect } from 'react';

export const FormAdoptHousehold = (values) => {
  const { application, onChange, cont, prev, handleCheckedBox } = values;
  const {
    House,
    Condo,
    Townhouse,
    Mobile_Home,
    Apartment,
    Own_Rent,
    Pets_Permitted,
    Landlord_Name,
    Landlord_Phone,
    Lived_Length_Home,
    Moving,
    When_Moving,
    If_Move,
    Kept_Where,
    Fence,
    Fence_Size,
    Alone_Time,
    Location_When_Home,
    Location_When_Not_Home,
    Location_When_Traveling,
    Location_When_Sleeping,
    Pet_Food,
    Convictions,
  } = application;

  const on_change = (e) => {
    onChange(e);
  };

  const next_page = (e) => {
    e.preventDefault();
    cont();
    window.scrollTo(0, 0);
  };
  const prev_page = (e) => {
    e.preventDefault();
    prev();
    window.scrollTo(0, 0);
  };

  let isChecked = (e) => {
    handleCheckedBox(e.target);
  };

  window.history.pushState({}, 'Adopt', '/adopt');

  useEffect(() => {
    window.onpopstate = function (ev) {
      prev();
    };
  }, [prev]);

  return (
    <Fragment>
      <div>
        <h4 className="text-center mt-2">ADOPTION APPLICATION</h4>
      </div>
      <div className="container bg-white border border-dark my-1">
        {/* ******************************************* */}
        <div className="container-fluid my-1 border border-dark">
          <div className="row bg-dark">
            <h6 className="text-center text-light align-middle my-1 col-lg-12">
              HOUSEHOLD INFORMATION
            </h6>
          </div>
          <br />
          <div className="row">
            <p className="col-lg-3 col-xl-2">Do you live in a:</p>
          </div>

          <div className="row mx-2">
            <div className="form-check col-lg-2 col-xl-1">
              <input
                type="checkbox"
                className="form-check-input"
                id="House"
                value={House}
                checked={House}
                onChange={isChecked}
              />
              <label className="form-check-label p-0 ml-1" htmlFor="House">
                House
              </label>
            </div>
            <div className="form-check  col-lg-3 col-xl-2">
              <input
                type="checkbox"
                className="form-check-input"
                id="Condo"
                value={Condo}
                checked={Condo}
                onChange={isChecked}
              />
              <label className="form-check-label p-0 ml-1" htmlFor="Condo">
                Condo
              </label>
            </div>
            <div className="form-check  col-lg-3 col-xl-2">
              <input
                type="checkbox"
                className="form-check-input"
                id="Townhouse"
                value={Townhouse}
                checked={Townhouse}
                onChange={isChecked}
              />
              <label className="form-check-label p-0 ml-1" htmlFor="Townhouse">
                Townhouse
              </label>
            </div>
            <div className="form-check  col-lg-2 col-xl-2">
              <input
                type="checkbox"
                className="form-check-input"
                id="Mobile_Home"
                value={Mobile_Home}
                checked={Mobile_Home}
                onChange={isChecked}
              />
              <label
                className="form-check-label p-0 ml-1"
                htmlFor="Mobile_Home"
              >
                Mobile Home
              </label>
            </div>
            <div className="form-check  col-lg-2 col-xl-2">
              <input
                type="checkbox"
                className="form-check-input"
                id="Apartment"
                value={Apartment}
                checked={Apartment}
                onChange={isChecked}
              />
              <label className="form-check-label p-0 ml-1" htmlFor="Apartment">
                Apartment
              </label>
            </div>
          </div>
          <br />
          <div className="row">
            <div className="form-group col-11 col-sm-11 col-md-11 col-lg-11 col-xl-3 pr-0">
              <label htmlFor="Own_Rent">Do you own or rent your home?</label>
              <select
                id="Own_Rent"
                className="form-control"
                onChange={on_change}
                value={Own_Rent}
              >
                <option value="not_answered">Select</option>
                <option>Own</option>
                <option>Rent</option>
              </select>
            </div>
            <div className="form-group col-11 col-sm-11 col-md-11 col-lg-11 col-xl-3 pr-0">
              <label htmlFor="Pets_Permitted">
                If rent, are pets permitted?
              </label>
              <select
                id="Pets_Permitted"
                className="form-control"
                onChange={on_change}
                value={Pets_Permitted}
              >
                <option value="not_answered">Select</option>
                <option>Yes</option>
                <option>No</option>
              </select>
            </div>
            <div className="form-group col-11 col-sm-11 col-md-11 col-lg-11 col-xl-3 pr-0">
              <label htmlFor="Landlord_Name">Landlord/ Rental Agent Name</label>
              <input
                id="Landlord_Name"
                type="text"
                className="form-control"
                value={Landlord_Name}
                onChange={on_change}
                maxLength="100"
              />
            </div>
            <div className="form-group col-11 col-sm-11 col-md-11 col-lg-11 col-xl-2 pr-0">
              <label htmlFor="Landlord_Phone">Phone</label>
              <input
                id="Landlord_Phone"
                type="phone"
                className="form-control"
                value={Landlord_Phone}
                onChange={on_change}
                maxLength="20"
              />
            </div>
          </div>
          <div className="row">
            <div className="form-group col-11 col-sm-11 col-md-11 col-lg-11 col-xl-5 pr-0">
              <label htmlFor="Lived_Length_Home">
                How long have you lived at your present address?
              </label>
              <input
                id="Lived_Length_Home"
                type="text"
                className="form-control"
                value={Lived_Length_Home}
                onChange={on_change}
                maxLength="20"
              />
            </div>
          </div>
          <div className="row">
            <div className="form-group col-11 col-sm-11 col-md-11 col-lg-11 col-xl-5 pr-0">
              <label htmlFor="Moving">
                Are you planning to move in the next 6 months?
              </label>
              <select
                id="Moving"
                className="form-control"
                onChange={on_change}
                value={Moving}
              >
                <option value="not_answered">Select</option>
                <option>Yes</option>
                <option>No</option>
              </select>
            </div>
            <div className="form-group col-11 col-sm-11 col-md-11 col-lg-11 col-xl-5 pr-0">
              <label htmlFor="When_Moving">If yes, when</label>
              <input
                id="When_Moving"
                type="text"
                className="form-control"
                value={When_Moving}
                onChange={on_change}
                maxLength="250"
              />
            </div>
          </div>
          <div className="row">
            <div className="form-group col-11 col-sm-11 col-md-11 col-lg-11 col-xl-5 pr-0">
              <label htmlFor="If_Move">
                What will you do with your pet when/if you move?
              </label>
              <input
                id="If_Move"
                type="text"
                className="form-control"
                value={If_Move}
                onChange={on_change}
                maxLength="250"
              />
            </div>
            <div className="form-group col-11 col-sm-11 col-md-11 col-lg-11 col-xl-5 pr-0">
              <label htmlFor="Kept_Where">
                Will your pet be kept indoors or outdoors?
              </label>
              <select
                id="Kept_Where"
                className="form-control"
                onChange={on_change}
                value={Kept_Where}
              >
                <option value="not_answered">Select</option>
                <option>Inside</option>
                <option>Outside</option>
              </select>
            </div>
          </div>
          <div className="row">
            <div className="form-group col-11 col-sm-11 col-md-11 col-lg-11 col-xl-5 pr-0">
              <label htmlFor="Fence">What type yard do you have?</label>
              <select
                id="Fence"
                className="form-control"
                onChange={on_change}
                value={Fence}
              >
                <option value="not_answered">Select</option>
                <option>Fenced Yard</option>
                <option>Outside dog pen or kennel</option>
                <option>Neither</option>
              </select>
            </div>
            <div className="form-group col-11 col-sm-11 col-md-11 col-lg-11 col-xl-6 pr-0">
              <label htmlFor="Fence_Size">
                If fence/pen/kennel, please describe area size, material used,
                height
              </label>
              <input
                id="Fence_Size"
                type="text"
                className="form-control"
                value={Fence_Size}
                onChange={on_change}
                maxLength="250"
              />
            </div>
          </div>
          <div className="row">
            <div className="form-group col-11 col-sm-11 col-md-11 col-lg-11 col-xl-6 pr-0">
              <label htmlFor="Location_When_Home">
                Where will your pet spend most of his/her time when you are
                home?
              </label>
              <input
                id="Location_When_Home"
                type="text"
                className="form-control"
                value={Location_When_Home}
                onChange={on_change}
                maxLength="250"
              />
            </div>
            <div className="form-group col-11 col-sm-11 col-md-11 col-lg-11 col-xl-5 pr-0">
              <label htmlFor="Alone_Time">
                How many hours will your pet be alone during the day?
              </label>
              <input
                id="Alone_Time"
                type="text"
                className="form-control"
                value={Alone_Time}
                onChange={on_change}
                maxLength="20"
              />
            </div>
          </div>
          <div className="row">
            <div className="form-group col-11 col-sm-11 col-md-11 col-lg-11 col-xl-6 pr-0">
              <label htmlFor="Location_When_Not_Home">
                Where will you keep your pet when you are not home during the
                day?
              </label>
              <input
                id="Location_When_Not_Home"
                type="text"
                className="form-control"
                value={Location_When_Not_Home}
                onChange={on_change}
                maxLength="250"
              />
            </div>
            <div className="form-group col-11 col-sm-11 col-md-11 col-lg-11 col-xl-5 pr-0">
              <label htmlFor="Location_When_Traveling">
                Where will you keep your pet when you travel?
              </label>
              <input
                id="Location_When_Traveling"
                type="text"
                className="form-control"
                value={Location_When_Traveling}
                onChange={on_change}
                maxLength="250"
              />
            </div>
          </div>
          <div className="row">
            <div className="form-group col-11 col-sm-11 col-md-11 col-lg-11 col-xl-4 pr-0">
              <label htmlFor="Location_When_Sleeping">
                Where will your pet sleep at night?
              </label>
              <input
                id="Location_When_Sleeping"
                type="text"
                className="form-control"
                value={Location_When_Sleeping}
                onChange={on_change}
                maxLength="250"
              />
            </div>
            <div className="form-group col-11 col-sm-11 col-md-11 col-lg-11 col-xl-7 pr-0">
              <label htmlFor="Pet_Food">
                What have you fed your prior or current dogs or will you feed
                your new dog?
              </label>
              <input
                id="Pet_Food"
                type="text"
                className="form-control"
                value={Pet_Food}
                onChange={on_change}
                maxLength="100"
              />
            </div>
          </div>
          <div className="row">
            <div className="form-group col-11 col-sm-11 col-md-11 col-lg-11 col-xl-11 pr-0">
              <label htmlFor="Convictions">
                To the best of your knowledge, has any member of your household
                ever been convicted of an animal welfare law violation such as
                neglect, cruelty, abandonment, etc.?{' '}
              </label>
              <select
                id="Convictions"
                className="form-control"
                onChange={on_change}
                value={Convictions}
              >
                <option value="not_answered">Select</option>
                <option>YES</option>
                <option>NO</option>
              </select>
            </div>
          </div>
        </div>

        {/* ****************BUTTONS ARE BELOW HERE*********** */}
        <div className="container">
          <div className="row">
            <button
              className="btn btn-danger col-lg-2 mb-3"
              onClick={prev_page}
            >
              <i className="fa fa-arrow-left p" aria-hidden="true" />
              Previous
            </button>
            <div className="col-lg-8"></div>
            <button
              type="button"
              className="btn btn-dark col-lg-2  mb-3"
              onClick={next_page}
            >
              Continue
              <i className="fa fa-arrow-right p" aria-hidden="true" />
            </button>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default FormAdoptHousehold;
