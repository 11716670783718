import React, { Fragment, useEffect } from 'react';

export const FormAdoptPrep = (values) => {
  const { application, onChange, cont, prev, handleCheckedBox } = values;
  const {
    Preperations,
    Grooming,
    Allergies,
    Training,
    If_Allergies,
    Normal_Behavior,
    Acclimate,
    Introductions,
    Exercise,
    Housebreak,
    Corrections,
    Encourage,
    Lifetime_Commitment,
    Will_Give_Preventatives,
    Medical_Care,
    Costs,
    Expense,
    Divorce,
    Move_Out_Of_State,
    Move_No_Pets,
    Barks,
    Bills,
    Partner_Allergies,
    Baby,
    Un_Trainable,
    Pet_Incompatible,
    Bladder_Issues,
    Neighbors,
    Chronic_Illness,
    Pet_Not_As_Expected,
    Nothing_Applies,
    If_Die,
    Other_Reasons,
    Other_Checkbox,
  } = application;

  const on_change = (e) => {
    onChange(e);
  };

  const next_page = (e) => {
    e.preventDefault();
    cont();
    window.scrollTo(0, 0);
  };
  const prev_page = (e) => {
    e.preventDefault();
    prev();
    window.scrollTo(0, 0);
  };

  let isChecked = (e) => {
    handleCheckedBox(e.target);
  };

  window.history.pushState({}, 'Adopt', '/adopt');

  useEffect(() => {
    window.onpopstate = function (ev) {
      prev();
    };
  }, [prev]);

  return (
    <Fragment>
      <div>
        <h4 className="text-center mt-2">ADOPTION APPLICATION</h4>
      </div>
      <div className="container bg-white border border-dark my-1">
        {/* ******************************************* */}
        <div className="row bg-dark">
          <h6 className="text-center text-light align-middle my-1 col-lg-12">
            PREPARING FOR YOUR NEW DOG OR PUPPY
          </h6>
        </div>
        <div className="row pt-2">
          <div className="form-group col-11 col-sm-11 col-md-11 col-lg-11 col-xl-11">
            <label className="active" htmlFor="Preperations">
              What have you done to prepare yourself and your family for the
              adoption of a new pet?
            </label>
            <textarea
              id="Preperations"
              type="text"
              className="form-control"
              rows="4"
              value={Preperations}
              onChange={on_change}
            />
          </div>
        </div>

        <div className="row">
          <div className="form-group col-11 col-sm-11 col-md-11 col-lg-11 col-xl-11 pr-0">
            <label htmlFor="Grooming">
              What level of grooming responsibility will you accept in
              association with your new pet?
            </label>
            <input
              id="Grooming"
              type="text"
              className="form-control"
              value={Grooming}
              onChange={on_change}
              maxLength="100"
            />
          </div>
        </div>
        <div className="row">
          <div className="form-group col-11 col-sm-11 col-md-11 col-lg-11 col-xl-11 pr-0">
            <label htmlFor="Allergies">
              Does anyone in your household have allergies to dander / hair/
              saliva?
            </label>
            <select
              id="Allergies"
              className="form-control"
              onChange={on_change}
              value={Allergies}
            >
              <option value="not_answered">Select</option>
              <option>YES</option>
              <option>NO</option>
            </select>
          </div>
        </div>
        <div className="row">
          <div className="form-group col-11 col-sm-11 col-md-11 col-lg-11 col-xl-11 pr-0">
            <label htmlFor="If_Allergies">
              If If_Allergies exist/develop, are you willing to take actions to
              keep the dog?
            </label>
            <select
              id="If_Allergies"
              className="form-control"
              onChange={on_change}
              value={If_Allergies}
            >
              <option value="not_answered">Select</option>
              <option>YES</option>
              <option>NO</option>
            </select>
          </div>
        </div>
        <div className="row">
          <div className="form-group col-11 col-sm-11 col-md-11 col-lg-11 col-xl-11 pr-0">
            <label htmlFor="Training">
              Are you planning to attend obedience classes with your dog/puppy?
            </label>
            <select
              id="Training"
              className="form-control"
              onChange={on_change}
              value={Training}
            >
              <option value="not_answered">Select</option>
              <option>YES</option>
              <option>NO</option>
            </select>
          </div>
        </div>
        <div className="row">
          <div className="form-group col-11 col-sm-11 col-md-11 col-lg-11 col-xl-11 pr-0">
            <label htmlFor="Normal_Behavior">
              What do you consider to be normal dog/puppy behavior problems?
            </label>
            <input
              id="Normal_Behavior"
              type="text"
              className="form-control"
              value={Normal_Behavior}
              onChange={on_change}
              maxLength="100"
            />
          </div>
        </div>
        <div className="row">
          <div className="form-group col-11 col-sm-11 col-md-11 col-lg-11 col-xl-11 pr-0">
            <label htmlFor="Acclimate">
              How long do you feel it will take for a dog/puppy to acclimate to
              a new houshold?
            </label>
            <input
              id="Acclimate"
              type="text"
              className="form-control"
              value={Acclimate}
              onChange={on_change}
              maxLength="50"
            />
          </div>
        </div>
        <div className="row">
          <div className="form-group col-11 col-sm-11 col-md-11 col-lg-11 col-xl-11">
            <label className="active" htmlFor="Introductions">
              How will you introduce your new dog to your existing pet(s)?
            </label>
            <textarea
              id="Introductions"
              type="text"
              className="form-control"
              rows="4"
              value={Introductions}
              onChange={on_change}
            />
          </div>
        </div>
        <div className="row">
          <div className="form-group col-11 col-sm-11 col-md-11 col-lg-11 col-xl-11 pr-0">
            <label htmlFor="Exercise">
              How will you exercise the dog/puppy?
            </label>
            <input
              id="Exercise"
              type="text"
              className="form-control"
              value={Exercise}
              onChange={on_change}
              maxLength="100"
            />
          </div>
        </div>
        <div className="row">
          <div className="form-group col-11 col-sm-11 col-md-11 col-lg-11 col-xl-11 pr-0">
            <label htmlFor="Housebreak">
              How will you housebreak the dog/puppy?
            </label>
            <input
              id="Housebreak"
              type="text"
              className="form-control"
              value={Housebreak}
              onChange={on_change}
              maxLength="100"
            />
          </div>
        </div>
        <div className="row">
          <div className="form-group col-11 col-sm-11 col-md-11 col-lg-11 col-xl-11 pr-0">
            <label htmlFor="Corrections">
              How will you correct the dog/puppy when there is a potty accident?
            </label>
            <input
              id="Corrections"
              type="text"
              className="form-control"
              value={Corrections}
              onChange={on_change}
              maxLength="100"
            />
          </div>
        </div>
        <div className="row">
          <div className="form-group col-11 col-sm-11 col-md-11 col-lg-11 col-xl-11">
            <label className="active" htmlFor="Encourage">
              How will you encourage appropriate behavior and prevent
              inappropriate behavior?
            </label>
            <textarea
              id="Encourage"
              type="text"
              className="form-control"
              rows="4"
              value={Encourage}
              onChange={on_change}
            />
          </div>
        </div>
        <div className="row">
          <div className="form-group col-11 col-sm-11 col-md-11 col-lg-11 col-xl-11 pr-0">
            <label htmlFor="Lifetime_Commitment">
              Are you willing to make a lifetime commitment to this dog?
            </label>
            <select
              id="Lifetime_Commitment"
              className="form-control"
              onChange={on_change}
              value={Lifetime_Commitment}
            >
              <option value="not_answered">Select</option>
              <option>YES</option>
              <option>NO</option>
            </select>
          </div>
        </div>
        <div className="row">
          <div className="form-group col-11 col-sm-11 col-md-11 col-lg-11 col-xl-11 pr-0">
            <label htmlFor="Will_Give_Preventatives">
              Will you give heartworm tablets/flea and tick preventative every
              month all year round?
            </label>
            <select
              id="Will_Give_Preventatives"
              className="form-control"
              onChange={on_change}
              value={Will_Give_Preventatives}
            >
              <option value="not_answered">Select</option>
              <option>YES</option>
              <option>NO</option>
            </select>
          </div>
        </div>
        <div className="row">
          <div className="form-group col-11 col-sm-11 col-md-11 col-lg-11 col-xl-11 pr-0">
            <label htmlFor="Medical_Care">
              Will you provide all required vaccinations and medical care?
            </label>
            <select
              id="Medical_Care"
              className="form-control"
              onChange={on_change}
              value={Medical_Care}
            >
              <option value="not_answered">Select</option>
              <option>YES</option>
              <option>NO</option>
            </select>
          </div>
        </div>
        <div className="row">
          <div className="form-group col-11 col-sm-11 col-md-11 col-lg-11 col-xl-11 pr-0">
            <label htmlFor="Costs">
              How much do you think it costs, per year, to own a dog?
            </label>
            <input
              id="Costs"
              type="text"
              className="form-control"
              value={Costs}
              onChange={on_change}
              maxLength="50"
            />
          </div>
        </div>
        <div className="row">
          <div className="form-group col-11 col-sm-11 col-md-11 col-lg-11 col-xl-11 pr-0">
            <label htmlFor="Expense">
              Are you willing to spend the money necessary updating shots on
              time, spay/neutering, and emergency care for your pet?
            </label>
            <select
              id="Expense"
              className="form-control"
              onChange={on_change}
              value={Expense}
            >
              <option value="not_answered">Select</option>
              <option>YES</option>
              <option>NO</option>
            </select>
          </div>
        </div>

        {/* ******************************************************************************************** */}
        <div className="form-group col-11 col-sm-11 col-md-11 col-lg-11 col-xl-12 pr-0">
          <div className="row">
            <label htmlFor="Divorce">
              Which of the following would force you to give up your pet? (check
              all that may apply)
            </label>
          </div>
          <div className="row">
            <div className="form-check  col-xl-3">
              <input
                type="checkbox"
                className="form-check-input"
                id="Divorce"
                value={Divorce}
                checked={Divorce}
                onChange={isChecked}
              />
              <label className="form-check-label p-0 ml-1" htmlFor="Divorce">
                Divorce
              </label>
            </div>
            <div className="form-check   col-xl-3">
              <input
                type="checkbox"
                className="form-check-input"
                id="Move_Out_Of_State"
                value={Move_Out_Of_State}
                checked={Move_Out_Of_State}
                onChange={isChecked}
              />
              <label
                className="form-check-label p-0 ml-1"
                htmlFor="Move_Out_Of_State"
              >
                Move Out Of State
              </label>
            </div>
            <div className="form-check  col-xl-3">
              <input
                type="checkbox"
                className="form-check-input"
                id="Move_No_Pets"
                value={Move_No_Pets}
                checked={Move_No_Pets}
                onChange={isChecked}
              />
              <label
                className="form-check-label p-0 ml-1"
                htmlFor="Move_No_Pets"
              >
                Move where pets aren't allowed
              </label>
            </div>
            <div className="form-check col-xl-3">
              <input
                type="checkbox"
                className="form-check-input"
                id="Barks"
                value={Barks}
                checked={Barks}
                onChange={isChecked}
              />
              <label className="form-check-label p-0 ml-1" htmlFor="Barks">
                Barks a lot
              </label>
            </div>
            <div className="form-check   col-xl-3">
              <input
                type="checkbox"
                className="form-check-input"
                id="Bills"
                value={Bills}
                checked={Bills}
                onChange={isChecked}
              />
              <label className="form-check-label p-0 ml-1" htmlFor="Bills">
                Big vet bills
              </label>
            </div>
            <div className="form-check  col-xl-3">
              <input
                type="checkbox"
                className="form-check-input"
                id="Partner_Allergies"
                value={Partner_Allergies}
                checked={Partner_Allergies}
                onChange={isChecked}
              />
              <label
                className="form-check-label p-0 ml-1"
                htmlFor="Partner_Allergies"
              >
                Marry someone with allergies
              </label>
            </div>
            <div className="form-check col-xl-3">
              <input
                type="checkbox"
                className="form-check-input"
                id="Baby"
                value={Baby}
                checked={Baby}
                onChange={isChecked}
              />
              <label className="form-check-label p-0 ml-1" htmlFor="Baby">
                Having a baby
              </label>
            </div>
            <div className="form-check   col-xl-3">
              <input
                type="checkbox"
                className="form-check-input"
                id="Un_Trainable"
                value={Un_Trainable}
                checked={Un_Trainable}
                onChange={isChecked}
              />
              <label
                className="form-check-label p-0 ml-1"
                htmlFor="Un_Trainable"
              >
                Un-Trainable
              </label>
            </div>
            <div className="form-check  col-xl-3">
              <input
                type="checkbox"
                className="form-check-input"
                id="Pet_Incompatible"
                value={Pet_Incompatible}
                checked={Pet_Incompatible}
                onChange={isChecked}
              />
              <label
                className="form-check-label p-0 ml-1"
                htmlFor="Pet_Incompatible"
              >
                Doesn't get along with other pet
              </label>
            </div>
            <div className="form-check col-xl-3">
              <input
                type="checkbox"
                className="form-check-input"
                id="Bladder_Issues"
                value={Bladder_Issues}
                checked={Bladder_Issues}
                onChange={isChecked}
              />
              <label
                className="form-check-label p-0 ml-1"
                htmlFor="Bladder_Issues"
              >
                Loses control of bladder
              </label>
            </div>
            <div className="form-check   col-xl-3">
              <input
                type="checkbox"
                className="form-check-input"
                id="Neighbors"
                value={Neighbors}
                checked={Neighbors}
                onChange={isChecked}
              />
              <label className="form-check-label p-0 ml-1" htmlFor="Neighbors">
                Neighbors complain
              </label>
            </div>
            <div className="form-check  col-xl-3">
              <input
                type="checkbox"
                className="form-check-input"
                id="Pet_Not_As_Expected"
                value={Pet_Not_As_Expected}
                checked={Pet_Not_As_Expected}
                onChange={isChecked}
              />
              <label
                className="form-check-label p-0 ml-1"
                htmlFor="Pet_Not_As_Expected"
              >
                Pet is not the kind of pet I thought it would be
              </label>
            </div>
            <div className="form-check   col-xl-3">
              <input
                type="checkbox"
                className="form-check-input"
                id="Chronic_Illness"
                value={Chronic_Illness}
                checked={Chronic_Illness}
                onChange={isChecked}
              />
              <label
                className="form-check-label p-0 ml-1"
                htmlFor="Chronic_Illness"
              >
                Pet develops chronic illness
              </label>
            </div>

            <div className="form-check   col-xl-3">
              <input
                type="checkbox"
                className="form-check-input"
                id="Nothing_Applies"
                value={Nothing_Applies}
                checked={Nothing_Applies}
                onChange={isChecked}
              />
              <label
                className="form-check-label p-0 ml-1"
                htmlFor="Nothing_Applies"
              >
                Nothing here applies
              </label>
            </div>
            <div className="form-check   col-xl-6">
              <input
                type="checkbox"
                className="form-check-input"
                id="Other_Checkbox"
                value={Other_Checkbox}
                checked={Other_Checkbox}
                onChange={isChecked}
              />
              <label>Other:</label>
              <textarea
                id="Other_Reasons"
                rows="3"
                type="text"
                className="form-control col-xl-12"
                value={Other_Reasons}
                onChange={on_change}
                maxLength="2000"
              />
            </div>
          </div>
        </div>
        <div className="row">
          <div className="form-group col-11 col-sm-11 col-md-11 col-lg-11 col-xl-11 pr-0">
            <label htmlFor="If_Die">
              In the event that you become very ill or die, what plans do you
              have for your dog?
            </label>
            <input
              id="If_Die"
              type="text"
              className="form-control"
              value={If_Die}
              onChange={on_change}
              maxLength="250"
            />
          </div>
        </div>
        {/* ****************BUTTONS ARE BELOW HERE*********** */}
        <div className="container">
          <div className="row">
            <button
              className="btn btn-danger col-lg-2 mt-3 mb-3"
              onClick={prev_page}
            >
              <i className="fa fa-arrow-left p" aria-hidden="true" />
              Previous
            </button>
            <div className="col-lg-8"></div>
            <button
              type="button"
              className="btn btn-dark col-lg-2 mt-3 mb-3"
              onClick={next_page}
            >
              Continue
              <i className="fa fa-arrow-right p" aria-hidden="true" />
            </button>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default FormAdoptPrep;
