import React, { Fragment, useContext, useEffect } from 'react';
import BlogContext from '../../context/blog/blogContext';
import ReactHtmlParser from 'react-html-parser';

const BlogViewer = props => {
  const blogContext = useContext(BlogContext);
  const { viewBlog, current, loading } = blogContext;

  useEffect(() => {
    viewBlog(props.match.params.id);

    // eslint-disable-next-line
  }, []);

  const back = () => {
    props.history.push('/blog');
  };

  window.scrollTo(0, 0);
  return (
    <Fragment>
      {current !== null && !loading ? (
        <div className="container">
          <button className="btn btn-danger mb-2" onClick={back}>
            BACK
          </button>
          <div className="card p-4 mb-2">
            <h1>{current.title}</h1>
            <h6>{current.author}</h6>
            <h6>{current.post_date}</h6>
            <p>{ReactHtmlParser(current.post)}</p>
          </div>
        </div>
      ) : (
        <h4>Loading</h4>
      )}
    </Fragment>
  );
};

export default BlogViewer;
