import React, { useReducer } from 'react';
import axios from 'axios';
import BlogContext from './blogContext';
import blogReducer from './blogReducer';
import {
  CREATE_BLOG,
  VIEW_BLOG,
  EDIT_BLOG,
  DELETE_BLOG,
  CLEAR_CURRENT,
  GET_BLOGS
} from '../types';

function formatDate(string) {
  var options = {
    year: 'numeric',
    month: 'numeric',
    day: 'numeric'
  };
  return new Date(string).toLocaleDateString([], options);
}

const BlogState = props => {
  const initialstate = {
    blogs: null,
    current: null,
    clearCurrent: null
  };

  const [state, dispatch] = useReducer(blogReducer, initialstate);

  const config = {
    headers: {
      'Content-Type': 'application/json'
    }
  };

  // Get Blogs
  const getBlogs = async () => {
    try {
      const res = await axios.get('/api/blog');
      dispatch({
        type: GET_BLOGS,
        payload: res.data
      });
    } catch (err) {
      console.log(err);
    }
  };

  // Create Blog
  const createBlog = async data => {
    axios.post('/api/blog', data, config);
    try {
      dispatch({
        type: CREATE_BLOG,
        payload: data
      });
    } catch (err) {
      console.log(err);
    }
  };
  const viewBlog = async e => {
    try {
      const res = await axios.get(`/api/blog/${e}`);
      const formatedDate = formatDate(res.data.post_date);
      const updatedData = {
        _id: res.data._id,
        title: res.data.title,
        post: res.data.post,
        author: res.data.author,
        post_date: formatedDate
      };
      dispatch({
        type: VIEW_BLOG,
        payload: updatedData
      });
    } catch (err) {
      console.log(err);
    }
  };

  const editBlog = e => {
    try {
      axios.put(`/api/blog/${e._id}`, e, config);
      dispatch({
        type: EDIT_BLOG,
        payload: e.data
      });
    } catch (err) {
      console.log(err);
    }
  };

  const deleteBlog = e => {
    try {
      axios.delete(`/api/blog/${e}`, e);
      dispatch({
        type: DELETE_BLOG,
        payload: e
      });
    } catch (err) {
      console.log(err);
    }
  };

  const clearCurrent = e => {
    dispatch({
      type: CLEAR_CURRENT
    });
  };

  return (
    <BlogContext.Provider
      value={{
        blogs: state.blogs,
        current: state.current,
        clearCurrent,
        createBlog,
        editBlog,
        deleteBlog,
        viewBlog,
        getBlogs
      }}
    >
      {props.children}
    </BlogContext.Provider>
  );
};

export default BlogState;
