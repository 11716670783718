export const GET_PETS = 'GET_PETS';
export const VIEW_PET = 'VIEW_PET';
export const SUBMIT_APP = 'SUBMIT_APP';
export const SUBMIT_FAPP = 'SUBMIT_FAPP';
export const SET_CURRENT = 'SET_CURRENT';
export const NEXT_PAGE = 'NEXT_PAGE';
export const PREV_PAGE = 'PREV_PAGE';
export const GO_HOME = 'GO_HOME';
export const SET_ALERT = 'SET_ALERT';
export const REMOVE_ALERT = 'REMOVE_ALERT';
export const CREATE_BLOG = 'CREATE_BLOG';
export const EDIT_BLOG = 'EDIT_BLOG';
export const DELETE_BLOG = 'DELETE_BLOG';
export const CLEAR_CURRENT = 'CLEAR_CURRENT';
export const GET_BLOGS = 'GET_BLOGS';
export const VIEW_BLOG = 'VIEW_BLOG';
export const REGISTER_SUCCESS = 'REGISTER_SUCCESS';
export const REGISTER_FAIL = 'REGISTER_FAIL';
export const USER_LOADED = 'USER_LOADED';
export const AUTH_ERROR = 'AUTH_ERROR';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_FAIL = 'LOGIN_FAIL';
export const LOGOUT = 'LOGOUT';
export const CLEAR_ERRORS = 'CLEAR_ERRORS';
export const LOAD_BLOG_PAGE = 'LOAD_BLOG_PAGE';
export const LOAD_DASHBOARD = 'LOAD_DASHBOARD';
export const LOAD_USER_PAGE = 'LOAD_USER_PAGE';
export const LOAD_APPLICATIONS_PAGE = 'LOAD_APPLICATIONS_PAGE';
export const LOAD_CREATE_BLOG_PAGE = 'LOAD_CREATE_BLOG_PAGE';
export const CREATE_USER = 'CREATE_USER';
export const EDIT_USER = 'EDIT_USER';
export const DELETE_USER = 'DELETE_USER';
export const GET_USER = 'GET_USER';
export const GET_USERS = 'GET_USERS';
export const LOAD_CREATE_USER_PAGE = 'LOAD_CREATE_USER_PAGE';
export const LOAD_EDIT_USER_PAGE = 'LOAD_EDIT_USER_PAGE';
export const LOAD_USER_PROFILE = 'LOAD_USER_PROFILE';
export const LOAD_USER_ID = 'LOAD_USER_ID';
export const LOAD_EDIT_BLOG_PAGE = 'LOAD_EDIT_BLOG_PAGE';
export const LOAD_BLOG_ID = 'LOAD_BLOG_ID';
export const GET_APPS = 'GET_APPS';
export const LOAD_APPLICATION_PAGE = 'LOAD_APPLICATION_PAGE';
export const LOAD_APP_ID = 'LOAD_APP_ID';
export const GET_APP = 'GET_APP';
export const APP_TAB = 'APP_TAB';
export const DELETE_APP = 'DELETE_APP';
export const CHECK_CAPTCHA = 'CHECK_CAPTCHA';
export const CREATE_TASK = 'CREATE_TASK';
export const EDIT_TASK = 'EDIT_TASK';
export const DELETE_TASK = 'DELETE_TASK';
export const GET_TASKS = 'GET_TASKS';
export const GET_TASK = 'GET_TASK';
export const CREATE_NOTE = 'CREATE_NOTE';
export const EDIT_NOTE = 'EDIT_NOTE';
export const DELETE_NOTE = 'DELETE_NOTE';
export const GET_NOTES = 'GET_NOTES';
export const GET_NOTE = 'GET_NOTE';
export const SAVE_EMAIL = 'SAVE_EMAIL';
export const EDIT_EMAIL_SEND = 'EDIT_EMAIL_SEND';
export const EDIT_EMAIL = 'EDIT_EMAIL';
export const DELETE_EMAIL = 'DELETE_EMAIL';
export const GET_EMAILS = 'GET_EMAILS';
export const SEND_EMAIL = 'SEND_EMAIL';
