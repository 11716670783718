import React, { Fragment, useEffect } from 'react';

export const FormFosterSubmit = values => {
  const { application, onChange, submitApp, prev, showAlert } = values;
  const { Description } = application;

  const on_change = e => {
    onChange(e);
  };

  const prev_page = e => {
    e.preventDefault();
    prev();
  };

  const submission = e => {
    e.preventDefault();
    showAlert('THANK YOU! YOUR APPLICATION HAS BEEN SUBMITTED', 'success');
    window.scrollTo(0, 0);
    submitApp(application);
  };

  window.history.pushState({}, 'Foster', '/foster');

  useEffect(() => {
    window.onpopstate = function(ev) {
      prev();
    };
  }, [prev]);

  return (
    <Fragment>
      <div>
        <h4 className="text-center mt-2">FOSTER APPLICATION</h4>
      </div>
      <div className="container my-1 border border-dark">
        <div className="row bg-dark">
          <h6 className="text-center text-light align-middle my-1 col-lg-12">
            ADDITIONAL INFORMATION
          </h6>
        </div>
        <div className="container-fluid my-1 border border-dark">
          <div className="form-group mt-4">
            <label className="active" htmlFor="Description">
              <h6>
                Any other information you feel we should know in considering
                your application:
              </h6>
            </label>
            <textarea
              id="Description"
              type="text"
              className="form-control"
              rows="4"
              value={Description}
              onChange={on_change}
            />
          </div>
        </div>

        <div className="container-fluid my-1 border border-dark">
          <div className="row bg-dark">
            <h6 className="text-center text-light align-middle my-1 col-lg-12">
              ACKNOWLEDGEMENT
            </h6>
          </div>
          <p className="mt-3">
            By submitting this application, I acknowledge that I have answered
            all questions truthfully. Failure to provide truthful answers can
            result in the rejection of my application.
          </p>
        </div>

        {/* ****************BUTTONS ARE BELOW HERE*********** */}
        <div className="container">
          <div className="row">
            <button
              className="btn btn-danger col-lg-2 mt-3 mb-3"
              onClick={prev_page}
            >
              <i className="fa fa-arrow-left p" aria-hidden="true" />
              Previous
            </button>
            <div className="col-lg-8"></div>
            <button
              type="button"
              className="btn btn-warning col-lg-2 mt-3 mb-3"
              onClick={submission}
            >
              Submit
              <i className="fa fa-paper-plane-o p" aria-hidden="true" />
            </button>
          </div>
        </div>
      </div>
    </Fragment>
  );
};
export default FormFosterSubmit;
