import React, { Fragment, useEffect } from 'react';

export const FormAdoptSubmit = (values) => {
  const { application, onChange, submitApp, prev, showAlert } = values;
  const { Description, Adoption_Aware_Fee } = application;

  const on_change = (e) => {
    onChange(e);
  };

  const prev_page = (e) => {
    e.preventDefault();
    prev();
  };

  window.history.pushState({}, 'Adopt', '/adopt');

  useEffect(() => {
    window.onpopstate = function (ev) {
      prev();
    };
  }, [prev]);

  const submission = (e) => {
    e.preventDefault();
    showAlert('THANK YOU! YOUR APPLICATION HAS BEEN SUBMITTED', 'success');
    window.scrollTo(0, 0);
    submitApp(application);
  };

  return (
    <Fragment>
      <div>
        <h4 className="text-center mt-2">ADOPTION APPLICATION</h4>
      </div>
      <div className="container my-1 border border-dark">
        <div className="row bg-dark">
          <h6 className="text-center text-light align-middle my-1 col-lg-12">
            ADDITIONAL INFORMATION
          </h6>
        </div>
        <div className="form-group mt-4">
          <label className="active" htmlFor="Description">
            <h6>
              Any other information you feel we should know in considering your
              application:
            </h6>
          </label>
          <textarea
            id="Description"
            type="text"
            className="form-control"
            rows="4"
            value={Description}
            onChange={on_change}
          />
        </div>
        <div className="row">
          <div className="form-group col-11 col-sm-11 col-md-11 col-lg-11 col-xl-11 pr-0">
            <label htmlFor="Adoption_Aware_Fee">
              Are you aware that there is an adoption fee for the dog/puppy you
              are considering?
            </label>
            <select
              id="Adoption_Aware_Fee"
              className="form-control"
              onChange={on_change}
              value={Adoption_Aware_Fee}
            >
              <option value="not_answered">Select</option>sh
              <option>YES</option>
              <option>NO</option>
            </select>
          </div>
        </div>
      </div>
      <div className="container-fluid my-1 border border-dark">
        <div className="row bg-dark">
          <h6 className="text-center text-light align-middle my-1 col-lg-12">
            ACKNOWLEDGEMENT
          </h6>
        </div>
        <p className="mt-3">
          By submitting this application, I acknowledge that I have answered all
          questions truthfully. Failure to provide truthful answers can result
          in the rejection of my application or the forfeiture of this adopted
          pet to Puppy Paws Rescue. If I have not received a telephone call
          within two weeks from the date of this application from a Puppy Paws
          Rescue representative, I understand that my application has been
          declined or the dog that I have requested has been placed with another
          applicant.
        </p>
      </div>

      {/* ****************BUTTONS ARE BELOW HERE*********** */}
      <div className="container">
        <div className="row">
          <button
            className="btn btn-danger col-lg-2 mt-3 mb-3"
            onClick={prev_page}
          >
            <i className="fa fa-arrow-left p" aria-hidden="true" />
            Previous
          </button>
          <div className="col-lg-8"></div>
          <button
            type="button"
            className="btn btn-warning col-lg-2 mt-3 mb-3"
            onClick={submission}
          >
            Submit
            <i className="fa fa-paper-plane-o p" aria-hidden="true" />
          </button>
        </div>
      </div>
    </Fragment>
  );
};
export default FormAdoptSubmit;
