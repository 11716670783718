import React, { Fragment, useEffect } from 'react';

export const FormAdoptPetHistory = (values) => {
  const { application, onChange, cont, prev, handleCheckedBox } = values;
  const {
    Pet1_Age,
    Pet1_Breed,
    Pet1_Fixed,
    Pet1_How_long,
    Pet1_Kept,
    Pet1_Name,
    Pet1_Sex,
    Pet1_Vac,
    Pet1_WhereNow,
    Pet2_Age,
    Pet2_Breed,
    Pet2_Fixed,
    Pet2_How_long,
    Pet2_Kept,
    Pet2_Name,
    Pet2_Sex,
    Pet2_Vac,
    Pet2_WhereNow,
    Pet3_Age,
    Pet3_Breed,
    Pet3_Fixed,
    Pet3_How_long,
    Pet3_Kept,
    Pet3_Name,
    Pet3_Sex,
    Pet3_Vac,
    Pet3_WhereNow,
    Pet4_Age,
    Pet4_Breed,
    Pet4_Fixed,
    Pet4_How_long,
    Pet4_Kept,
    Pet4_Name,
    Pet4_Sex,
    Pet4_Vac,
    Pet4_WhereNow,
    Vet_Name,
    Vet_Number,
    Vet_Perm,
    Other_Vet,
    Policy_Acknowledged,
    Vac_Explain,
    Killed_By_Vehicle,
    Given_Away,
    Lost_Stolen,
    Turned_In,
    None_Apply,
    Outcome_Explain,
  } = application;

  const on_change = (e) => {
    onChange(e);
  };

  const next_page = (e) => {
    e.preventDefault();
    cont();
    window.scrollTo(0, 0);
  };
  const prev_page = (e) => {
    e.preventDefault();
    prev();
    window.scrollTo(0, 0);
  };

  let isChecked = (e) => {
    handleCheckedBox(e.target);
  };

  window.history.pushState({}, 'Adopt', '/adopt');

  useEffect(() => {
    window.onpopstate = function (ev) {
      prev();
    };
  }, [prev]);

  return (
    <Fragment>
      <div>
        <h4 className="text-center mt-2">ADOPTION APPLICATION</h4>
      </div>
      <div className="container bg-white border border-dark my-1">
        <div className="row">
          <h5 className="m-2">PET HISTORY </h5>
          <hr />
        </div>
        {/* ******************************************* */}
        <div className="container-fluid my-1 border border-dark">
          <div className="row bg-dark">
            <h6 className="text-center text-light align-middle my-1 col-lg-12">
              PET 1 INFORMATION
            </h6>
          </div>
          <div className="row mt-2">
            <div className="form-group col-11 col-sm-11 col-md-11 col-lg-3 col-xl-3 pr-0">
              <label htmlFor="Pet1_Breed ">Type/Breed</label>
              <input
                id="Pet1_Breed"
                type="text"
                className="form-control"
                value={Pet1_Breed}
                onChange={on_change}
                maxLength="50"
              />
            </div>
            <div className="form-group col-11 col-sm-11 col-md-11 col-lg-3 col-xl-4 pr-0">
              <label htmlFor="Pet1_Name">Name of Pet</label>
              <input
                id="Pet1_Name"
                type="text"
                className="form-control"
                value={Pet1_Name}
                onChange={on_change}
                maxLength="100"
              />
            </div>
            <div className="form-group col-6 col-sm-6 col-md-6 col-lg-1 col-xl-1 pr-0">
              <label htmlFor="Pet1_Sex">Sex</label>
              <select
                id="Pet1_Sex"
                className="form-control"
                onChange={on_change}
                value={Pet1_Sex}
              >
                <option value="not_answered">Select</option>
                <option>M</option>
                <option>F</option>
              </select>
            </div>
            <div className="form-group col-5 col-sm-5 col-md-5 col-lg-1 col-xl-1 pr-0">
              <label htmlFor="Pet1_Age">Age</label>
              <input
                id="Pet1_Age"
                type="number"
                className="form-control text-center"
                value={Pet1_Age}
                onChange={on_change}
                maxLength="2"
              />
            </div>
            <div className="form-group col-11 col-sm-11 col-md-11 col-lg-3 col-xl-3 pr-0">
              <label htmlFor="Pet1_Fixed">Spayed/Neutered</label>
              <select
                id="Pet1_Fixed"
                className="form-control"
                onChange={on_change}
                value={Pet1_Fixed}
              >
                <option value="not_answered">Select</option>
                <option>Yes</option>
                <option>No</option>
              </select>
            </div>
          </div>
          <div className="row">
            <div className="form-group col-11 col-sm-11 col-md-11 col-lg-4 col-xl-4 pr-0">
              <label htmlFor="Pet1_Vac">
                Preventatives vaccines / Rabies & Heartworm
              </label>
              <select
                id="Pet1_Vac"
                className="form-control"
                onChange={on_change}
                value={Pet1_Vac}
              >
                <option value="not_answered">Select</option>
                <option>Yes</option>
                <option>No</option>
              </select>
            </div>
            <div className="form-group col-11 col-sm-11 col-md-11 col-lg-2 col-xl-2 pr-0">
              <label htmlFor="Pet1_How_long">How long in your care?</label>
              <input
                id="Pet1_How_long"
                type="text"
                className="form-control"
                value={Pet1_How_long}
                onChange={on_change}
                maxLength="10"
              />
            </div>
            <div className="form-group col-11 col-sm-11 col-md-11 col-lg-2 col-xl-3 pr-0">
              <label htmlFor="Pet1_Kept">Kept inside or outside?</label>
              <select
                id="Pet1_Kept"
                className="form-control"
                onChange={on_change}
                value={Pet1_Kept}
              >
                <option value="not_answered">Select</option>
                <option>Inside</option>
                <option>Outside</option>
              </select>
            </div>
            <div className="form-group col-11 col-sm-11 col-md-11 col-lg-2 col-xl-3 pr-0">
              <label htmlFor="Pet1_WhereNow">Where is this pet now?</label>
              <input
                id="Pet1_WhereNow"
                type="text"
                className="form-control"
                value={Pet1_WhereNow}
                onChange={on_change}
                maxLength="50"
              />
            </div>
          </div>
        </div>
        {/* ************************************************* */}
        {/* ******************************************* */}
        <div className="container-fluid my-1 border border-dark">
          <div className="row bg-dark">
            <h6 className="text-center text-light align-middle my-1 col-lg-12">
              PET 2 INFORMATION
            </h6>
          </div>
          <div className="row mt-2">
            <div className="form-group col-11 col-sm-11 col-md-11 col-lg-3 col-xl-3 pr-0">
              <label htmlFor="Pet2_Breed ">Type/Breed</label>
              <input
                id="Pet2_Breed"
                type="text"
                className="form-control"
                value={Pet2_Breed}
                onChange={on_change}
                maxLength="50"
              />
            </div>
            <div className="form-group col-11 col-sm-11 col-md-11 col-lg-3 col-xl-4 pr-0">
              <label htmlFor="Pet2_Name">Name of Pet</label>
              <input
                id="Pet2_Name"
                type="text"
                className="form-control"
                value={Pet2_Name}
                onChange={on_change}
                maxLength="100"
              />
            </div>
            <div className="form-group col-6 col-sm-6 col-md-6 col-lg-1 col-xl-1 pr-0">
              <label htmlFor="Pet2_Sex">Sex</label>
              <select
                id="Pet2_Sex"
                className="form-control"
                onChange={on_change}
                value={Pet2_Sex}
              >
                <option value="not_answered">Select</option>
                <option>M</option>
                <option>F</option>
              </select>
            </div>
            <div className="form-group col-5 col-sm-5 col-md-5 col-lg-1 col-xl-1 pr-0">
              <label htmlFor="Pet2_Age">Age</label>
              <input
                id="Pet2_Age"
                type="number"
                className="form-control text-center"
                value={Pet2_Age}
                onChange={on_change}
                maxLength="2"
              />
            </div>
            <div className="form-group col-11 col-sm-11 col-md-11 col-lg-3 col-xl-3 pr-0">
              <label htmlFor="Pet2_Fixed">Spayed/Neutered</label>
              <select
                id="Pet2_Fixed"
                className="form-control"
                onChange={on_change}
                value={Pet2_Fixed}
              >
                <option value="not_answered">Select</option>
                <option>Yes</option>
                <option>No</option>
              </select>
            </div>
          </div>
          <div className="row">
            <div className="form-group col-11 col-sm-11 col-md-11 col-lg-4 col-xl-4 pr-0">
              <label htmlFor="Pet2_Vac">
                Preventatives vaccines / Rabies & Heartworm
              </label>
              <select
                id="Pet2_Vac"
                className="form-control"
                onChange={on_change}
                value={Pet2_Vac}
              >
                <option value="not_answered">Select</option>
                <option>Yes</option>
                <option>No</option>
              </select>
            </div>
            <div className="form-group col-11 col-sm-11 col-md-11 col-lg-2 col-xl-2 pr-0">
              <label htmlFor="Pet2_How_long">How long in your care?</label>
              <input
                id="Pet2_How_long"
                type="text"
                className="form-control"
                value={Pet2_How_long}
                onChange={on_change}
                maxLength="10"
              />
            </div>
            <div className="form-group col-11 col-sm-11 col-md-11 col-lg-2 col-xl-3 pr-0">
              <label htmlFor="Pet2_Kept">Kept inside or outside?</label>
              <select
                id="Pet2_Kept"
                className="form-control"
                onChange={on_change}
                value={Pet2_Kept}
              >
                <option value="not_answered">Select</option>
                <option>Inside</option>
                <option>Outside</option>
              </select>
            </div>
            <div className="form-group col-11 col-sm-11 col-md-11 col-lg-2 col-xl-3 pr-0">
              <label htmlFor="Pet2_WhereNow">Where is this pet now?</label>
              <input
                id="Pet2_WhereNow"
                type="text"
                className="form-control"
                value={Pet2_WhereNow}
                onChange={on_change}
                maxLength="50"
              />
            </div>
          </div>
        </div>
        {/* ************************************************* */}
        <div className="container-fluid my-1 border border-dark">
          <div className="row bg-dark">
            <h6 className="text-center text-light align-middle my-1 col-lg-12">
              PET 3 INFORMATION
            </h6>
          </div>
          <div className="row mt-2">
            <div className="form-group col-11 col-sm-11 col-md-11 col-lg-3 col-xl-3 pr-0">
              <label htmlFor="Pet3_Breed ">Type/Breed</label>
              <input
                id="Pet3_Breed"
                type="text"
                className="form-control"
                value={Pet3_Breed}
                onChange={on_change}
                maxLength="50"
              />
            </div>
            <div className="form-group col-11 col-sm-11 col-md-11 col-lg-3 col-xl-4 pr-0">
              <label htmlFor="Pet3_Name">Name of Pet</label>
              <input
                id="Pet3_Name"
                type="text"
                className="form-control"
                value={Pet3_Name}
                onChange={on_change}
                maxLength="100"
              />
            </div>
            <div className="form-group col-6 col-sm-6 col-md-6 col-lg-1 col-xl-1 pr-0">
              <label htmlFor="Pet3_Sex">Sex</label>
              <select
                id="Pet3_Sex"
                className="form-control"
                onChange={on_change}
                value={Pet3_Sex}
              >
                <option value="not_answered">Select</option>
                <option>M</option>
                <option>F</option>
              </select>
            </div>
            <div className="form-group col-5 col-sm-5 col-md-5 col-lg-1 col-xl-1 pr-0">
              <label htmlFor="Pet3_Age">Age</label>
              <input
                id="Pet3_Age"
                type="number"
                className="form-control text-center"
                value={Pet3_Age}
                onChange={on_change}
                maxLength="2"
              />
            </div>
            <div className="form-group col-11 col-sm-11 col-md-11 col-lg-3 col-xl-3 pr-0">
              <label htmlFor="Pet3_Fixed">Spayed/Neutered</label>
              <select
                id="Pet3_Fixed"
                className="form-control"
                onChange={on_change}
                value={Pet3_Fixed}
              >
                <option value="not_answered">Select</option>
                <option>Yes</option>
                <option>No</option>
              </select>
            </div>
          </div>
          <div className="row">
            <div className="form-group col-11 col-sm-11 col-md-11 col-lg-4 col-xl-4 pr-0">
              <label htmlFor="Pet3_Vac">
                Preventatives vaccines / Rabies & Heartworm
              </label>
              <select
                id="Pet3_Vac"
                className="form-control"
                onChange={on_change}
                value={Pet3_Vac}
              >
                <option value="not_answered">Select</option>
                <option>Yes</option>
                <option>No</option>
              </select>
            </div>
            <div className="form-group col-11 col-sm-11 col-md-11 col-lg-2 col-xl-2 pr-0">
              <label htmlFor="Pet3_How_long">How long in your care?</label>
              <input
                id="Pet3_How_long"
                type="text"
                className="form-control"
                value={Pet3_How_long}
                onChange={on_change}
                maxLength="10"
              />
            </div>
            <div className="form-group col-11 col-sm-11 col-md-11 col-lg-2 col-xl-3 pr-0">
              <label htmlFor="Pet3_Kept">Kept inside or outside?</label>
              <select
                id="Pet3_Kept"
                className="form-control"
                onChange={on_change}
                value={Pet3_Kept}
              >
                <option value="not_answered">Select</option>
                <option>Inside</option>
                <option>Outside</option>
              </select>
            </div>
            <div className="form-group col-11 col-sm-11 col-md-11 col-lg-2 col-xl-3 pr-0">
              <label htmlFor="Pet3_WhereNow">Where is this pet now?</label>
              <input
                id="Pet3_WhereNow"
                type="text"
                className="form-control"
                value={Pet3_WhereNow}
                onChange={on_change}
                maxLength="50"
              />
            </div>
          </div>
        </div>
        {/* ************************************************* */}
        <div className="container-fluid my-1 border border-dark">
          <div className="row bg-dark">
            <h6 className="text-center text-light align-middle my-1 col-lg-12">
              PET 4 INFORMATION
            </h6>
          </div>
          <div className="row mt-2">
            <div className="form-group col-11 col-sm-11 col-md-11 col-lg-3 col-xl-3 pr-0">
              <label htmlFor="Pet4_Breed ">Type/Breed</label>
              <input
                id="Pet4_Breed"
                type="text"
                className="form-control"
                value={Pet4_Breed}
                onChange={on_change}
                maxLength="50"
              />
            </div>
            <div className="form-group col-11 col-sm-11 col-md-11 col-lg-3 col-xl-4 pr-0">
              <label htmlFor="Pet4_Name">Name of Pet</label>
              <input
                id="Pet4_Name"
                type="text"
                className="form-control"
                value={Pet4_Name}
                onChange={on_change}
                maxLength="100"
              />
            </div>
            <div className="form-group col-6 col-sm-6 col-md-6 col-lg-1 col-xl-1 pr-0">
              <label htmlFor="Pet4_Sex">Sex</label>
              <select
                id="Pet4_Sex"
                className="form-control"
                onChange={on_change}
                value={Pet4_Sex}
              >
                <option value="not_answered">Select</option>
                <option>M</option>
                <option>F</option>
              </select>
            </div>
            <div className="form-group col-5 col-sm-5 col-md-5 col-lg-1 col-xl-1 pr-0">
              <label htmlFor="Pet4_Age">Age</label>
              <input
                id="Pet4_Age"
                type="number"
                className="form-control text-center"
                value={Pet4_Age}
                onChange={on_change}
                maxLength="2"
              />
            </div>
            <div className="form-group col-11 col-sm-11 col-md-11 col-lg-3 col-xl-3 pr-0">
              <label htmlFor="Pet4_Fixed">Spayed/Neutered</label>
              <select
                id="Pet4_Fixed"
                className="form-control"
                onChange={on_change}
                value={Pet4_Fixed}
              >
                <option value="not_answered">Select</option>
                <option>Yes</option>
                <option>No</option>
              </select>
            </div>
          </div>
          <div className="row">
            <div className="form-group col-11 col-sm-11 col-md-11 col-lg-4 col-xl-4 pr-0">
              <label htmlFor="Pet4_Vac">
                Preventatives vaccines / Rabies & Heartworm
              </label>
              <select
                id="Pet4_Vac"
                className="form-control"
                onChange={on_change}
                value={Pet4_Vac}
              >
                <option value="not_answered">Select</option>
                <option>Yes</option>
                <option>No</option>
              </select>
            </div>
            <div className="form-group col-11 col-sm-11 col-md-11 col-lg-2 col-xl-2 pr-0">
              <label htmlFor="Pet4_How_long">How long in your care?</label>
              <input
                id="Pet4_How_long"
                type="text"
                className="form-control"
                value={Pet4_How_long}
                onChange={on_change}
                maxLength="10"
              />
            </div>
            <div className="form-group col-11 col-sm-11 col-md-11 col-lg-2 col-xl-3 pr-0">
              <label htmlFor="Pet4_Kept">Kept inside or outside?</label>
              <select
                id="Pet4_Kept"
                className="form-control"
                onChange={on_change}
                value={Pet4_Kept}
              >
                <option value="not_answered">Select</option>
                <option>Inside</option>
                <option>Outside</option>
              </select>
            </div>
            <div className="form-group col-11 col-sm-11 col-md-11 col-lg-2 col-xl-3 pr-0">
              <label htmlFor="Pet4_WhereNow">Where is this pet now?</label>
              <input
                id="Pet4_WhereNow"
                type="text"
                className="form-control"
                value={Pet4_WhereNow}
                onChange={on_change}
                maxLength="50"
              />
            </div>
          </div>
        </div>
        {/* ************************************************* */}
        <div className="container-fluid my-1 border border-dark">
          <div className="row bg-dark">
            <h6 className="text-center text-light align-middle my-1 col-lg-12">
              VETERINARIAN INFORMATION
            </h6>
          </div>
          <div className="row mt-2">
            <div className="form-group col-11 col-sm-11 col-md-11 col-lg-3 col-xl-5 pr-0">
              <label htmlFor="Vet_Name">Primary Vet Used</label>
              <input
                id="Vet_Name"
                type="text"
                className="form-control"
                value={Vet_Name}
                onChange={on_change}
                maxLength="100"
              />
            </div>
            <div className="form-group col-11 col-sm-11 col-md-11 col-lg-3 col-xl-4 pr-0">
              <label htmlFor="Vet_Number">Vet Number</label>
              <input
                id="Vet_Number"
                type="phone"
                className="form-control"
                value={Vet_Number}
                onChange={on_change}
                maxLength="20"
              />
            </div>
          </div>
          <div className="row">
            <div className="form-group col-11 col-sm-11 col-md-11 col-lg-11 col-xl-11 pr-0">
              <label htmlFor="Other_Vet">
                If you had other Veterinarians providing part of the routine
                care, please provide their names and phone number as well
              </label>
              <input
                id="Other_Vet"
                type="text"
                className="form-control"
                value={Other_Vet}
                onChange={on_change}
                maxLength="250"
              />
            </div>
          </div>
          <div className="row">
            <div className="form-group col-11 col-sm-11 col-md-11 col-lg-11 col-xl-11 pr-0">
              <label htmlFor="Vet_Perm">
                Do We have your permission to contact your veterinarian for a
                reference?
              </label>
              <select
                id="Vet_Perm"
                className="form-control"
                onChange={on_change}
                value={Vet_Perm}
              >
                <option value="not_answered">Select</option>
                <option>Yes</option>
                <option>No</option>
              </select>
            </div>
          </div>
        </div>
        <div className="container-fluid my-1 border border-dark">
          <div className="row bg-dark">
            <h6 className="text-center text-light align-middle my-1 col-lg-12">
              ADDITIONAL INFORMATION
            </h6>
          </div>
          <div className="row mx-2">
            <p>
              Please note that we will not consider applicants who have not
              provided regular vet care to their prior pets. if there are
              reasons why vaccinations or heartworm preventatives were not
              provided you must clearly in the space provided below
            </p>
          </div>
          <div className="row">
            <div className="form-group col-11 col-sm-11 col-md-11 col-lg-11 col-xl-11 pr-0">
              <textarea
                id="Vac_Explain"
                type="text"
                className="form-control"
                rows="4"
                value={Vac_Explain}
                onChange={on_change}
              />
            </div>
          </div>
          <div className="form-group col-11 col-sm-11 col-md-11 col-lg-11 col-xl-11 pr-0">
            <div className="row">
              <label
                htmlFor="Policy_Acknowledged"
                className="col-11 col-sm-11 col-md-11 col-lg-6 col-xl-5 pr-0 text-middle"
              >
                Please acknowledge your understanding of this policy
              </label>
              <select
                id="Policy_Acknowledged"
                className="form-control col-11 col-sm-11 col-md-11 col-lg-3 col-xl-3 pr-0 "
                onChange={on_change}
                value={Policy_Acknowledged}
              >
                <option value="not_answered">Select</option>
                <option>I Understand</option>
                <option>Do Not Understand</option>
              </select>
            </div>
          </div>
          <div className="row mx-2">
            <p>Were any of your animals:</p>
          </div>
          <div className="row mx-2">
            <div className="form-check col-xl-2">
              <input
                type="checkbox"
                className="form-check-input"
                id="Killed_By_Vehicle"
                value={Killed_By_Vehicle}
                checked={Killed_By_Vehicle}
                onChange={isChecked}
              />
              <label
                className="form-check-label p-0 ml-1"
                htmlFor="Killed_By_Vehicle"
              >
                Killed by moving Vehicle
              </label>
            </div>
            <div className="form-check col-xl-2">
              <input
                type="checkbox"
                className="form-check-input"
                id="Given_Away"
                value={Given_Away}
                checked={Given_Away}
                onChange={isChecked}
              />
              <label className="form-check-label p-0 ml-1" htmlFor="Given_Away">
                Given Away
              </label>
            </div>
            <div className="form-check col-xl-2">
              <input
                type="checkbox"
                className="form-check-input"
                id="Lost_Stolen"
                value={Lost_Stolen}
                checked={Lost_Stolen}
                onChange={isChecked}
              />
              <label
                className="form-check-label p-0 ml-1"
                htmlFor="Lost_Stolen"
              >
                Lost or Stolen
              </label>
            </div>
            <div className="form-check col-xl-3">
              <input
                type="checkbox"
                className="form-check-input"
                id="Turned_In"
                value={Turned_In}
                checked={Turned_In}
                onChange={isChecked}
              />
              <label className="form-check-label p-0 ml-1" htmlFor="Turned_In">
                Turned into shelter or rescue
              </label>
            </div>
            <div className="form-check col-xl-2">
              <input
                type="checkbox"
                className="form-check-input"
                id="None_Apply"
                value={None_Apply}
                checked={None_Apply}
                onChange={isChecked}
              />
              <label className="form-check-label p-0 ml-1" htmlFor="None_Apply">
                None Apply
              </label>
            </div>
          </div>
          <div className="row mt-3 ml-2">
            <p className="font-weight-bold">If yes, please explain:</p>
          </div>
          <div className="row">
            <div className="form-group col-11 col-sm-11 col-md-11 col-lg-11 col-xl-11 pr-0">
              <textarea
                id="Outcome_Explain"
                type="text"
                className="form-control"
                rows="4"
                value={Outcome_Explain}
                onChange={on_change}
              />
            </div>
          </div>
        </div>
        {/* ****************BUTTONS ARE BELOW HERE*********** */}
        <div className="container">
          <div className="row">
            <button
              className="btn btn-danger col-lg-2 mb-3"
              onClick={prev_page}
            >
              <i className="fa fa-arrow-left p" aria-hidden="true" />
              Previous
            </button>
            <div className="col-lg-8"></div>
            <button
              type="button"
              className="btn btn-dark col-lg-2  mb-3"
              onClick={next_page}
            >
              Continue
              <i className="fa fa-arrow-right p" aria-hidden="true" />
            </button>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default FormAdoptPetHistory;
