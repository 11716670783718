import React from 'react';
import PetFace from '../../media/dogFace.png';
import SittingDog from '../../media/sittingDog.png';

const MiddleSection = () => {
  return (
    <div className="container py-5">
      <div className="row">
        <div className="col-md-3 text-center ">
          <a href="/adopt" className="text-dark">
            <i className="fas fa-paw fa-7x"></i>
            <h1>APPLY NOW</h1>
          </a>
        </div>

        <div className="col-md-3 text-center">
          <a href="/pets" className="text-dark">
            <img
              src={PetFace}
              style={{ height: '110px', width: '120px' }}
              className="p-0"
              alt=""
            />
            <h1>OUR PETS</h1>
          </a>
        </div>

        <div className="col-md-3 text-center">
          <a
            href="https://www.petfinder.com/search/pets-adopted/?shelter_id%5B0%5D=MD411&sort%5B0%5D=recently_added"
            className="text-dark"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img
              src={SittingDog}
              style={{ height: '110px', width: '120px' }}
              className="p-0"
              alt=""
            />
            <h1>HAPPY TAILS</h1>
          </a>
        </div>
        <div className="col-md-3 text-center">
          <a href="/blog" className="text-dark">
            <i className="far fa-newspaper fa-7x"></i>
            <h1>PAW BLOG</h1>
          </a>
        </div>
      </div>
    </div>
  );
};

export default MiddleSection;
