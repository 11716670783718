import React, { Fragment, useContext, useState } from 'react';
import Bruiser from '../../media/Bruiser.jpg';
import ReCAPTCHA from 'react-google-recaptcha';
import AppContext from '../../context/app/appContext';
import AlertContext from '../../context/alert/alertContext';
import axios from 'axios';
import sendContactEmail from '../templates/sendContactEmail';

const Contact = (props) => {
  const appContext = useContext(AppContext);
  const alertContext = useContext(AlertContext);
  const { isVerified, checkCaptcha } = appContext;

  const { setAlert } = alertContext;

  const [contactMessage, setContactMessage] = useState({
    name: '',
    phone: '',
    email: '',
    message: '',
  });

  const onChange = (e) => {
    setContactMessage({ ...contactMessage, [e.target.id]: e.target.value });
  };

  // Google Captcha Implementation
  const captchaID = '6Lf6EMMUAAAAACdn1-MLLtPckPVVFfyMLTnP6tF2';

  const isNotRobot = (response) => {
    checkCaptcha(response);
  };

  const config = {
    headers: {
      'Content-Type': 'application/json',
    },
  };

  const sendMessage = () => {
    if (isVerified) {
      const message = sendContactEmail(contactMessage);
      axios.post('/api/sendContactEmail', message, config);
      props.history.push('/');
      window.scroll(0, 0);
      setAlert(
        'Your Message has been sent. We will get back to you ASAP!',
        'success'
      );
    } else {
      window.scroll(0, 0);
      setAlert('Confirm your not a robot!', 'danger');
    }
  };

  return (
    <Fragment>
      <div className="container">
        <div className="row">
          <div className="col-lg-8">
            <div className="row">
              <div className="card">
                <div className="card-content">
                  <h1 className="text-center">CONTACT</h1>
                  <h2 className="text-center">
                    <i className="fa fa-paw fa-2x"></i> PUPPY PAWS RESCUE
                    <i className="fa fa-paw pl-1 fa-2x"></i>
                  </h2>
                  <p className="p-3 text-center">
                    Puppy Paws Rescue strives to answer all inquiries as soon as
                    possible. The best way to contact us is through{' '}
                    <a href="https://www.facebook.com/pg/PuppyPawsRescue">
                      Facebook Messenger
                    </a>
                    . However, please feel free to contact us using the below
                    contact form. We will get back to you asap.
                  </p>
                </div>
                <form className="p-3">
                  <div className="form-group mt-1">
                    <label htmlFor="name">Name</label>
                    <input
                      type="text"
                      onChange={onChange}
                      className="form-control"
                      id="name"
                    />
                  </div>
                  <div className="form-group">
                    <label htmlFor="phone">Phone</label>
                    <input
                      type="text"
                      onChange={onChange}
                      className="form-control"
                      id="phone"
                    />
                  </div>

                  <div className="form-group">
                    <label htmlFor="email">Email address</label>
                    <input
                      type="email"
                      onChange={onChange}
                      className="form-control"
                      id="email"
                    />
                  </div>

                  <div className="form-group">
                    <label htmlFor="message">Message</label>
                    <textarea
                      className="form-control"
                      onChange={onChange}
                      id="message"
                      rows="6"
                    ></textarea>
                  </div>
                </form>
              </div>
            </div>

            <div className="row mt-2">
              <div className="col-xs-5 col-sm-3"></div>
              <div className="col-5">
                <ReCAPTCHA sitekey={captchaID} onChange={isNotRobot} />
              </div>
            </div>
            <div className="row">
              <button
                type="submit"
                onClick={sendMessage}
                className="btn btn-secondary my-3 btn-block"
              >
                SEND
              </button>
            </div>
          </div>

          <div className="col-lg-3 my-2">
            <img
              className=" contact-image img-fluid rounded"
              src={Bruiser}
              alt=""
            ></img>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default Contact;
