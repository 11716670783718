import React, { useContext } from 'react';
import AlertContext from '../../context/alert/alertContext';

const Alerts = () => {
  const alertContext = useContext(AlertContext);
  return (
    alertContext.alerts.length > 0 &&
    alertContext.alerts.map(alert => (
      <div
        key={alert.id}
        className={`alert font-weight-bold text-center text-dark alert-${alert.type}`}
      >
        <i className="fas fas-info-circle" /> {alert.msg}
      </div>
    ))
  );
};

export default Alerts;
