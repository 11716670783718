import React, { Fragment, useState, useContext } from 'react';
import ReCAPTCHA from 'react-google-recaptcha';
import Email from '../../components/templates/singlePageEmail';
import TaskContext from '../../context/tasks/taskContext';
import Home from '../pages/Home';
import axios from 'axios';

const config = {
  headers: {
    'Content-Type': 'application/json',
  },
};

const FormAdoptSinglePage = () => {
  const taskContext = useContext(TaskContext);
  const { createTask } = taskContext;
  const [page, setPage] = useState(1);
  const [isVerified, setIsVerified] = useState(false);
  const [applicationData, setApplicationData] = useState({
    Acclimate: '',
    Adoption_Aware_Fee: '',
    Adults_Aware: '',
    Age_of_Children: '',
    Allergies: '',
    Alone_Time: '',
    Apartment: '',
    Application_Type: 'Adoption',
    Application_Status: 'New Application',
    Lead_Status: 'New Application',
    Lead_Source: 'Web Applications',
    Baby: '',
    Barks: '',
    Bills: '',
    Bladder_Issues: '',
    Chronic_Illness: '',
    City: '',
    CoApp_Full_Name: '',
    Condo: '',
    Convictions: '',
    Corrections: '',
    Costs: '',
    County: '',
    Divorce: '',
    Email: '',
    Employer1: '',
    Employer2: '',
    Encourage: '',
    Exercise: '',
    Expense: '',
    First_Name: '',
    Future_Dog2: '',
    Fence: '',
    Fence_Size: '',
    Given_Away: '',
    Grooming: '',
    Hours1: '',
    Hours2: '',
    House: '',
    Housebreak: '',
    If_Allergies: '',
    If_Move: '',
    If_Die: '',
    Introductions: '',
    Killed_By_Vehicle: '',
    Kept_Where: '',
    Last_Name: '',
    Landlord_Name: '',
    Landlord_Phone: '',
    Lifetime_Commitment: '',
    Lived_Length_Home: '',
    Location_When_Home: '',
    Location_When_Not_Home: '',
    Location_When_Traveling: '',
    Location_When_Sleeping: '',
    Lost_Stolen: '',
    Medical_Care: '',
    Middle_Initial: '',
    Mobile: '',
    Mobile_Home: '',
    Moving: '',
    Move_Out_Of_State: '',
    Move_No_Pets: '',
    nd_Choice: '',
    Neighbors: '',
    None_Apply: '',
    Normal_Behavior: '',
    Nothing_Applies: '',
    Number_Household: '',
    Of_Age: '',
    Other_Adult_Names: '',
    Other_Checkbox: '',
    Other_Reasons: '',
    Other_Vet: '',
    Description: '',
    Outcome_Explain: '',
    Owner: { id: '35194048' },
    Owner_Id: '1045',
    Own_Rent: '',
    Partner_Allergies: '',
    Past_Addresses: '',
    Pet1_Age: '',
    Pet1_Breed: '',
    Pet1_Fixed: '',
    Pet1_How_long: '',
    Pet1_Kept: '',
    Pet1_Name: '',
    Pet1_Sex: '',
    Pet1_Vac: '',
    Pet1_WhereNow: '',
    Pet2_Age: '',
    Pet2_Breed: '',
    Pet2_Fixed: '',
    Pet2_How_long: '',
    Pet2_Kept: '',
    Pet2_Name: '',
    Pet2_Sex: '',
    Pet2_Vac: '',
    Pet2_WhereNow: '',
    Pet3_Age: '',
    Pet3_Breed: '',
    Pet3_Fixed: '',
    Pet3_How_long: '',
    Pet3_Kept: '',
    Pet3_Name: '',
    Pet3_Sex: '',
    Pet3_Vac: '',
    Pet3_WhereNow: '',
    Pet4_Age: '',
    Pet4_Breed: '',
    Pet4_Fixed: '',
    Pet4_How_long: '',
    Pet4_Kept: '',
    Pet4_Name: '',
    Pet4_Sex: '',
    Pet4_Vac: '',
    Pet4_WhereNow: '',
    Pet_Food: '',
    Pet_Incompatible: '',
    Pet_Not_As_Expected: '',
    Pets_Permitted: '',
    Phone: '',
    Policy_Acknowledged: '',
    Preperations: '',
    Primary_Caregiver: '',
    Reference_1: '',
    Reference_2: '',
    Reference_1_Phone: '',
    Reference_2_Phone: '',
    st_Choice: '',
    State: '',
    Street: '',
    Townhouse: '',
    Training: '',
    Turned_In: '',
    Un_Trainable: '',
    Vac_Explain: '',
    Vet_Name: '',
    Vet_Number: '',
    Vet_Perm: '',
    When_Moving: '',
    Will_Give_Preventatives: '',
    Work: '',
    Zip_Code: '',
    OS_Type: navigator.appVersion,
    TimeStamp: Date.now(),
  });

  const submitApp = async (data) => {
    await axios.post('/api/createJSON', data, config);

    if (data.Application_Type === 'Adoption') {
      const message = Email(data);
      axios.post('/api/createPDF', data, config);
      axios.post('/api/sendEmail', message, config);
      axios.post('/api/thankyouAdoption', data, config);
    }
    try {
      const dbResults = await axios.post('/api/database', data, config);
      createTask(dbResults.data._id);
      const fileData = {
        appID: dbResults.data._id,
        data: data,
      };
      await axios.post('/api/attachApplication', fileData, config);

      // ! ********* PAST ADOPTION APPLICATION CHECKER CODE - Feature Release V.2.3 *********

      const applicationData = {
        application_id: dbResults.data._id,
        First_Name: data.First_Name,
        Last_Name: data.Last_Name,
        Email_Address: data.Email,
      };

      axios.post('/api/database/duplicateCheck/', applicationData, config);
      // ! ******************************************************************************************
    } catch (err) {
      console.log(err);
    }
  };

  // Google Captcha Implementation
  const captchaID = '6Lf6EMMUAAAAACdn1-MLLtPckPVVFfyMLTnP6tF2';

  const checkCaptcha = async (data) => {
    const resBody = {
      response: data,
    };
    try {
      const res = await axios.post('/api/captcha', resBody, config);
      setIsVerified(res.data);
    } catch (err) {
      console.log(err);
    }
  };

  // FORM VALIDATIONS
  const [validation, setValidation] = useState({
    First_Name_Invalid: '',
    Middle_Initial_Invalid: '',
    Last_Name_Invalid: '',
    Email_Invalid: '',
  });

  const checkValidations = () => {
    let fname = '';
    let mname = '';
    let lname = '';
    let email = '';

    if (applicationData.First_Name === '') {
      fname = 'Enter First Name';
    }

    if (applicationData.Middle_Initial === '') {
      mname = `Enter Middle Name`;
    }
    if (applicationData.Last_Name === '') {
      lname = `Enter Last Name`;
    }
    if (applicationData.Email === '') {
      email = `Enter Valid Email`;
    }

    setValidation({
      ...validation,
      First_Name_Invalid: fname,
      Middle_Initial_Invalid: mname,
      Last_Name_Invalid: lname,
      Email_Invalid: email,
    });
  };

  const isNotRobot = (response) => {
    checkCaptcha(response);
  };

  const handleCheckedBox = (e) => {
    if (applicationData[e.target.id] === '') {
      setApplicationData({ ...applicationData, [e.target.id]: 'checked' });
    } else {
      setApplicationData({ ...applicationData, [e.target.id]: '' });
    }
  };

  const onChange = (e) => {
    setApplicationData({ ...applicationData, [e.target.id]: e.target.value });
  };

  const submitForm = (e) => {
    e.preventDefault();
    checkValidations();
    if (
      applicationData.First_Name !== '' &&
      applicationData.Middle_Initial !== '' &&
      applicationData.Email !== '' &&
      applicationData.Last_Name !== ''
    ) {
      submitApp(applicationData);
      setTimeout(() => {
        alert('Your Application has been submitted! ');
      }, 1000);
      window.scrollTo(0, 0);
      setPage(3);
    } else {
      window.scrollTo(0, 200);
      setTimeout(() => {
        alert('Please enter the manditory fields noted in RED ');
      }, 1000);
    }
  };

  const next_page = () => {
    if (isVerified) {
      if (page === 1) {
        setPage(2);
        window.scrollTo(0, 0);
      } else {
        setPage(1);
        window.scrollTo(0, 0);
      }
    } else {
      alert('Prove you are not a robot');
    }
  };

  const handleKeypress = (event) => {
    if (event.target.type !== 'textarea' && event.which === 13 /* Enter */) {
      event.preventDefault();
    }
  };

  switch (page) {
    default:
    case 1:
      return (
        <Fragment>
          <div className="bg-light p-2">
            <div className="container py-0 border border-dark bg-light">
              <div className="container-fluid my-1 ">
                <div className="row bg-danger align-middle">
                  <h5 className="text-center text-light align-middle my-1 p-2 col-lg-12">
                    APPLICATION DISCLAIMER
                  </h5>
                </div>
                <p className="mt-3">
                  Puppy Paws Rescue takes the processing of adoption
                  applications very seriously. Prior to filling out the
                  application please confirm the following:
                </p>
                <ol>
                  <li>
                    I am ready to adopt and so are all of my family members.
                  </li>
                  <li>
                    I can make arrangements to let a puppy or young dog out
                    until acclimated or old enough. Puppies should not be alone
                    longer than their age in months plus 1 (e.g.: 3 months old
                    puppy+1 = 4 hours alone max at a time).
                  </li>
                  <li>I am willing to give it 2 weeks for a dog to adjust.</li>
                  <li>
                    I have spoken to my landlord or parents or other individuals
                    that make decisions about allowing a dog into a home.
                  </li>
                  <li>
                    I am calling my vet if I have or had animals in the past 5
                    years and I am allowing Puppy Paws Rescue to perform a
                    veterinary check, which consists of basic vaccinations -
                    Distemper Parvo (DHPP), Rabies, annual heart-worm test, dog
                    spayed or neutered and on monthly heart-worm prevention.
                  </li>
                  <li>
                    I am currently not in foreclosure nor in a situation to lose
                    my home or residence.
                  </li>
                  <li>
                    I am financially able to pay a $ 300 adoption donation, take
                    a dog for a post adoption vet visit, and other cost that
                    might incur when adding a dog to the household.
                  </li>
                </ol>
                <div className="container col-md-5 col-lg-4 col-xl-3 mb-3">
                  <ReCAPTCHA sitekey={captchaID} onChange={isNotRobot} />
                </div>
                <div className="container">
                  <div className="row">
                    <a
                      href="/"
                      className="btn btn-danger col-lg-2 mt-3 mb-3 align-middle"
                    >
                      I DON'T AGREE
                    </a>

                    <div className="col-lg-8"></div>
                    <button
                      type="button"
                      className="btn btn-dark col-lg-2 mt-3 mb-3"
                      onClick={next_page}
                    >
                      I AGREE
                      <i className="fa fa-hand-paper-o p" aria-hidden="true" />
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Fragment>
      );
    case 2:
      return (
        <Fragment>
          <div className="bg-light p-2">
            <div className="container py-0 border border-dark border-10px bg-light">
              <form id="form" action="/">
                <div>
                  <h2 className="text-center my-4">
                    PUPPY PAWS RESCUE ADOPTION APPLICATION
                  </h2>
                </div>

                {/* ********************  INTEREST ************************ */}
                <div className="container-fluid my-1 border border-dark">
                  <div className="row bg-dark">
                    <h6 className="text-center text-light align-middle my-1 col-lg-12">
                      CURRENT INTEREST IN ADOPTING
                    </h6>
                  </div>
                  <div>
                    <h5>
                      What pet are you interested in adopting from our rescue?{' '}
                    </h5>
                  </div>
                  <div className="row">
                    <div className="form-group col-sm-12 col-md-6 col-lg-6 mt-1">
                      <label className="active" htmlFor="1st Choice">
                        1st Choice
                      </label>
                      <input
                        id="st_Choice"
                        type="text"
                        className="form-control"
                        onChange={onChange}
                        onKeyPress={handleKeypress}
                        maxLength="100"
                      />
                    </div>
                    <div className="form-group col-sm-12 col-md-6 col-lg-6">
                      <label className="active" htmlFor="2nd Choice">
                        2nd Choice
                      </label>
                      <input
                        id="nd_Choice"
                        type="text"
                        className="form-control"
                        onChange={onChange}
                        onKeyPress={handleKeypress}
                        maxLength="100"
                      />
                    </div>
                  </div>
                  <div>
                    <div className="form-group">
                      <label className="active" htmlFor="Future_Dog2">
                        <h5>
                          Future Puppy Paws Rescue Dog - Please describe what
                          you are looking for?
                        </h5>
                      </label>
                      <textarea
                        id="Future_Dog2"
                        type="text"
                        className="form-control"
                        rows="4"
                        onChange={onChange}
                        onKeyPress={handleKeypress}
                        maxLength="1500"
                      />
                    </div>
                  </div>
                </div>
                {/* ***************** APPLICANTS INFORMATION **************************** */}
                <div className="container-fluid my-1 border border-dark">
                  <div className="row bg-dark">
                    <h6 className="text-center text-light align-middle my-1 col-lg-12">
                      PERSONAL DATA
                    </h6>
                  </div>
                  <div className="row">
                    <div className="col-sm-7 col-lg-8"></div>
                    <div className="col-sm-5 col-lg-3">
                      <p className="text-danger">* Indicates Manditory</p>
                    </div>
                  </div>
                  <div className="row">
                    <div className="form-group col-sm-12 col-md-4 col-lg-4">
                      <label htmlFor="First_Name">
                        First Name <span className="text-danger"> *</span>
                      </label>
                      <input
                        id="First_Name"
                        type="text"
                        className="form-control"
                        onChange={onChange}
                        onKeyPress={handleKeypress}
                        maxLength="100"
                      />
                      <div className="text-danger font-weight-bold">
                        {validation.First_Name_Invalid}
                      </div>
                    </div>
                    <div className="form-group col-sm-12 col-md-4 col-lg-3">
                      <label htmlFor="Middle_Initial">
                        Middle Name <span className="text-danger"> *</span>{' '}
                      </label>
                      <input
                        id="Middle_Initial"
                        type="text"
                        className="form-control "
                        onChange={onChange}
                        onKeyPress={handleKeypress}
                        required
                        maxLength="100"
                      />
                      <div className="text-danger font-weight-bold">
                        {validation.Middle_Initial_Invalid}
                      </div>
                    </div>
                    <div className="form-group col-sm-12 col-md-4 col-lg-4">
                      <label htmlFor="Last_Name">
                        Last Name <span className="text-danger"> *</span>
                      </label>
                      <input
                        id="Last_Name"
                        type="text"
                        className="form-control"
                        onChange={onChange}
                        onKeyPress={handleKeypress}
                        required
                        maxLength="100"
                      />
                      <div className="text-danger font-weight-bold">
                        {validation.Last_Name_Invalid}
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="form-group col-sm-12 col-md-6 col-lg-3">
                      <label htmlFor="Phone">Home Phone</label>
                      <input
                        id="Phone"
                        type="text"
                        className="form-control"
                        onChange={onChange}
                        onKeyPress={handleKeypress}
                        maxLength="20"
                      />
                    </div>
                    <div className="form-group col-sm-12 col-md-6 col-lg-3">
                      <label htmlFor="Work">Work Phone</label>
                      <input
                        id="Work"
                        type="text"
                        className="form-control"
                        onChange={onChange}
                        onKeyPress={handleKeypress}
                        maxLength="20"
                      />
                    </div>
                    <div className="form-group col-sm-12 col-md-6 col-lg-3">
                      <label htmlFor="Mobile">Mobile Phone</label>
                      <input
                        id="Mobile"
                        type="text"
                        className="form-control"
                        onChange={onChange}
                        onKeyPress={handleKeypress}
                        maxLength="20"
                      />
                    </div>
                    <div className="form-group col-sm-12 col-md-6 col-lg-3">
                      <label htmlFor="Email">
                        Email Address <span className="text-danger"> *</span>
                      </label>
                      <input
                        id="Email"
                        type="email"
                        className="form-control"
                        onChange={onChange}
                        onKeyPress={handleKeypress}
                        maxLength="100"
                      />
                      <div className="text-danger font-weight-bold">
                        {validation.Email_Invalid}
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="form-group col-sm-12 col-md-6 col-lg-6">
                      <label htmlFor="CoApp_Full_Name">
                        Co-Applicant Full Name (including middle name)
                      </label>
                      <input
                        id="CoApp_Full_Name"
                        type="text"
                        className="form-control"
                        onChange={onChange}
                        onKeyPress={handleKeypress}
                        maxLength="100"
                      />
                    </div>
                  </div>
                  <div className="row">
                    <div className="form-group col-sm-12 col-md-12 col-lg-8">
                      <label htmlFor="Street">Street Address</label>
                      <input
                        id="Street"
                        type="text"
                        className="form-control"
                        onChange={onChange}
                        onKeyPress={handleKeypress}
                        maxLength="250"
                      />
                    </div>
                  </div>
                  <div className="row">
                    <div className="form-group col-sm-12 col-md-7 col-lg-6">
                      <label htmlFor="City">City</label>
                      <input
                        id="City"
                        type="text"
                        className="form-control"
                        onChange={onChange}
                        onKeyPress={handleKeypress}
                        maxLength="100"
                      />
                    </div>
                    <div className="form-group col-sm-12 col-md-2 col-lg-2">
                      <label htmlFor="State">State</label>
                      <input
                        id="State"
                        type="text"
                        className="form-control"
                        onChange={onChange}
                        onKeyPress={handleKeypress}
                        maxLength="2"
                      />
                    </div>
                    <div className="form-group col-sm-12 col-md-3 col-lg-4">
                      <label htmlFor="Zip_Code">Zip Code</label>
                      <input
                        id="Zip_Code"
                        type="text"
                        className="form-control"
                        onChange={onChange}
                        onKeyPress={handleKeypress}
                        maxLength="7"
                      />
                    </div>
                  </div>
                  <div className="row">
                    <div className="form-group col-sm-12 col-md-7 col-lg-6">
                      <label htmlFor="County">County</label>
                      <input
                        id="County"
                        type="text"
                        className="form-control"
                        onChange={onChange}
                        onKeyPress={handleKeypress}
                        maxLength="100"
                      />
                    </div>
                  </div>

                  <div className="row">
                    <div className="form-group col-sm-12 col-md-6 col-lg-6">
                      <label htmlFor="Past_Addresses">
                        All Addresses that you have lived at in the past 5 years
                      </label>
                      <textarea
                        id="Past_Addresses"
                        type="text"
                        rows="4"
                        className="form-control"
                        onChange={onChange}
                        onKeyPress={handleKeypress}
                        maxLength="250"
                      />
                    </div>
                  </div>
                  <div className="form-group col-sm-12 col-md-6 col-lg-6">
                    <div className="row">
                      <br />
                      <br />
                    </div>
                  </div>
                  <div className="form-group col-sm-12 col-md-6 col-lg-6">
                    <div className="row">
                      <label
                        className="col-sm-12 col-md-8 col-lg-5"
                        htmlFor="Of_Age"
                      >
                        <span className="align-middle">
                          Are you 18yr old or Older?
                        </span>
                      </label>
                      <select
                        className="form-control col-sm-12 col-md-3 col-lg-3"
                        id="Of_Age"
                        onChange={onChange}
                        onKeyPress={handleKeypress}
                      >
                        <option value="not_answered">Select</option>
                        <option>Yes</option>
                        <option>No</option>
                      </select>
                    </div>
                  </div>
                  <div className="form-group col-sm-12 col-md-6 col-lg-6">
                    <div className="row mt-2">
                      <label
                        className="col-sm-12 col-md-12 col-lg-7"
                        htmlFor="Number_Household"
                      >
                        <span className="align-middle">
                          Number of persons living in household:
                        </span>
                      </label>
                      <input
                        id="Number_Household"
                        type="text"
                        onChange={onChange}
                        onKeyPress={handleKeypress}
                        className="form-control col-sm-12 col-md-12 col-lg-2"
                        maxLength="2"
                      />
                    </div>
                  </div>

                  <div className="row">
                    <div className="form-group col-sm-12 col-md-6 col-lg-6">
                      <label htmlFor="Age_of_Children">
                        Ages of children living or frequently visiting house:
                      </label>
                      <input
                        id="Age_of_Children"
                        type="text"
                        className="form-control"
                        onChange={onChange}
                        onKeyPress={handleKeypress}
                        maxLength="15"
                      />
                    </div>

                    <div className="form-group col-sm-12 col-md-12 col-lg-12">
                      <label
                        className="col-sm-12 col-md-12 col-lg-5"
                        htmlFor="Other_Adult_Names"
                      >
                        Names of other adults living in the household
                      </label>
                      <input
                        id="Other_Adult_Names"
                        type="text"
                        className="form-control"
                        onChange={onChange}
                        onKeyPress={handleKeypress}
                        maxLength="100"
                      />
                    </div>
                  </div>
                  <div className="form-group col-sm-12 col-md-12 col-lg-12">
                    <div className="row">
                      <label
                        className="col-sm-12 col-md-12 col-lg-7"
                        htmlFor="Adults_Aware"
                      >
                        <span className="align-middle">
                          Are all adults in your household aware that you are
                          considering adopting a pet?
                        </span>
                      </label>
                      <select
                        id="Adults_Aware"
                        type="text"
                        onChange={onChange}
                        onKeyPress={handleKeypress}
                        className="form-control col-sm-12 col-md-10 col-lg-2"
                      >
                        <option value="not_answered">Select</option>
                        <option value="Yes">Yes</option>
                        <option value="no">No</option>
                      </select>
                    </div>
                  </div>
                  <div className="form-group col-sm-12 col-md-12 col-lg-12">
                    <div className="row">
                      <label
                        className="col-sm-12 col-md-6 col-lg-5"
                        htmlFor="Primary_Caregiver"
                      >
                        Who will be the primary caregiver for your new pet?
                      </label>
                      <input
                        id="Primary_Caregiver"
                        type="text"
                        className="form-control col-sm-12 col-md-6 col-lg-6"
                        onChange={onChange}
                        onKeyPress={handleKeypress}
                        maxLength="50"
                      />
                    </div>
                  </div>

                  <div className="row bg-dark">
                    <h6 className="text-center text-light align-middle my-1 col-lg-12">
                      EMPLOYMENT INFORMATION
                    </h6>
                  </div>
                  <br />

                  <div className="row"></div>
                  <div className="row">
                    <div className="form-group col-sm-12 col-md-12">
                      <div className="row">
                        <div className="form-group col-sm-12 col-md-6">
                          <label
                            className="col-sm-12 col-md-12"
                            htmlFor="Employer1"
                          >
                            Applicant's Employer
                          </label>
                          <input
                            id="Employer1"
                            type="text"
                            className="form-control col-sm-12 col-md-12"
                            onChange={onChange}
                            onKeyPress={handleKeypress}
                            maxLength="100"
                          />
                        </div>
                        <div className="form-group col-sm-12 col-md-6">
                          <label className="col-md-12" htmlFor="Hours1">
                            Working Hours
                          </label>
                          <input
                            id="Hours1"
                            type="text"
                            className="form-control col-md-4"
                            onChange={onChange}
                            onKeyPress={handleKeypress}
                            maxLength="20"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="form-group col-sm-12 col-md-12">
                      <div className="row">
                        <div className="form-group col-sm-12 col-md-6">
                          <label
                            className="col-sm-12 col-md-12"
                            htmlFor="Employer2"
                          >
                            Co-Applicant's Employer
                          </label>
                          <input
                            id="Employer2"
                            type="text"
                            className="form-control"
                            onChange={onChange}
                            onKeyPress={handleKeypress}
                            maxLength="100"
                          />
                        </div>

                        <div className="form-group col-sm-12 col-md-6">
                          <label className="col-md-12" htmlFor="Hours2">
                            Working Hours
                          </label>
                          <input
                            id="Hours2"
                            type="text"
                            className="form-control col-md-4"
                            onChange={onChange}
                            onKeyPress={handleKeypress}
                            maxLength="20"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {/* ***************** REFERENCES ******************* */}
                <div className="container my-1 border border-dark">
                  <div className="row bg-dark">
                    <h6 className="text-center text-light align-middle my-1 col-lg-12">
                      PERSONAL REFERENCES
                    </h6>
                  </div>
                  <div className="row m-2">
                    <h6 className="ml-0">
                      Please provide two unrelated references (neighbor, friend,
                      or co-worker) that we may contact:
                    </h6>
                  </div>
                  <div className="row">
                    <div className="form-group col-sm-12 col-md-8 col-lg-8">
                      <label htmlFor="Reference_1">Reference 1 Name</label>
                      <input
                        id="Reference_1"
                        type="text"
                        className="form-control"
                        onChange={onChange}
                        onKeyPress={handleKeypress}
                        maxLength="100"
                      />
                    </div>
                    <div className="form-group col-sm-12 col-md-3 col-lg-3">
                      <label htmlFor="Reference_1_Phone">Phone</label>
                      <input
                        id="Reference_1_Phone"
                        type="Phone"
                        className="form-control"
                        onChange={onChange}
                        onKeyPress={handleKeypress}
                        maxLength="20"
                      />
                    </div>
                  </div>
                  <div className="row">
                    <div className="form-group col-sm-12 col-md-8 col-lg-8">
                      <label htmlFor="Reference_2">Reference 2 Name</label>
                      <input
                        id="Reference_2"
                        type="text"
                        className="form-control"
                        onChange={onChange}
                        onKeyPress={handleKeypress}
                        maxLength="100"
                      />
                    </div>
                    <div className="form-group col-sm-12 col-md-3 col-lg-3">
                      <label htmlFor="Reference_2_Phone">Phone</label>
                      <input
                        id="Reference_2_Phone"
                        type="phone"
                        className="form-control"
                        onChange={onChange}
                        onKeyPress={handleKeypress}
                        maxLength="20"
                      />
                    </div>
                  </div>
                </div>
                {/*  ******************* HOUSEHOLD INFORMATION *************** */}
                <div className="container-fluid my-1 border border-dark">
                  <div className="row bg-dark">
                    <h6 className="text-center text-light align-middle my-1 col-lg-12">
                      HOUSEHOLD INFORMATION
                    </h6>
                  </div>
                  <br />
                  <div className="row">
                    <p className="col-lg-3 col-xl-2">Do you live in a:</p>
                  </div>

                  <div className="row mx-2">
                    <div className="form-check col-lg-2 col-xl-1">
                      <input
                        type="checkbox"
                        className="form-check-input"
                        id="House"
                        onClick={handleCheckedBox}
                        onKeyPress={handleKeypress}
                      />
                      <label
                        className="form-check-label p-0 ml-1"
                        htmlFor="House"
                      >
                        House
                      </label>
                    </div>
                    <div className="form-check  col-lg-3 col-xl-2">
                      <input
                        type="checkbox"
                        className="form-check-input"
                        id="Condo"
                        onClick={handleCheckedBox}
                        onKeyPress={handleKeypress}
                      />
                      <label
                        className="form-check-label p-0 ml-1"
                        htmlFor="Condo"
                      >
                        Condo
                      </label>
                    </div>
                    <div className="form-check  col-lg-3 col-xl-2">
                      <input
                        type="checkbox"
                        className="form-check-input"
                        id="Townhouse"
                        onClick={handleCheckedBox}
                        onKeyPress={handleKeypress}
                      />
                      <label
                        className="form-check-label p-0 ml-1"
                        htmlFor="Townhouse"
                      >
                        Townhouse
                      </label>
                    </div>
                    <div className="form-check  col-lg-2 col-xl-2">
                      <input
                        type="checkbox"
                        className="form-check-input"
                        id="Mobile_Home"
                        onClick={handleCheckedBox}
                        onKeyPress={handleKeypress}
                      />
                      <label
                        className="form-check-label p-0 ml-1"
                        htmlFor="Mobile_Home"
                      >
                        Mobile Home
                      </label>
                    </div>
                    <div className="form-check  col-lg-2 col-xl-2">
                      <input
                        type="checkbox"
                        className="form-check-input"
                        id="Apartment"
                        onClick={handleCheckedBox}
                        onKeyPress={handleKeypress}
                      />
                      <label
                        className="form-check-label p-0 ml-1"
                        htmlFor="Apartment"
                      >
                        Apartment
                      </label>
                    </div>
                  </div>
                  <br />
                  <div className="row">
                    <div className="form-group col-11 col-sm-11 col-md-11 col-lg-11 col-xl-3 pr-0">
                      <label htmlFor="Own_Rent">
                        Do you own or rent your home?
                      </label>
                      <select
                        id="Own_Rent"
                        className="form-control"
                        onChange={onChange}
                        onKeyPress={handleKeypress}
                      >
                        <option value="not_answered">Select</option>
                        <option>Own</option>
                        <option>Rent</option>
                      </select>
                    </div>
                    <div className="form-group col-11 col-sm-11 col-md-11 col-lg-11 col-xl-3 pr-0">
                      <label htmlFor="Pets_Permitted">
                        If rent, are pets permitted?
                      </label>

                      <select
                        id="Pets_Permitted"
                        className="form-control"
                        onChange={onChange}
                        onKeyPress={handleKeypress}
                      >
                        <option value="not_answered">Select</option>
                        <option>Yes</option>
                        <option>No</option>
                      </select>
                    </div>
                    <div className="form-group col-11 col-sm-11 col-md-11 col-lg-11 col-xl-3 pr-0">
                      <label htmlFor="Landlord_Name">
                        Landlord/ Rental Agent Name
                      </label>
                      <input
                        id="Landlord_Name"
                        type="text"
                        className="form-control"
                        onChange={onChange}
                        onKeyPress={handleKeypress}
                        maxLength="100"
                      />
                    </div>
                    <div className="form-group col-11 col-sm-11 col-md-11 col-lg-11 col-xl-2 pr-0">
                      <label htmlFor="Landlord_Phone">Phone</label>
                      <input
                        id="Landlord_Phone"
                        type="phone"
                        onChange={onChange}
                        onKeyPress={handleKeypress}
                        className="form-control"
                        maxLength="20"
                      />
                    </div>
                  </div>
                  <div className="row">
                    <div className="form-group col-11 col-sm-11 col-md-11 col-lg-11 col-xl-5 pr-0">
                      <label htmlFor="Lived_Length_Home">
                        How long have you lived at your present address?
                      </label>
                      <input
                        id="Lived_Length_Home"
                        type="text"
                        onChange={onChange}
                        onKeyPress={handleKeypress}
                        className="form-control"
                        maxLength="20"
                      />
                    </div>
                  </div>
                  <div className="row">
                    <div className="form-group col-11 col-sm-11 col-md-11 col-lg-11 col-xl-5 pr-0">
                      <label htmlFor="Moving">
                        Are you planning to move in the next 6 months?
                      </label>
                      <select
                        id="Moving"
                        className="form-control"
                        onChange={onChange}
                        onKeyPress={handleKeypress}
                      >
                        <option value="not_answered">Select</option>
                        <option>Yes</option>
                        <option>No</option>
                      </select>
                    </div>
                    <div className="form-group col-11 col-sm-11 col-md-11 col-lg-11 col-xl-5 pr-0">
                      <label htmlFor="When_Moving">If yes, when</label>
                      <input
                        id="When_Moving"
                        type="text"
                        className="form-control"
                        onChange={onChange}
                        onKeyPress={handleKeypress}
                        maxLength="250"
                      />
                    </div>
                  </div>
                  <div className="row">
                    <div className="form-group col-11 col-sm-11 col-md-11 col-lg-11 col-xl-5 pr-0">
                      <label htmlFor="If_Move">
                        What will you do with your pet when/if you move?
                      </label>
                      <input
                        id="If_Move"
                        type="text"
                        className="form-control"
                        onChange={onChange}
                        onKeyPress={handleKeypress}
                        maxLength="250"
                      />
                    </div>
                    <div className="form-group col-11 col-sm-11 col-md-11 col-lg-11 col-xl-5 pr-0">
                      <label htmlFor="Kept_Where">
                        Will your pet be kept indoors or outdoors?
                      </label>
                      <select
                        id="Kept_Where"
                        className="form-control"
                        onChange={onChange}
                        onKeyPress={handleKeypress}
                      >
                        <option value="not_answered">Select</option>
                        <option>Inside</option>
                        <option>Outside</option>
                      </select>
                    </div>
                  </div>
                  <div className="row">
                    <div className="form-group col-11 col-sm-11 col-md-11 col-lg-11 col-xl-5 pr-0">
                      <label htmlFor="Fence">What type yard do you have?</label>
                      <select
                        id="Fence"
                        className="form-control"
                        onChange={onChange}
                        onKeyPress={handleKeypress}
                      >
                        <option value="not_answered">Select</option>
                        <option>Fenced Yard</option>
                        <option>Outside dog pen or kennel</option>
                        <option>Neither</option>
                      </select>
                    </div>
                    <div className="form-group col-11 col-sm-11 col-md-11 col-lg-11 col-xl-6 pr-0">
                      <label htmlFor="Fence_Size">
                        If fence/pen/kennel, please describe area size, material
                        used, height
                      </label>
                      <input
                        id="Fence_Size"
                        type="text"
                        onChange={onChange}
                        onKeyPress={handleKeypress}
                        className="form-control"
                        maxLength="250"
                      />
                    </div>
                  </div>
                  <div className="row">
                    <div className="form-group col-11 col-sm-11 col-md-11 col-lg-11 col-xl-6 pr-0">
                      <label htmlFor="Location_When_Home">
                        Where will your pet spend most of his/her time when you
                        are home?
                      </label>
                      <input
                        id="Location_When_Home"
                        type="text"
                        className="form-control"
                        maxLength="250"
                        onChange={onChange}
                        onKeyPress={handleKeypress}
                      />
                    </div>
                    <div className="form-group col-11 col-sm-11 col-md-11 col-lg-11 col-xl-5 pr-0">
                      <label htmlFor="Alone_Time">
                        How many hours will your pet be alone during the day?
                      </label>
                      <input
                        id="Alone_Time"
                        type="text"
                        onChange={onChange}
                        onKeyPress={handleKeypress}
                        className="form-control"
                        maxLength="20"
                      />
                    </div>
                  </div>
                  <div className="row">
                    <div className="form-group col-11 col-sm-11 col-md-11 col-lg-11 col-xl-6 pr-0">
                      <label htmlFor="Location_When_Not_Home">
                        Where will you keep your pet when you are not home
                        during the day?
                      </label>
                      <input
                        id="Location_When_Not_Home"
                        type="text"
                        onChange={onChange}
                        onKeyPress={handleKeypress}
                        className="form-control"
                        maxLength="250"
                      />
                    </div>
                    <div className="form-group col-11 col-sm-11 col-md-11 col-lg-11 col-xl-5 pr-0">
                      <label htmlFor="Location_When_Traveling">
                        Where will you keep your pet when you travel?
                      </label>
                      <input
                        id="Location_When_Traveling"
                        type="text"
                        onChange={onChange}
                        onKeyPress={handleKeypress}
                        className="form-control"
                        maxLength="250"
                      />
                    </div>
                  </div>
                  <div className="row">
                    <div className="form-group col-11 col-sm-11 col-md-11 col-lg-11 col-xl-4 pr-0">
                      <label htmlFor="Location_When_Sleeping">
                        Where will your pet sleep at night?
                      </label>
                      <input
                        id="Location_When_Sleeping"
                        type="text"
                        className="form-control"
                        onChange={onChange}
                        onKeyPress={handleKeypress}
                        maxLength="250"
                      />
                    </div>
                    <div className="form-group col-11 col-sm-11 col-md-11 col-lg-11 col-xl-7 pr-0">
                      <label htmlFor="Pet_Food">
                        What have you fed your prior or current dogs or will you
                        feed your new dog?
                      </label>
                      <input
                        id="Pet_Food"
                        type="text"
                        className="form-control"
                        onChange={onChange}
                        onKeyPress={handleKeypress}
                        maxLength="100"
                      />
                    </div>
                  </div>
                  <div className="row">
                    <div className="form-group col-11 col-sm-11 col-md-11 col-lg-11 col-xl-11 pr-0">
                      <label htmlFor="Convictions">
                        To the best of your knowledge, has any member of your
                        household ever been convicted of an animal welfare law
                        violation such as neglect, cruelty, abandonment, etc.?{' '}
                      </label>
                      <select
                        id="Convictions"
                        className="form-control"
                        onChange={onChange}
                        onKeyPress={handleKeypress}
                      >
                        <option value="not_answered">Select</option>
                        <option>YES</option>
                        <option>NO</option>
                      </select>
                    </div>
                  </div>
                </div>
                {/* ***************** PET HISTORY   *********************************** */}
                <div className="container bg-white border border-dark p-0 my-1">
                  <div className="row ml-2">
                    <h5 className="m-2">PET HISTORY </h5>
                    <hr />
                  </div>
                  {/* ******************************************* */}
                  <div className="container-fluid my-1 border border-dark">
                    <div className="row bg-dark">
                      <h6 className="text-center text-light align-middle my-1 col-lg-12">
                        PET 1 INFORMATION
                      </h6>
                    </div>
                    <div className="row mt-2">
                      <div className="form-group col-11 col-sm-11 col-md-11 col-lg-3 col-xl-3 pr-0">
                        <label htmlFor="Pet1_Breed ">Type/Breed</label>
                        <input
                          id="Pet1_Breed"
                          type="text"
                          className="form-control"
                          onChange={onChange}
                          onKeyPress={handleKeypress}
                          maxLength="50"
                        />
                      </div>
                      <div className="form-group col-11 col-sm-11 col-md-11 col-lg-3 col-xl-4 pr-0">
                        <label htmlFor="Pet1_Name">Name of Pet</label>
                        <input
                          id="Pet1_Name"
                          type="text"
                          className="form-control"
                          onChange={onChange}
                          onKeyPress={handleKeypress}
                          maxLength="100"
                        />
                      </div>
                      <div className="form-group col-6 col-sm-6 col-md-6 col-lg-1 col-xl-1 pr-0">
                        <label htmlFor="Pet1_Sex">Sex</label>
                        <select
                          id="Pet1_Sex"
                          className="form-control"
                          onChange={onChange}
                          onKeyPress={handleKeypress}
                        >
                          <option value="not_answered">Select</option>
                          <option>M</option>
                          <option>F</option>
                        </select>
                      </div>
                      <div className="form-group col-5 col-sm-5 col-md-5 col-lg-1 col-xl-1 pr-0">
                        <label htmlFor="Pet1_Age">Age</label>
                        <input
                          id="Pet1_Age"
                          type="number"
                          className="form-control text-center"
                          onChange={onChange}
                          onKeyPress={handleKeypress}
                          maxLength="2"
                        />
                      </div>
                      <div className="form-group col-11 col-sm-11 col-md-11 col-lg-3 col-xl-3 pr-0">
                        <label htmlFor="Pet1_Fixed">Spayed/Neutered</label>
                        <select
                          id="Pet1_Fixed"
                          className="form-control"
                          onChange={onChange}
                          onKeyPress={handleKeypress}
                        >
                          <option value="not_answered">Select</option>
                          <option>Yes</option>
                          <option>No</option>
                        </select>
                      </div>
                    </div>
                    <div className="row">
                      <div className="form-group col-11 col-sm-11 col-md-11 col-lg-4 col-xl-4 pr-0">
                        <label htmlFor="Pet1_Vac">
                          Preventatives vaccines / Rabies & Heartworm
                        </label>
                        <select
                          id="Pet1_Vac"
                          className="form-control"
                          onChange={onChange}
                          onKeyPress={handleKeypress}
                        >
                          <option value="not_answered">Select</option>
                          <option>Yes</option>
                          <option>No</option>
                        </select>
                      </div>
                      <div className="form-group col-11 col-sm-11 col-md-11 col-lg-2 col-xl-2 pr-0">
                        <label htmlFor="Pet1_How_long">
                          How long in your care?
                        </label>
                        <input
                          id="Pet1_How_long"
                          type="text"
                          className="form-control"
                          onChange={onChange}
                          onKeyPress={handleKeypress}
                          maxLength="10"
                        />
                      </div>
                      <div className="form-group col-11 col-sm-11 col-md-11 col-lg-2 col-xl-3 pr-0">
                        <label htmlFor="Pet1_Kept">
                          Kept inside or outside?
                        </label>
                        <select
                          id="Pet1_Kept"
                          className="form-control"
                          onChange={onChange}
                          onKeyPress={handleKeypress}
                        >
                          <option value="not_answered">Select</option>
                          <option>Inside</option>
                          <option>Outside</option>
                        </select>
                      </div>
                      <div className="form-group col-11 col-sm-11 col-md-11 col-lg-2 col-xl-3 pr-0">
                        <label htmlFor="Pet1_WhereNow">
                          Where is this pet now?
                        </label>
                        <input
                          id="Pet1_WhereNow"
                          type="text"
                          className="form-control"
                          onChange={onChange}
                          onKeyPress={handleKeypress}
                          maxLength="50"
                        />
                      </div>
                    </div>
                  </div>
                  {/* ******************************************* */}
                  <div className="container-fluid my-1 border border-dark">
                    <div className="row bg-dark">
                      <h6 className="text-center text-light align-middle my-1 col-lg-12">
                        PET 2 INFORMATION
                      </h6>
                    </div>
                    <div className="row mt-2">
                      <div className="form-group col-11 col-sm-11 col-md-11 col-lg-3 col-xl-3 pr-0">
                        <label htmlFor="Pet2_Breed ">Type/Breed</label>
                        <input
                          id="Pet2_Breed"
                          type="text"
                          className="form-control"
                          onChange={onChange}
                          onKeyPress={handleKeypress}
                          maxLength="50"
                        />
                      </div>
                      <div className="form-group col-11 col-sm-11 col-md-11 col-lg-3 col-xl-4 pr-0">
                        <label htmlFor="Pet2_Name">Name of Pet</label>
                        <input
                          id="Pet2_Name"
                          type="text"
                          className="form-control"
                          onChange={onChange}
                          onKeyPress={handleKeypress}
                          maxLength="100"
                        />
                      </div>
                      <div className="form-group col-6 col-sm-6 col-md-6 col-lg-1 col-xl-1 pr-0">
                        <label htmlFor="Pet2_Sex">Sex</label>
                        <select
                          id="Pet2_Sex"
                          className="form-control"
                          onChange={onChange}
                          onKeyPress={handleKeypress}
                        >
                          <option value="not_answered">Select</option>
                          <option>M</option>
                          <option>F</option>
                        </select>
                      </div>
                      <div className="form-group col-5 col-sm-5 col-md-5 col-lg-1 col-xl-1 pr-0">
                        <label htmlFor="Pet2_Age">Age</label>
                        <input
                          id="Pet2_Age"
                          type="number"
                          className="form-control text-center"
                          onChange={onChange}
                          onKeyPress={handleKeypress}
                          maxLength="2"
                        />
                      </div>
                      <div className="form-group col-11 col-sm-11 col-md-11 col-lg-3 col-xl-3 pr-0">
                        <label htmlFor="Pet2_Fixed">Spayed/Neutered</label>
                        <select
                          id="Pet2_Fixed"
                          className="form-control"
                          onChange={onChange}
                          onKeyPress={handleKeypress}
                        >
                          <option value="not_answered">Select</option>
                          <option>Yes</option>
                          <option>No</option>
                        </select>
                      </div>
                    </div>
                    <div className="row">
                      <div className="form-group col-11 col-sm-11 col-md-11 col-lg-4 col-xl-4 pr-0">
                        <label htmlFor="Pet2_Vac">
                          Preventatives vaccines / Rabies & Heartworm
                        </label>
                        <select
                          id="Pet2_Vac"
                          className="form-control"
                          onChange={onChange}
                          onKeyPress={handleKeypress}
                        >
                          <option value="not_answered">Select</option>
                          <option>Yes</option>
                          <option>No</option>
                        </select>
                      </div>
                      <div className="form-group col-11 col-sm-11 col-md-11 col-lg-2 col-xl-2 pr-0">
                        <label htmlFor="Pet2_How_long">
                          How long in your care?
                        </label>
                        <input
                          id="Pet2_How_long"
                          type="text"
                          className="form-control"
                          onChange={onChange}
                          onKeyPress={handleKeypress}
                          maxLength="10"
                        />
                      </div>
                      <div className="form-group col-11 col-sm-11 col-md-11 col-lg-2 col-xl-3 pr-0">
                        <label htmlFor="Pet2_Kept">
                          Kept inside or outside?
                        </label>
                        <select
                          id="Pet2_Kept"
                          className="form-control"
                          onChange={onChange}
                          onKeyPress={handleKeypress}
                        >
                          <option value="not_answered">Select</option>
                          <option>Inside</option>
                          <option>Outside</option>
                        </select>
                      </div>
                      <div className="form-group col-11 col-sm-11 col-md-11 col-lg-2 col-xl-3 pr-0">
                        <label htmlFor="Pet2_WhereNow">
                          Where is this pet now?
                        </label>
                        <input
                          id="Pet2_WhereNow"
                          type="text"
                          className="form-control"
                          onChange={onChange}
                          onKeyPress={handleKeypress}
                          maxLength="50"
                        />
                      </div>
                    </div>
                  </div>
                  {/* ************************************************* */}
                  <div className="container-fluid my-1 border border-dark">
                    <div className="row bg-dark">
                      <h6 className="text-center text-light align-middle my-1 col-lg-12">
                        PET 3 INFORMATION
                      </h6>
                    </div>
                    <div className="row mt-2">
                      <div className="form-group col-11 col-sm-11 col-md-11 col-lg-3 col-xl-3 pr-0">
                        <label htmlFor="Pet3_Breed ">Type/Breed</label>
                        <input
                          id="Pet3_Breed"
                          type="text"
                          className="form-control"
                          onChange={onChange}
                          onKeyPress={handleKeypress}
                          maxLength="50"
                        />
                      </div>
                      <div className="form-group col-11 col-sm-11 col-md-11 col-lg-3 col-xl-4 pr-0">
                        <label htmlFor="Pet3_Name">Name of Pet</label>
                        <input
                          id="Pet3_Name"
                          type="text"
                          className="form-control"
                          onChange={onChange}
                          onKeyPress={handleKeypress}
                          maxLength="100"
                        />
                      </div>
                      <div className="form-group col-6 col-sm-6 col-md-6 col-lg-1 col-xl-1 pr-0">
                        <label htmlFor="Pet3_Sex">Sex</label>
                        <select
                          id="Pet3_Sex"
                          className="form-control"
                          onChange={onChange}
                          onKeyPress={handleKeypress}
                        >
                          <option value="not_answered">Select</option>
                          <option>M</option>
                          <option>F</option>
                        </select>
                      </div>
                      <div className="form-group col-5 col-sm-5 col-md-5 col-lg-1 col-xl-1 pr-0">
                        <label htmlFor="Pet3_Age">Age</label>
                        <input
                          id="Pet3_Age"
                          type="number"
                          className="form-control text-center"
                          onChange={onChange}
                          onKeyPress={handleKeypress}
                          maxLength="2"
                        />
                      </div>
                      <div className="form-group col-11 col-sm-11 col-md-11 col-lg-3 col-xl-3 pr-0">
                        <label htmlFor="Pet3_Fixed">Spayed/Neutered</label>
                        <select
                          id="Pet3_Fixed"
                          className="form-control"
                          onChange={onChange}
                          onKeyPress={handleKeypress}
                        >
                          <option value="not_answered">Select</option>
                          <option>Yes</option>
                          <option>No</option>
                        </select>
                      </div>
                    </div>
                    <div className="row">
                      <div className="form-group col-11 col-sm-11 col-md-11 col-lg-4 col-xl-4 pr-0">
                        <label htmlFor="Pet3_Vac">
                          Preventatives vaccines / Rabies & Heartworm
                        </label>
                        <select
                          id="Pet3_Vac"
                          className="form-control"
                          onChange={onChange}
                          onKeyPress={handleKeypress}
                        >
                          <option value="not_answered">Select</option>
                          <option>Yes</option>
                          <option>No</option>
                        </select>
                      </div>
                      <div className="form-group col-11 col-sm-11 col-md-11 col-lg-2 col-xl-2 pr-0">
                        <label htmlFor="Pet3_How_long">
                          How long in your care?
                        </label>
                        <input
                          id="Pet3_How_long"
                          type="text"
                          className="form-control"
                          onChange={onChange}
                          onKeyPress={handleKeypress}
                          maxLength="10"
                        />
                      </div>
                      <div className="form-group col-11 col-sm-11 col-md-11 col-lg-2 col-xl-3 pr-0">
                        <label htmlFor="Pet3_Kept">
                          Kept inside or outside?
                        </label>
                        <select
                          id="Pet3_Kept"
                          className="form-control"
                          onChange={onChange}
                          onKeyPress={handleKeypress}
                        >
                          <option value="not_answered">Select</option>
                          <option>Inside</option>
                          <option>Outside</option>
                        </select>
                      </div>
                      <div className="form-group col-11 col-sm-11 col-md-11 col-lg-2 col-xl-3 pr-0">
                        <label htmlFor="Pet3_WhereNow">
                          Where is this pet now?
                        </label>
                        <input
                          id="Pet3_WhereNow"
                          type="text"
                          className="form-control"
                          onChange={onChange}
                          onKeyPress={handleKeypress}
                          maxLength="50"
                        />
                      </div>
                    </div>
                  </div>
                  {/* ************************************************* */}
                  <div className="container-fluid my-1 border border-dark">
                    <div className="row bg-dark">
                      <h6 className="text-center text-light align-middle my-1 col-lg-12">
                        PET 4 INFORMATION
                      </h6>
                    </div>
                    <div className="row mt-2">
                      <div className="form-group col-11 col-sm-11 col-md-11 col-lg-3 col-xl-3 pr-0">
                        <label htmlFor="Pet4_Breed ">Type/Breed</label>
                        <input
                          id="Pet4_Breed"
                          type="text"
                          className="form-control"
                          onChange={onChange}
                          onKeyPress={handleKeypress}
                          maxLength="50"
                        />
                      </div>
                      <div className="form-group col-11 col-sm-11 col-md-11 col-lg-3 col-xl-4 pr-0">
                        <label htmlFor="Pet4_Name">Name of Pet</label>
                        <input
                          id="Pet4_Name"
                          type="text"
                          className="form-control"
                          onChange={onChange}
                          onKeyPress={handleKeypress}
                          maxLength="100"
                        />
                      </div>
                      <div className="form-group col-6 col-sm-6 col-md-6 col-lg-1 col-xl-1 pr-0">
                        <label htmlFor="Pet4_Sex">Sex</label>
                        <select
                          id="Pet4_Sex"
                          className="form-control"
                          onChange={onChange}
                          onKeyPress={handleKeypress}
                        >
                          <option value="not_answered">Select</option>
                          <option>M</option>
                          <option>F</option>
                        </select>
                      </div>
                      <div className="form-group col-5 col-sm-5 col-md-5 col-lg-1 col-xl-1 pr-0">
                        <label htmlFor="Pet4_Age">Age</label>
                        <input
                          id="Pet4_Age"
                          type="number"
                          className="form-control text-center"
                          onChange={onChange}
                          onKeyPress={handleKeypress}
                          maxLength="2"
                        />
                      </div>
                      <div className="form-group col-11 col-sm-11 col-md-11 col-lg-3 col-xl-3 pr-0">
                        <label htmlFor="Pet4_Fixed">Spayed/Neutered</label>
                        <select
                          id="Pet4_Fixed"
                          className="form-control"
                          onChange={onChange}
                          onKeyPress={handleKeypress}
                        >
                          <option value="not_answered">Select</option>
                          <option>Yes</option>
                          <option>No</option>
                        </select>
                      </div>
                    </div>
                    <div className="row">
                      <div className="form-group col-11 col-sm-11 col-md-11 col-lg-4 col-xl-4 pr-0">
                        <label htmlFor="Pet4_Vac">
                          Preventatives vaccines / Rabies & Heartworm
                        </label>
                        <select
                          id="Pet4_Vac"
                          className="form-control"
                          onChange={onChange}
                          onKeyPress={handleKeypress}
                        >
                          <option value="not_answered">Select</option>
                          <option>Yes</option>
                          <option>No</option>
                        </select>
                      </div>
                      <div className="form-group col-11 col-sm-11 col-md-11 col-lg-2 col-xl-2 pr-0">
                        <label htmlFor="Pet4_How_long">
                          How long in your care?
                        </label>
                        <input
                          id="Pet4_How_long"
                          type="text"
                          className="form-control"
                          onChange={onChange}
                          onKeyPress={handleKeypress}
                          maxLength="10"
                        />
                      </div>
                      <div className="form-group col-11 col-sm-11 col-md-11 col-lg-2 col-xl-3 pr-0">
                        <label htmlFor="Pet4_Kept">
                          Kept inside or outside?
                        </label>
                        <select
                          id="Pet4_Kept"
                          className="form-control"
                          onChange={onChange}
                          onKeyPress={handleKeypress}
                        >
                          <option value="not_answered">Select</option>
                          <option>Inside</option>
                          <option>Outside</option>
                        </select>
                      </div>
                      <div className="form-group col-11 col-sm-11 col-md-11 col-lg-2 col-xl-3 pr-0">
                        <label htmlFor="Pet4_WhereNow">
                          Where is this pet now?
                        </label>
                        <input
                          id="Pet4_WhereNow"
                          type="text"
                          className="form-control"
                          onChange={onChange}
                          onKeyPress={handleKeypress}
                          maxLength="50"
                        />
                      </div>
                    </div>
                  </div>
                  {/* ************************************************* */}
                  <div className="container-fluid my-1 border border-dark">
                    <div className="row bg-dark">
                      <h6 className="text-center text-light align-middle my-1 col-lg-12">
                        VETERINARIAN INFORMATION
                      </h6>
                    </div>
                    <div className="row mt-2">
                      <div className="form-group col-11 col-sm-11 col-md-11 col-lg-3 col-xl-5 pr-0">
                        <label htmlFor="Vet_Name">Primary Vet Used</label>
                        <input
                          id="Vet_Name"
                          type="text"
                          className="form-control"
                          onChange={onChange}
                          onKeyPress={handleKeypress}
                          maxLength="100"
                        />
                      </div>
                      <div className="form-group col-11 col-sm-11 col-md-11 col-lg-3 col-xl-4 pr-0">
                        <label htmlFor="Vet_Number">Vet Number</label>
                        <input
                          id="Vet_Number"
                          type="phone"
                          className="form-control"
                          onChange={onChange}
                          onKeyPress={handleKeypress}
                          maxLength="20"
                        />
                      </div>
                    </div>
                    <div className="row">
                      <div className="form-group col-11 col-sm-11 col-md-11 col-lg-11 col-xl-11 pr-0">
                        <label htmlFor="Other_Vet">
                          If you had other Veterinarians providing part of the
                          routine care, please provide their names and phone
                          number as well
                        </label>
                        <input
                          id="Other_Vet"
                          type="text"
                          className="form-control"
                          onChange={onChange}
                          onKeyPress={handleKeypress}
                          maxLength="250"
                        />
                      </div>
                    </div>
                    <div className="row">
                      <div className="form-group col-11 col-sm-11 col-md-11 col-lg-11 col-xl-11 pr-0">
                        <label htmlFor="Vet_Perm">
                          Do We have your permission to contact your
                          veterinarian for a reference?
                        </label>
                        <select
                          id="Vet_Perm"
                          className="form-control"
                          onChange={onChange}
                          onKeyPress={handleKeypress}
                        >
                          <option value="not_answered">Select</option>
                          <option>Yes</option>
                          <option>No</option>
                        </select>
                      </div>
                    </div>
                  </div>
                  <div className="container-fluid my-1 border border-dark">
                    <div className="row bg-dark">
                      <h6 className="text-center text-light align-middle my-1 col-lg-12">
                        ADDITIONAL INFORMATION
                      </h6>
                    </div>
                    <div className="row mx-2">
                      <p>
                        Please note that we will not consider applicants who
                        have not provided regular vet care to their prior pets.
                        if there are reasons why vaccinations or heartworm
                        preventatives were not provided you must clearly in the
                        space provided below
                      </p>
                    </div>
                    <div className="row">
                      <div className="form-group col-11 col-sm-11 col-md-11 col-lg-11 col-xl-11 pr-0">
                        <textarea
                          id="Vac_Explain"
                          type="text"
                          className="form-control"
                          rows="4"
                          onChange={onChange}
                          onKeyPress={handleKeypress}
                        />
                      </div>
                    </div>
                    <div className="form-group col-11 col-sm-11 col-md-11 col-lg-11 col-xl-11 pr-0">
                      <div className="row">
                        <label
                          htmlFor="Policy_Acknowledged"
                          className="col-11 col-sm-11 col-md-11 col-lg-6 col-xl-5 pr-0 text-middle"
                        >
                          Please acknowledge your understanding of this policy
                        </label>
                        <select
                          id="Policy_Acknowledged"
                          className="form-control col-11 col-sm-11 col-md-11 col-lg-3 col-xl-3 pr-0 "
                          onChange={onChange}
                          onKeyPress={handleKeypress}
                        >
                          <option value="not_answered">Select</option>
                          <option>I Understand</option>
                          <option>Do Not Understand</option>
                        </select>
                      </div>
                    </div>
                    <div className="row mx-2">
                      <p>Were any of your animals:</p>
                    </div>
                    <div className="row mx-2">
                      <div className="form-check col-xl-2">
                        <input
                          type="checkbox"
                          className="form-check-input"
                          id="Killed_By_Vehicle"
                          onChange={handleCheckedBox}
                          onKeyPress={handleKeypress}
                        />
                        <label
                          className="form-check-label p-0 ml-1"
                          htmlFor="Killed_By_Vehicle"
                        >
                          Killed by moving Vehicle
                        </label>
                      </div>
                      <div className="form-check col-xl-2">
                        <input
                          type="checkbox"
                          className="form-check-input"
                          id="Given_Away"
                          onChange={handleCheckedBox}
                          onKeyPress={handleKeypress}
                        />
                        <label
                          className="form-check-label p-0 ml-1"
                          htmlFor="Given_Away"
                        >
                          Given Away
                        </label>
                      </div>
                      <div className="form-check col-xl-2">
                        <input
                          type="checkbox"
                          className="form-check-input"
                          id="Lost_Stolen"
                          onChange={handleCheckedBox}
                          onKeyPress={handleKeypress}
                        />
                        <label
                          className="form-check-label p-0 ml-1"
                          htmlFor="Lost_Stolen"
                        >
                          Lost or Stolen
                        </label>
                      </div>
                      <div className="form-check col-xl-3">
                        <input
                          type="checkbox"
                          className="form-check-input"
                          id="Turned_In"
                          onChange={handleCheckedBox}
                          onKeyPress={handleKeypress}
                        />

                        <label
                          className="form-check-label p-0 ml-1"
                          htmlFor="Turned_In"
                        >
                          Turned into shelter or rescue
                        </label>
                      </div>
                      <div className="form-check col-xl-2">
                        <input
                          type="checkbox"
                          className="form-check-input"
                          id="None_Apply"
                          onChange={handleCheckedBox}
                          onKeyPress={handleKeypress}
                        />
                        <label
                          className="form-check-label p-0 ml-1"
                          htmlFor="None_Apply"
                        >
                          None Apply
                        </label>
                      </div>
                    </div>
                    <div className="row mt-3 ml-2">
                      <p className="font-weight-bold">
                        If yes, please explain:
                      </p>
                    </div>
                    <div className="row">
                      <div className="form-group col-11 col-sm-11 col-md-11 col-lg-11 col-xl-11 pr-0">
                        <textarea
                          id="Outcome_Explain"
                          type="text"
                          className="form-control"
                          rows="4"
                          onChange={onChange}
                          onKeyPress={handleKeypress}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="container bg-white border border-dark my-1">
                  {/* ******************************************* */}
                  <div className="row bg-dark">
                    <h6 className="text-center text-light align-middle my-1 col-lg-12">
                      PREPARING FOR YOUR NEW DOG OR PUPPY
                    </h6>
                  </div>
                  <div className="row pt-2">
                    <div className="form-group col-11 col-sm-11 col-md-11 col-lg-11 col-xl-11">
                      <label className="active" htmlFor="Preperations">
                        What have you done to prepare yourself and your family
                        for the adoption of a new pet?
                      </label>
                      <textarea
                        id="Preperations"
                        type="text"
                        className="form-control"
                        rows="4"
                        onChange={onChange}
                        onKeyPress={handleKeypress}
                      />
                    </div>
                  </div>

                  <div className="row">
                    <div className="form-group col-11 col-sm-11 col-md-11 col-lg-11 col-xl-11 pr-0">
                      <label htmlFor="Grooming">
                        What level of grooming responsibility will you accept in
                        association with your new pet?
                      </label>
                      <input
                        id="Grooming"
                        type="text"
                        className="form-control"
                        onChange={onChange}
                        onKeyPress={handleKeypress}
                        maxLength="100"
                      />
                    </div>
                  </div>
                  <div className="row">
                    <div className="form-group col-11 col-sm-11 col-md-11 col-lg-11 col-xl-11 pr-0">
                      <label htmlFor="Allergies">
                        Does anyone in your household have allergies to dander /
                        hair/ saliva?
                      </label>
                      <select
                        id="Allergies"
                        className="form-control"
                        onChange={onChange}
                      >
                        <option value="not_answered">Select</option>
                        <option>YES</option>
                        <option>NO</option>
                      </select>
                    </div>
                  </div>
                  <div className="row">
                    <div className="form-group col-11 col-sm-11 col-md-11 col-lg-11 col-xl-11 pr-0">
                      <label htmlFor="If_Allergies">
                        If If_Allergies exist/develop, are you willing to take
                        actions to keep the dog?
                      </label>
                      <select
                        id="If_Allergies"
                        className="form-control"
                        onChange={onChange}
                        onKeyPress={handleKeypress}
                      >
                        <option value="not_answered">Select</option>
                        <option>YES</option>
                        <option>NO</option>
                      </select>
                    </div>
                  </div>
                  <div className="row">
                    <div className="form-group col-11 col-sm-11 col-md-11 col-lg-11 col-xl-11 pr-0">
                      <label htmlFor="Training">
                        Are you planning to attend obedience classes with your
                        dog/puppy?
                      </label>
                      <select
                        id="Training"
                        className="form-control"
                        onChange={onChange}
                        onKeyPress={handleKeypress}
                      >
                        <option value="not_answered">Select</option>
                        <option>YES</option>
                        <option>NO</option>
                      </select>
                    </div>
                  </div>
                  <div className="row">
                    <div className="form-group col-11 col-sm-11 col-md-11 col-lg-11 col-xl-11 pr-0">
                      <label htmlFor="Normal_Behavior">
                        What do you consider to be normal dog/puppy behavior
                        problems?
                      </label>
                      <input
                        id="Normal_Behavior"
                        type="text"
                        className="form-control"
                        onChange={onChange}
                        onKeyPress={handleKeypress}
                        maxLength="100"
                      />
                    </div>
                  </div>
                  <div className="row">
                    <div className="form-group col-11 col-sm-11 col-md-11 col-lg-11 col-xl-11 pr-0">
                      <label htmlFor="Acclimate">
                        How long do you feel it will take for a dog/puppy to
                        acclimate to a new houshold?
                      </label>
                      <input
                        id="Acclimate"
                        type="text"
                        className="form-control"
                        onChange={onChange}
                        onKeyPress={handleKeypress}
                        maxLength="50"
                      />
                    </div>
                  </div>
                  <div className="row">
                    <div className="form-group col-11 col-sm-11 col-md-11 col-lg-11 col-xl-11">
                      <label className="active" htmlFor="Introductions">
                        How will you introduce your new dog to your existing
                        pet(s)?
                      </label>
                      <textarea
                        id="Introductions"
                        type="text"
                        className="form-control"
                        rows="4"
                        onChange={onChange}
                        onKeyPress={handleKeypress}
                      />
                    </div>
                  </div>
                  <div className="row">
                    <div className="form-group col-11 col-sm-11 col-md-11 col-lg-11 col-xl-11 pr-0">
                      <label htmlFor="Exercise">
                        How will you exercise the dog/puppy?
                      </label>
                      <input
                        id="Exercise"
                        type="text"
                        className="form-control"
                        onChange={onChange}
                        onKeyPress={handleKeypress}
                        maxLength="100"
                      />
                    </div>
                  </div>
                  <div className="row">
                    <div className="form-group col-11 col-sm-11 col-md-11 col-lg-11 col-xl-11 pr-0">
                      <label htmlFor="Housebreak">
                        How will you housebreak the dog/puppy?
                      </label>
                      <input
                        id="Housebreak"
                        type="text"
                        className="form-control"
                        onChange={onChange}
                        onKeyPress={handleKeypress}
                        maxLength="100"
                      />
                    </div>
                  </div>
                  <div className="row">
                    <div className="form-group col-11 col-sm-11 col-md-11 col-lg-11 col-xl-11 pr-0">
                      <label htmlFor="Corrections">
                        How will you correct the dog/puppy when there is a potty
                        accident?
                      </label>
                      <input
                        id="Corrections"
                        type="text"
                        className="form-control"
                        onChange={onChange}
                        onKeyPress={handleKeypress}
                        maxLength="100"
                      />
                    </div>
                  </div>
                  <div className="row">
                    <div className="form-group col-11 col-sm-11 col-md-11 col-lg-11 col-xl-11">
                      <label className="active" htmlFor="Encourage">
                        How will you encourage appropriate behavior and prevent
                        inappropriate behavior?
                      </label>
                      <textarea
                        id="Encourage"
                        type="text"
                        className="form-control"
                        rows="4"
                        onChange={onChange}
                        onKeyPress={handleKeypress}
                      />
                    </div>
                  </div>
                  <div className="row">
                    <div className="form-group col-11 col-sm-11 col-md-11 col-lg-11 col-xl-11 pr-0">
                      <label htmlFor="Lifetime_Commitment">
                        Are you willing to make a lifetime commitment to this
                        dog?
                      </label>
                      <select
                        id="Lifetime_Commitment"
                        className="form-control"
                        onChange={onChange}
                        onKeyPress={handleKeypress}
                      >
                        <option value="not_answered">Select</option>
                        <option>YES</option>
                        <option>NO</option>
                      </select>
                    </div>
                  </div>
                  <div className="row">
                    <div className="form-group col-11 col-sm-11 col-md-11 col-lg-11 col-xl-11 pr-0">
                      <label htmlFor="Will_Give_Preventatives">
                        Will you give heartworm tablets/flea and tick
                        preventative every month all year round?
                      </label>
                      <select
                        id="Will_Give_Preventatives"
                        className="form-control"
                        onChange={onChange}
                        onKeyPress={handleKeypress}
                      >
                        <option value="not_answered">Select</option>
                        <option>YES</option>
                        <option>NO</option>
                      </select>
                    </div>
                  </div>
                  <div className="row">
                    <div className="form-group col-11 col-sm-11 col-md-11 col-lg-11 col-xl-11 pr-0">
                      <label htmlFor="Medical_Care">
                        Will you provide all required vaccinations and medical
                        care?
                      </label>
                      <select
                        id="Medical_Care"
                        className="form-control"
                        onChange={onChange}
                        onKeyPress={handleKeypress}
                      >
                        <option value="not_answered">Select</option>
                        <option>YES</option>
                        <option>NO</option>
                      </select>
                    </div>
                  </div>
                  <div className="row">
                    <div className="form-group col-11 col-sm-11 col-md-11 col-lg-11 col-xl-11 pr-0">
                      <label htmlFor="Costs">
                        How much do you think it costs, per year, to own a dog?
                      </label>
                      <input
                        id="Costs"
                        type="text"
                        className="form-control"
                        onChange={onChange}
                        onKeyPress={handleKeypress}
                        maxLength="50"
                      />
                    </div>
                  </div>
                  <div className="row">
                    <div className="form-group col-11 col-sm-11 col-md-11 col-lg-11 col-xl-11 pr-0">
                      <label htmlFor="Expense">
                        Are you willing to spend the money necessary updating
                        shots on time, spay/neutering, and emergency care for
                        your pet?
                      </label>
                      <select
                        id="Expense"
                        className="form-control"
                        onChange={onChange}
                        onKeyPress={handleKeypress}
                      >
                        <option value="not_answered">Select</option>
                        <option>YES</option>
                        <option>NO</option>
                      </select>
                    </div>
                  </div>

                  {/* ******************************************************************************************** */}
                  <div className="form-group col-11 col-sm-11 col-md-11 col-lg-11 col-xl-12 pr-0">
                    <div className="row">
                      <label htmlFor="Divorce">
                        Which of the following would force you to give up your
                        pet? (check all that may apply)
                      </label>
                    </div>
                    <div className="row">
                      <div className="form-check  col-xl-3">
                        <input
                          type="checkbox"
                          className="form-check-input"
                          id="Divorce"
                          onChange={handleCheckedBox}
                          onKeyPress={handleKeypress}
                        />
                        <label
                          className="form-check-label p-0 ml-1"
                          htmlFor="Divorce"
                        >
                          Divorce
                        </label>
                      </div>
                      <div className="form-check   col-xl-3">
                        <input
                          type="checkbox"
                          className="form-check-input"
                          id="Move_Out_Of_State"
                          onChange={handleCheckedBox}
                          onKeyPress={handleKeypress}
                        />
                        <label
                          className="form-check-label p-0 ml-1"
                          htmlFor="Move_Out_Of_State"
                        >
                          Move Out Of State
                        </label>
                      </div>
                      <div className="form-check  col-xl-3">
                        <input
                          type="checkbox"
                          className="form-check-input"
                          id="Move_No_Pets"
                          onChange={handleCheckedBox}
                          onKeyPress={handleKeypress}
                        />
                        <label
                          className="form-check-label p-0 ml-1"
                          htmlFor="Move_No_Pets"
                        >
                          Move where pets aren't allowed
                        </label>
                      </div>
                      <div className="form-check col-xl-3">
                        <input
                          type="checkbox"
                          className="form-check-input"
                          id="Barks"
                          onChange={handleCheckedBox}
                          onKeyPress={handleKeypress}
                        />
                        <label
                          className="form-check-label p-0 ml-1"
                          htmlFor="Barks"
                        >
                          Barks a lot
                        </label>
                      </div>
                      <div className="form-check   col-xl-3">
                        <input
                          type="checkbox"
                          className="form-check-input"
                          id="Bills"
                          onChange={handleCheckedBox}
                          onKeyPress={handleKeypress}
                        />
                        <label
                          className="form-check-label p-0 ml-1"
                          htmlFor="Bills"
                        >
                          Big vet bills
                        </label>
                      </div>
                      <div className="form-check  col-xl-3">
                        <input
                          type="checkbox"
                          className="form-check-input"
                          id="Partner_Allergies"
                          onChange={handleCheckedBox}
                          onKeyPress={handleKeypress}
                        />
                        <label
                          className="form-check-label p-0 ml-1"
                          htmlFor="Partner_Allergies"
                        >
                          Marry someone with allergies
                        </label>
                      </div>
                      <div className="form-check col-xl-3">
                        <input
                          type="checkbox"
                          className="form-check-input"
                          id="Baby"
                          onChange={handleCheckedBox}
                          onKeyPress={handleKeypress}
                        />
                        <label
                          className="form-check-label p-0 ml-1"
                          htmlFor="Baby"
                        >
                          Having a baby
                        </label>
                      </div>
                      <div className="form-check   col-xl-3">
                        <input
                          type="checkbox"
                          className="form-check-input"
                          id="Un_Trainable"
                          onChange={handleCheckedBox}
                          onKeyPress={handleKeypress}
                        />
                        <label
                          className="form-check-label p-0 ml-1"
                          htmlFor="Un_Trainable"
                        >
                          Un-Trainable
                        </label>
                      </div>
                      <div className="form-check  col-xl-3">
                        <input
                          type="checkbox"
                          className="form-check-input"
                          id="Pet_Incompatible"
                          onChange={handleCheckedBox}
                          onKeyPress={handleKeypress}
                        />
                        <label
                          className="form-check-label p-0 ml-1"
                          htmlFor="Pet_Incompatible"
                        >
                          Doesn't get along with other pet
                        </label>
                      </div>
                      <div className="form-check col-xl-3">
                        <input
                          type="checkbox"
                          className="form-check-input"
                          id="Bladder_Issues"
                          onChange={handleCheckedBox}
                          onKeyPress={handleKeypress}
                        />
                        <label
                          className="form-check-label p-0 ml-1"
                          htmlFor="Bladder_Issues"
                        >
                          Loses control of bladder
                        </label>
                      </div>
                      <div className="form-check   col-xl-3">
                        <input
                          type="checkbox"
                          className="form-check-input"
                          id="Neighbors"
                          onChange={handleCheckedBox}
                          onKeyPress={handleKeypress}
                        />
                        <label
                          className="form-check-label p-0 ml-1"
                          htmlFor="Neighbors"
                        >
                          Neighbors complain
                        </label>
                      </div>
                      <div className="form-check  col-xl-3">
                        <input
                          type="checkbox"
                          className="form-check-input"
                          id="Pet_Not_As_Expected"
                          onChange={handleCheckedBox}
                          onKeyPress={handleKeypress}
                        />
                        <label
                          className="form-check-label p-0 ml-1"
                          htmlFor="Pet_Not_As_Expected"
                        >
                          Pet is not the kind of pet I thought it would be
                        </label>
                      </div>
                      <div className="form-check   col-xl-3">
                        <input
                          type="checkbox"
                          className="form-check-input"
                          id="Chronic_Illness"
                          onChange={handleCheckedBox}
                          onKeyPress={handleKeypress}
                        />
                        <label
                          className="form-check-label p-0 ml-1"
                          htmlFor="Chronic_Illness"
                        >
                          Pet develops chronic illness
                        </label>
                      </div>

                      <div className="form-check   col-xl-3">
                        <input
                          type="checkbox"
                          className="form-check-input"
                          id="Nothing_Applies"
                          onChange={handleCheckedBox}
                          onKeyPress={handleKeypress}
                        />
                        <label
                          className="form-check-label p-0 ml-1"
                          htmlFor="Nothing_Applies"
                        >
                          Nothing here applies
                        </label>
                      </div>
                      <div className="form-check   col-xl-6">
                        <input
                          type="checkbox"
                          className="form-check-input"
                          id="Other_Checkbox"
                          onChange={handleCheckedBox}
                          onKeyPress={handleKeypress}
                        />
                        <label>Other:</label>
                        <textarea
                          id="Other_Reasons"
                          rows="3"
                          type="text"
                          className="form-control col-xl-12"
                          onChange={onChange}
                          onKeyPress={handleKeypress}
                          maxLength="2000"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="form-group col-11 col-sm-11 col-md-11 col-lg-11 col-xl-11 pr-0">
                      <label htmlFor="If_Die">
                        In the event that you become very ill or die, what plans
                        do you have for your dog?
                      </label>
                      <input
                        id="If_Die"
                        type="text"
                        className="form-control"
                        onChange={onChange}
                        onKeyPress={handleKeypress}
                        maxLength="250"
                      />
                    </div>
                  </div>
                </div>

                <div className="container my-1 border border-dark">
                  <div className="row bg-dark">
                    <h6 className="text-center text-light align-middle my-1 col-lg-12">
                      ADDITIONAL INFORMATION
                    </h6>
                  </div>
                  <div className="form-group mt-4">
                    <label className="active" htmlFor="Description">
                      <h6>
                        Any other information you feel we should know in
                        considering your application:
                      </h6>
                    </label>
                    <textarea
                      id="Description"
                      type="text"
                      className="form-control"
                      rows="4"
                      onChange={onChange}
                      onKeyPress={handleKeypress}
                    />
                  </div>
                  <div className="row">
                    <div className="form-group col-11 col-sm-11 col-md-11 col-lg-11 col-xl-11 pr-0">
                      <label htmlFor="Adoption_Aware_Fee">
                        Are you aware that there is an adoption fee for the
                        dog/puppy you are considering?
                      </label>
                      <select
                        id="Adoption_Aware_Fee"
                        className="form-control"
                        onChange={onChange}
                        onKeyPress={handleKeypress}
                      >
                        <option value="not_answered">Select</option>sh
                        <option>YES</option>
                        <option>NO</option>
                      </select>
                    </div>
                  </div>
                </div>
                <div className="container-fluid my-1 border border-dark">
                  <div className="row bg-dark">
                    <h6 className="text-center text-light align-middle my-1 col-lg-12">
                      ACKNOWLEDGEMENT
                    </h6>
                  </div>
                  <p className="mt-3">
                    By submitting this application, I acknowledge that I have
                    answered all questions truthfully. Failure to provide
                    truthful answers can result in the rejection of my
                    application or the forfeiture of this adopted pet to Puppy
                    Paws Rescue. If I have not received a telephone call within
                    two weeks from the date of this application from a Puppy
                    Paws Rescue representative, I understand that my application
                    has been declined or the dog that I have requested has been
                    placed with another applicant.
                  </p>
                </div>
                {/* ************************************* SUBMIT BUTTON ************************** */}
                <div className="p-3">
                  <button
                    type="submit"
                    value="submit"
                    onClick={submitForm}
                    onKeyPress={handleKeypress}
                    className="btn btn-success btn-lg btn-block my-2"
                  >
                    Submit
                  </button>
                </div>
              </form>
            </div>
          </div>
        </Fragment>
      );
    case 3:
      return <Home />;
  }
};

export default FormAdoptSinglePage;
