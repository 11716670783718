const sendOfflineApplicationRequest = (data) => {
  const htmlMessage = `
    <br>
    <br>
  FROM: <br>
    First Name: ${data.First_Name} <br>
    Last_Name: ${data.Last_Name}<br>
    Address: ${data.Street}<br>
    City: ${data.City}<br>
    State: ${data.State}<br>
    Zip_Code: ${data.Zip_Code}<br>
    Phone: ${data.Phone}<br>
    Email: ${data.Email}<br>
    <br>
    <br>
    DEVICE INFORMATION: <br>
    OS_Type: ${data.OS_Type}<br>
    Browser: ${data.Browser}<br>
    Device_Type: ${data.Device_Type}<br>
    Device_Browser: ${data.Device_Browser}<br>
    Direct_Link: ${data.Direct_Link}<br>

    <br>
    <br>
  ISSUE DETAILS: <br>
    ${data.Details}
    
    `;
  return {
    subject: 'An Offline Adoption Application has Requested',
    html: htmlMessage,
    text: 'A WEBSITE CONTACT EMAIL HAS BEEN SENT',
  };
};

export default sendOfflineApplicationRequest;
