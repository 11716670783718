import React from 'react';
import '../../css/Chewy.css';

const Chewy = () => {
  return (
    <div className="chewyContainer">
      <h1>DONATE USING CHEWY</h1>
      <p>
        It’s never been easier to give back! Donating to Puppy Paws Rescue by
        purchasing items from our Chewy Wish List.
      </p>
      <a
        href="https://www.chewy.com/g/puppy-paws-rescue_b71615192"
        target="_blank"
        rel="noreferrer"
      >
        <button className=" bg-warning">WISH LIST</button>
      </a>
    </div>
  );
};

export default Chewy;
