import React, { Fragment, useEffect, useState } from 'react';

export const FormAdoptApplicant = (values) => {
  const { application, onChange, cont, prev, showAlert } = values;
  const {
    First_Name,
    Middle_Initial,
    Last_Name,
    CoApp_Full_Name,
    Street,
    City,
    State,
    Zip_Code,
    County,
    Phone,
    Mobile,
    Work,
    Email,
    Of_Age,
    Past_Addresses,
    Employer1,
    Employer2,
    Hours1,
    Hours2,
    Number_Household,
    Age_of_Children,
    Other_Adult_Names,
    Adults_Aware,
    Primary_Caregiver,
  } = application;

  // FORM VALIDATIONS
  const [validation, setValidation] = useState({
    First_Name_Invalid: '',
    Middle_Initial_Invalid: '',
    Last_Name_Invalid: '',
    Email_Invalid: '',
  });

  const {
    First_Name_Invalid,
    Middle_Initial_Invalid,
    Last_Name_Invalid,
    Email_Invalid,
  } = validation;

  const checkValidations = () => {
    let fname = '';
    let mname = '';
    let lname = '';
    let email = '';

    if (First_Name === '') {
      fname = 'Enter First Name';
    }

    if (Middle_Initial === '') {
      mname = `Enter Middle Name`;
    }
    if (Last_Name === '') {
      lname = `Enter Last Name`;
    }
    if (Email === '') {
      email = `Enter Valid Email`;
    }

    setValidation({
      ...application,
      First_Name_Invalid: fname,
      Middle_Initial_Invalid: mname,
      Last_Name_Invalid: lname,
      Email_Invalid: email,
    });
  };

  const on_change = (e) => {
    onChange(e);
  };

  const next_page = (e) => {
    e.preventDefault();
    if (Middle_Initial === '' || Last_Name === '' || Email === '') {
      showAlert('MISSING DATA - PLEASE FIX ITEMS IN RED BELOW', 'danger');
      checkValidations();
      window.scrollTo(0, 0);
    } else {
      cont();
      window.scrollTo(0, 0);
    }
  };
  const prev_page = (e) => {
    e.preventDefault();
    prev();
  };

  window.history.pushState({}, 'Adopt', '/adopt');

  useEffect(() => {
    window.onpopstate = function (ev) {
      prev();
    };
  }, [prev]);

  return (
    <Fragment>
      <form>
        <div>
          <h4 className="text-center mt-2">ADOPTION APPLICATION</h4>
        </div>
        <div className="container-fluid my-1 border border-dark">
          <div className="row bg-dark">
            <h6 className="text-center text-light align-middle my-1 col-lg-12">
              PERSONAL DATA
            </h6>
          </div>
          <div className="row">
            <div className="col-sm-7 col-lg-8"></div>
            <div className="col-sm-5 col-lg-3">
              <p className="text-danger">* Indicates Manditory</p>
            </div>
          </div>
          <div className="row">
            <div className="form-group col-sm-12 col-md-4 col-lg-4">
              <label htmlFor="First_Name">
                First Name <span className="text-danger"> *</span>
              </label>
              <input
                id="First_Name"
                type="text"
                className="form-control"
                value={First_Name}
                onChange={on_change}
                maxLength="100"
              />
              <div className="text-danger font-weight-bold">
                {First_Name_Invalid}
              </div>
            </div>
            <div className="form-group col-sm-12 col-md-4 col-lg-3">
              <label htmlFor="Middle_Initial">
                Middle Name <span className="text-danger"> *</span>{' '}
              </label>
              <input
                id="Middle_Initial"
                type="text"
                className="form-control "
                value={Middle_Initial}
                onChange={on_change}
                required
                maxLength="100"
              />
              <div className="text-danger font-weight-bold">
                {Middle_Initial_Invalid}
              </div>
            </div>
            <div className="form-group col-sm-12 col-md-4 col-lg-4">
              <label htmlFor="Last_Name">
                Last Name <span className="text-danger"> *</span>
              </label>
              <input
                id="Last_Name"
                type="text"
                className="form-control"
                value={Last_Name}
                onChange={on_change}
                required
                maxLength="100"
              />
              <div className="text-danger font-weight-bold">
                {Last_Name_Invalid}
              </div>
            </div>
          </div>
          <div className="row">
            <div className="form-group col-sm-12 col-md-6 col-lg-3">
              <label htmlFor="Phone">Home Phone</label>
              <input
                id="Phone"
                type="text"
                className="form-control"
                value={Phone}
                onChange={on_change}
                maxLength="20"
              />
            </div>
            <div className="form-group col-sm-12 col-md-6 col-lg-3">
              <label htmlFor="Work">Work Phone</label>
              <input
                id="Work"
                type="text"
                className="form-control"
                value={Work}
                onChange={on_change}
                maxLength="20"
              />
            </div>
            <div className="form-group col-sm-12 col-md-6 col-lg-3">
              <label htmlFor="Mobile">Mobile Phone</label>
              <input
                id="Mobile"
                type="text"
                className="form-control"
                value={Mobile}
                onChange={on_change}
                maxLength="20"
              />
            </div>
            <div className="form-group col-sm-12 col-md-6 col-lg-3">
              <label htmlFor="Email">
                Email Address <span className="text-danger"> *</span>
              </label>
              <input
                id="Email"
                type="email"
                className="form-control"
                value={Email}
                onChange={on_change}
                maxLength="100"
              />
              <div className="text-danger font-weight-bold">
                {Email_Invalid}
              </div>
            </div>
          </div>
          <div className="row">
            <div className="form-group col-sm-12 col-md-6 col-lg-6">
              <label htmlFor="CoApp_Full_Name">
                Co-Applicant Full Name (including middle name)
              </label>
              <input
                id="CoApp_Full_Name"
                type="text"
                className="form-control"
                value={CoApp_Full_Name}
                onChange={on_change}
                maxLength="100"
              />
            </div>
          </div>
          <div className="row">
            <div className="form-group col-sm-12 col-md-12 col-lg-8">
              <label htmlFor="Street">Street Address</label>
              <input
                id="Street"
                type="text"
                className="form-control"
                value={Street}
                onChange={on_change}
                maxLength="250"
              />
            </div>
          </div>
          <div className="row">
            <div className="form-group col-sm-12 col-md-7 col-lg-6">
              <label htmlFor="City">City</label>
              <input
                id="City"
                type="text"
                className="form-control"
                value={City}
                onChange={on_change}
                maxLength="100"
              />
            </div>
            <div className="form-group col-sm-12 col-md-2 col-lg-2">
              <label htmlFor="State">State</label>
              <input
                id="State"
                type="text"
                className="form-control"
                value={State}
                onChange={on_change}
                maxLength="2"
              />
            </div>
            <div className="form-group col-sm-12 col-md-3 col-lg-4">
              <label htmlFor="Zip_Code">Zip Code</label>
              <input
                id="Zip_Code"
                type="text"
                className="form-control"
                value={Zip_Code}
                onChange={on_change}
                maxLength="7"
              />
            </div>
          </div>
          <div className="row">
            <div className="form-group col-sm-12 col-md-7 col-lg-6">
              <label htmlFor="County">County</label>
              <input
                id="County"
                type="text"
                className="form-control"
                value={County}
                onChange={on_change}
                maxLength="100"
              />
            </div>
          </div>

          <div className="row">
            <div className="form-group col-sm-12 col-md-6 col-lg-6">
              <label htmlFor="Past_Addresses">
                All Addresses that you have lived at in the past 5 years
              </label>
              <textarea
                id="Past_Addresses"
                type="text"
                rows="4"
                className="form-control"
                value={Past_Addresses}
                onChange={on_change}
                maxLength="250"
              />
            </div>
          </div>
          <div className="form-group col-sm-12 col-md-6 col-lg-6">
            <div className="row">
              <br />
              <br />
            </div>
          </div>
          <div className="form-group col-sm-12 col-md-6 col-lg-6">
            <div className="row">
              <label className="col-sm-12 col-md-8 col-lg-5" htmlFor="Of_Age">
                <span className="align-middle">Are you 18yr old or Older?</span>
              </label>
              <select
                className="form-control col-sm-12 col-md-3 col-lg-3"
                id="Of_Age"
                value={Of_Age}
                onChange={on_change}
              >
                <option value="not_answered">Select</option>
                <option>Yes</option>
                <option>No</option>
              </select>
            </div>
          </div>
          <div className="form-group col-sm-12 col-md-6 col-lg-6">
            <div className="row mt-2">
              <label
                className="col-sm-12 col-md-12 col-lg-7"
                htmlFor="Number_Household"
              >
                <span className="align-middle">
                  Number of persons living in household:
                </span>
              </label>
              <input
                id="Number_Household"
                type="text"
                className="form-control col-sm-12 col-md-12 col-lg-2"
                value={Number_Household}
                onChange={on_change}
                maxLength="2"
              />
            </div>
          </div>

          <div className="row">
            <div className="form-group col-sm-12 col-md-6 col-lg-6">
              <label htmlFor="Age_of_Children">
                Ages of children living or frequently visiting house:
              </label>
              <input
                id="Age_of_Children"
                type="text"
                className="form-control"
                value={Age_of_Children}
                onChange={on_change}
                maxLength="15"
              />
            </div>

            <div className="form-group col-sm-12 col-md-12 col-lg-12">
              <label
                className="col-sm-12 col-md-12 col-lg-5"
                htmlFor="Other_Adult_Names"
              >
                Names of other adults living in the household
              </label>
              <input
                id="Other_Adult_Names"
                type="text"
                className="form-control"
                value={Other_Adult_Names}
                onChange={on_change}
                maxLength="100"
              />
            </div>
          </div>
          <div className="form-group col-sm-12 col-md-12 col-lg-12">
            <div className="row">
              <label
                className="col-sm-12 col-md-12 col-lg-7"
                htmlFor="Adults_Aware"
              >
                <span className="align-middle">
                  Are all adults in your household aware that you are
                  considering adopting a pet?
                </span>
              </label>
              <select
                id="Adults_Aware"
                type="text"
                className="form-control col-sm-12 col-md-10 col-lg-2"
                value={Adults_Aware}
                onChange={on_change}
              >
                <option value="not_answered">Select</option>
                <option value="Yes">Yes</option>
                <option value="no">No</option>
              </select>
            </div>
          </div>
          <div className="form-group col-sm-12 col-md-12 col-lg-12">
            <div className="row">
              <label
                className="col-sm-12 col-md-6 col-lg-5"
                htmlFor="Primary_Caregiver"
              >
                Who will be the primary caregiver for your new pet?
              </label>
              <input
                id="Primary_Caregiver"
                type="text"
                className="form-control col-sm-12 col-md-6 col-lg-6"
                value={Primary_Caregiver}
                onChange={on_change}
                maxLength="50"
              />
            </div>
          </div>

          <div className="row bg-dark">
            <h6 className="text-center text-light align-middle my-1 col-lg-12">
              EMPLOYMENT INFORMATION
            </h6>
          </div>
          <br />

          <div className="row"></div>
          <div className="row">
            <div className="form-group col-sm-12 col-md-12">
              <div className="row">
                <div className="form-group col-sm-12 col-md-6">
                  <label className="col-sm-12 col-md-12" htmlFor="Employer1">
                    Applicant's Employer
                  </label>
                  <input
                    id="Employer1"
                    type="text"
                    className="form-control col-sm-12 col-md-12"
                    value={Employer1}
                    onChange={on_change}
                    maxLength="100"
                  />
                </div>
                <div className="form-group col-sm-12 col-md-6">
                  <label className="col-md-12" htmlFor="Hours1">
                    Working Hours
                  </label>
                  <input
                    id="Hours1"
                    type="text"
                    className="form-control col-md-4"
                    value={Hours1}
                    onChange={on_change}
                    maxLength="20"
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="form-group col-sm-12 col-md-12">
              <div className="row">
                <div className="form-group col-sm-12 col-md-6">
                  <label className="col-sm-12 col-md-12" htmlFor="Employer2">
                    Co-Applicant's Employer
                  </label>
                  <input
                    id="Employer2"
                    type="text"
                    className="form-control"
                    value={Employer2}
                    onChange={on_change}
                    maxLength="100"
                  />
                </div>

                <div className="form-group col-sm-12 col-md-6">
                  <label className="col-md-12" htmlFor="Hours2">
                    Working Hours
                  </label>
                  <input
                    id="Hours2"
                    type="text"
                    className="form-control col-md-4"
                    value={Hours2}
                    onChange={on_change}
                    maxLength="20"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* ****************BUTTONS ARE BELOW HERE*********** */}
        <div className="container">
          <div className="row">
            <button
              type="button"
              className="btn btn-danger col-lg-2 mt-3 mb-3"
              onClick={prev_page}
            >
              <i className="fa fa-arrow-left p" aria-hidden="true" />
              Previous
            </button>
            <div className="col-lg-8"></div>
            <button
              type="button"
              className="btn btn-dark col-lg-2 mt-3 mb-3"
              onClick={next_page}
            >
              Continue
              <i className="fa fa-arrow-right p" aria-hidden="true" />
            </button>
          </div>
        </div>
      </form>
    </Fragment>
  );
};

export default FormAdoptApplicant;
