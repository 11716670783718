import React, { Fragment } from 'react';
// import Carousel from '../layout/Carousel';
import Hero from '../layout/Hero';
import FosterBanner from '../layout/FosterBanner';
import Chewy from '../layout/Chewy';
import MiddleSection from '../layout/MiddleSection';
import InternetExployer from './InternetExployer';
import axios from 'axios';

const Home = () => {
  // CHECK FOR AUTH TOKEN & GET ONE IF NOT
  const currentDate = new Date();
  const difference = currentDate - localStorage.getItem('token_age');
  // Internet Explorer 6-11
  var isIE = /*@cc_on!@*/ false || !!document.documentMode;

  if (isIE) {
    return (
      <Fragment>
        <div>
          <InternetExployer />
        </div>
      </Fragment>
    );
  } else {
    if (difference > 3600000) {
      // GetToken();
      axios
        .get('/api/getToken')
        .then(function (res) {
          localStorage.setItem('token', res.data.data);
          const date = new Date();
          localStorage.setItem('token_age', date.getTime());
        })
        .catch(function (error) {
          console.log(error);
        });
    }
    return (
      <Fragment>
        <div className="home_container">
          {/* <Carousel /> */}
          <Hero />
          <MiddleSection />
          <FosterBanner />
          <Chewy />
        </div>
      </Fragment>
    );
  }
};
export default Home;
