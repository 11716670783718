import React, { useState, useEffect, useContext, useRef } from 'react';
import axios from 'axios';
import moment from 'moment';
import AlertContext from '../../context/alert/alertContext';
import ReCAPTCHA from 'react-google-recaptcha';

const config = {
  headers: {
    'Content-Type': 'application/json',
  },
};

const ContractSubmission = () => {
  const alertContext = useContext(AlertContext);
  const { setAlert } = alertContext;
  const [loadedPage, setLoadedPage] = useState('submission');
  const [isVerified, setIsVerified] = useState(false);
  const [adoptionInformation, setAdoptionInformation] = useState({
    Adoption_AppId: null,
    Dog_Adopted: null,
    Adoption_Date: null,
    Fixed: null,
    Submitter: null,
  });
  const [valid, setValid] = useState({
    id: false,
    dog: false,
    date: false,
    Submitter: false,
  });

  const [validationMessage, setValidationMessage] = useState({
    id: '',
    dog: '',
    date: '',
    Submitter: '',
  });
  const fileInputRef = useRef();
  const [validFile, setValidFile] = useState([]);
  const [errorMessage, setErrorMessage] = useState('');

  useEffect(() => {
    if (adoptionInformation.Dog_Adopted !== null) {
      if (adoptionInformation.Dog_Adopted.length === 0) {
        setValidationMessage({
          ...validationMessage,
          dog: '- MANDITORY -  Must Enter Dog Name',
        });

        setValid({ ...valid, dog: false });
      } else {
        setValidationMessage({
          ...validationMessage,
          dog: '',
        });
        setValid({ ...valid, dog: true });
      }
    }
    // eslint-disable-next-line
  }, [adoptionInformation.Dog_Adopted]);

  useEffect(() => {
    if (adoptionInformation.Submitter !== null) {
      if (adoptionInformation.Submitter.length === 0) {
        setValidationMessage({
          ...validationMessage,
          Submitter: '- MANDITORY -  Must Enter Your Name',
        });

        setValid({ ...valid, Submitter: false });
      } else {
        setValidationMessage({
          ...validationMessage,
          Submitter: '',
        });
        setValid({ ...valid, Submitter: true });
      }
    }
    // eslint-disable-next-line
  }, [adoptionInformation.Submitter]);

  useEffect(() => {
    if (adoptionInformation.Adoption_Date !== null) {
      if (adoptionInformation.Adoption_Date.length === 0) {
        setValidationMessage({
          ...validationMessage,
          date: '- Must Enter An Adoption Date',
        });

        setValid({ ...valid, date: false });
      } else {
        setValidationMessage({
          ...validationMessage,
          date: '',
        });
        setValid({ ...valid, date: true });
      }
    }
    // eslint-disable-next-line
  }, [adoptionInformation.Adoption_Date]);

  const handleInput = (e) => {
    setAdoptionInformation({
      ...adoptionInformation,
      [e.target.id]: e.target.value,
    });
  };

  const handleInputofId = (e) => {
    if (!isNaN(e.target.value)) {
      setValidationMessage({ ...validationMessage, id: '' });
      setAdoptionInformation({
        ...adoptionInformation,
        Adoption_AppId: parseInt(e.target.value),
      });
      setValid({ ...valid, id: true });
    } else {
      setValidationMessage({
        ...validationMessage,
        id: '- Must Be a Number Only',
      });
      setValid({ ...valid, id: false });
    }
  };

  // Google Captcha Implementation
  const captchaID = '6Lf6EMMUAAAAACdn1-MLLtPckPVVFfyMLTnP6tF2';

  const checkCaptcha = async (data) => {
    const resBody = {
      response: data,
    };
    try {
      const res = await axios.post('/api/captcha', resBody, config);
      setIsVerified(res.data);
    } catch (err) {
      console.log(err);
    }
  };

  const isNotRobot = (response) => {
    checkCaptcha(response);
  };

  const newSubmission = () => {
    setAdoptionInformation({
      Adoption_AppId: null,
      Dog_Adopted: null,
      Adoption_Date: null,
      Fixed: null,
      Submitter: null,
    });
    setValid({ id: false, dog: false, date: false, Submitter: false });
    setValidationMessage({
      id: '',
      dog: '',
      date: '',
      Submitter: '',
    });
    setLoadedPage('submission');
    window.scroll(0, 0);
  };

  const resetForm = () => {
    window.scroll(0, 0);
    setAdoptionInformation({
      Adoption_AppId: null,
      Dog_Adopted: null,
      Adoption_Date: null,
      Fixed: null,
      Submitter: '',
    });
    setValid({ id: false, dog: false, date: false });
    setValidationMessage({
      id: '',
      dog: '',
      date: '',
      Submitter: '',
    });
    document.getElementById('Adoption_AppId').value = '';
    document.getElementById('Dog_Adopted').value = '';
    document.getElementById('Fixed').value = '';
    document.getElementById('MicroChip_Brand').value = '';
    document.getElementById('MicroChip').value = '';
    fileInputRef.current.value = '';
    setValidFile([]);
  };

  const validateSubmission = () => {
    var messages = {};

    if (adoptionInformation.Dog_Adopted !== null) {
      if (!adoptionInformation.Dog_Adopted.length > 0) {
        messages.dog = '- Must Enter Dog Name';
        setValid({ ...valid, dog: false });
      }
    } else {
      messages.dog = '- MANDITORY -  Must Enter Dog Name';
      setValid({ ...valid, dog: false });
    }
    if (adoptionInformation.Submitter !== null) {
      if (!adoptionInformation.Submitter.length > 0) {
        messages.Submitter = '- Must Enter Your Name';
        setValid({ ...valid, Submitter: false });
      }
    } else {
      messages.Submitter = '- MANDITORY -  Must Enter Your Name';
      setValid({ ...valid, Submitter: false });
    }
    if (adoptionInformation.Adoption_Date !== null) {
      if (!adoptionInformation.Adoption_Date.length > 0) {
        messages.date = '- Must Enter An Adoption Date';
        setValid({ ...valid, date: false });
      }
    } else {
      messages.date = '- Must Enter An Adoption Date';
      setValid({ ...valid, date: false });
    }
    if (adoptionInformation.Adoption_AppId !== null) {
      if (isNaN(adoptionInformation.Adoption_AppId) === true) {
        messages.id = "- Must enter Adopter's Application ID";
        setValid({ ...valid, id: false });
      }
    } else {
      messages.id = "- Must enter Adopter's Application ID";
      setValid({ ...valid, id: false });
    }

    setValidationMessage(messages);
  };

  // ! ************************* HANDLE FILE TO BE UPLOADED ******************************

  const filesSelected = () => {
    if (fileInputRef.current.files.length) {
      handleFiles(fileInputRef.current.files);
    }
  };

  const handleFiles = (file) => {
    if (validateFile(file[0])) {
      setValidFile(file[0]);
      setErrorMessage('');
    } else {
      fileInputRef.current.value = '';
      setErrorMessage('- File type not permitted');
    }
  };

  const validateFile = (file) => {
    const validTypes = [
      'image/jpeg',
      'image/jpg',
      'image/png',
      'image/gif',
      'image/x-icon',
      'application/doc',
      'application/docx',
      'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
      'application/pdf',
      'application/msword',
    ];
    if (validTypes.indexOf(file.type) === -1) {
      return false;
    }

    return true;
  };

  const removeFile = () => {
    fileInputRef.current.value = '';
    setErrorMessage('');
    setValidFile([]);
  };
  // ! *******************************************************************************

  // * *************************** ADOPTION CONTRACT SUBMISSION *************************
  const SubmitContract = async () => {
    window.scroll(0, 0);
    validateSubmission();
    if (isVerified) {
      if (valid.dog && valid.id && valid.date && valid.Submitter) {
        setAlert('Processing......', 'warning');
        await axios
          .post('/api/adoptions', adoptionInformation, config)
          .then(async (response) => {
            if (adoptionInformation.Fixed !== null) {
              if (adoptionInformation.Fixed === 'NO') {
                const followUpData = {
                  Follow_Up_Description: 'Spay/Neuter F/U',
                  Follow_Up_Owner: 1045,
                  Follow_Up_Due_Date: moment().add(120, 'days').toDate(),
                  Follow_Up_App_Id: adoptionInformation.Adoption_AppId,
                };
                await axios
                  .post('/api/adoptions/createfollowup', followUpData, config)
                  .catch((error) => {
                    setAlert('FOLLOW UP CREATION ERROR', 'danger');
                  });
              }
            }
            if (validFile.length !== 0) {
              const date = Date.now();
              const fName = validFile.name.split('.')[0];
              const ext = validFile.name.split('.')[1];
              const newFileName = `${fName}-${date}.${ext}`;
              const email = {
                app_id: adoptionInformation.Adoption_AppId,
                filename: newFileName,
                adoption_date: adoptionInformation.Adoption_Date,
                dogAdopted: adoptionInformation.Dog_Adopted,
                Submitter: adoptionInformation.Submitter,
              };
              const formData = new FormData();
              formData.append('file', validFile, newFileName);
              const dbData = {
                File_Name: newFileName,
                File_Description: 'PPR Adoption Contract',
                File_AppID: adoptionInformation.Adoption_AppId,
                File_Size: validFile.size,
                Uploaded_By: adoptionInformation.Submitter,
              };
              await axios.post('/api/adoptions/saveDB', dbData, config);
              await axios.post('/api/adoptions/saveAgreement', formData, {
                headers: {
                  'Content-Type': 'multipart/form-data',
                },
              });

              axios
                .post('/api/adoptions/sendSubmissionNotice', email, config)
                .then((response) => {
                  setLoadedPage('submitted');
                  window.scroll(0, 0);
                  setAlert('Adoption Contract Submitted', 'success');
                  setValidFile([]);
                })
                .catch((error) => {
                  setAlert('SERVER ERROR - AGREEMENT SAVE ERROR', 'danger');
                  console.log(error);
                });
            } else {
              const email = {
                app_id: adoptionInformation.Adoption_AppId,
                adoption_date: adoptionInformation.Adoption_Date,
                dogAdopted: adoptionInformation.Dog_Adopted,
                Submitter: adoptionInformation.Submitter,
              };

              axios
                .post(
                  '/api/adoptions/sendSubmissionNoticeNoAttach',
                  email,
                  config
                )
                .then((response) => {
                  setLoadedPage('submitted');
                  window.scroll(0, 0);
                  setAlert('Adoption Contract Submitted', 'success');
                });
            }
          })

          .catch((error) => {
            console.log(error);
            setAlert('SERVER ERROR 2', 'danger');
          });
      }
    } else {
      setAlert('Prove you are not a robot');
    }
  };
  // * *********************************************************************************

  if (loadedPage === 'submission') {
    return (
      <div className="container">
        <h4 className="text-center mt-2">ADOPTION CONTRACT SUBMISSION</h4>
        <div className="row">
          <div className="col-1 col-lg-2"></div>
          <div className="card col-10 col-lg-8 rounded-lg shadow my-4">
            <div className="card-body">
              <form>
                <div className="form-group">
                  <div className="row">
                    <div className="col-lg-1"></div>
                    <label
                      htmlFor="Submitter"
                      className="col-12 font-weight-bold"
                    >
                      Enter Your Name{' '}
                      <span className="text-danger font-weight-bold">
                        {validationMessage.Submitter}
                      </span>
                    </label>
                    <div className="col-lg-1"></div>
                  </div>
                  <div className="row">
                    <input
                      type="text"
                      id="Submitter"
                      onChange={handleInput}
                      className="form-control col-12 bg-light shadow"
                    />
                  </div>
                </div>
                <div className="form-group">
                  <div className="row">
                    <div className=" col-lg-1"></div>
                    <label
                      htmlFor="Adoption_AppId"
                      className="col-12 font-weight-bold"
                    >
                      Adopter's Application Id:{' '}
                      <span className="text-danger font-weight-bold">
                        {validationMessage.id}
                      </span>
                      <div className="row">
                        <small className=" ml-4">
                          (Found on page 2 of adoption contract)
                        </small>
                      </div>
                    </label>
                    <div className=" col-lg-1"></div>
                  </div>
                  <div className="row">
                    <div className="col-lg-2"></div>
                    <input
                      type="text"
                      id="Adoption_AppId"
                      onChange={handleInputofId}
                      className="form-control col-12 bg-light shadow"
                    />
                    <div className=" col-lg-2"></div>
                  </div>
                </div>

                <div className="form-group">
                  <div className="row">
                    <div className="col-lg-1"></div>
                    <label
                      htmlFor="Dog_Adopted"
                      className="col-12 font-weight-bold"
                    >
                      Adopted Dog Name{' '}
                      <span className="text-danger font-weight-bold">
                        {validationMessage.dog}
                      </span>
                    </label>
                    <div className="col-lg-1"></div>
                  </div>
                  <div className="row">
                    {/* <div className="col-lg-2"></div> */}
                    <input
                      type="text"
                      id="Dog_Adopted"
                      onChange={handleInput}
                      className="form-control col-12 bg-light shadow"
                    />
                  </div>
                </div>
                <div className="form-group">
                  <div className="row">
                    <div className="col-lg-1"></div>
                    <label
                      htmlFor="Fixed"
                      className="font-weight-bold col-lg-12"
                    >
                      Is Dog Altered?
                    </label>
                  </div>
                  <div className="row">
                    <div className="col-lg-11"></div>
                    <select
                      className="form-control col-12 bg-light shadow"
                      id="Fixed"
                      onChange={handleInput}
                    >
                      <option value="">Choose</option>
                      <option>YES</option>
                      <option>NO</option>
                    </select>

                    <div className="col-lg-1"></div>
                  </div>
                </div>
                <div className="form-group">
                  <div className="row">
                    <div className="col-lg-1"></div>
                    <label
                      htmlFor="MicroChip_Brand"
                      className="col-12 font-weight-bold"
                    >
                      MicroChip Brand
                    </label>
                    <div className="col-lg-1"></div>
                  </div>
                  <div className="row">
                    <div className="col-lg-2"></div>
                    <input
                      type="text"
                      id="MicroChip_Brand"
                      onChange={handleInput}
                      className="form-control col-12 bg-light shadow "
                    />
                    <div className="col-lg-12"></div>
                  </div>
                </div>
                <div className="form-group">
                  <div className="row">
                    <div className="col-lg-1"></div>
                    <label
                      htmlFor="MicroChip"
                      className="col-12 font-weight-bold"
                    >
                      MicroChip #
                    </label>
                    <div className="col-lg-1"></div>
                  </div>
                  <div className="row">
                    <div className="col-lg-1"></div>

                    <input
                      type="text"
                      id="MicroChip"
                      onChange={handleInput}
                      className="form-control col-12 bg-light shadow"
                    />
                  </div>
                </div>

                <div className="form-group">
                  <div className="row mt-3">
                    <label
                      htmlFor="Adoption_Date"
                      className="font-weight-bold col-lg-12"
                    >
                      Adoption Date{' '}
                      <span className="text-danger font-weight-bold">
                        {validationMessage.date}
                      </span>
                    </label>
                  </div>
                  <div className="row">
                    <input
                      type="date"
                      id="Adoption_Date"
                      onChange={handleInput}
                      className="form-control col-lg-12 bg-light shadow"
                    />
                    <div className="col-lg-1"></div>
                  </div>
                </div>
                <div className="form-group">
                  <div className="row mt-3">
                    <label
                      htmlFor="contractFile"
                      className="font-weight-bold ml-4"
                    >
                      Scanned Contract{' '}
                      <span className="text-danger font-weight-bold">
                        <small className="font-weight-bold">
                          {' '}
                          {errorMessage}{' '}
                        </small>
                      </span>
                    </label>
                  </div>
                  <div className="row">
                    <input
                      ref={fileInputRef}
                      type="file"
                      className="form-control-file mr-2 col-12 col-lg-7 p-0"
                      id="contractFile"
                      onChange={filesSelected}
                    />
                    {validFile.length !== 0 ? (
                      <button
                        className="btn btn-warning btn-sm mt-1 pointer col-12 col-lg-2"
                        onClick={removeFile}
                      >
                        Clear File
                      </button>
                    ) : null}
                  </div>
                </div>
              </form>
              <div className="row">
                {/* <div className="col-lg-2"></div> */}
                <div className="col-8 ml-1 mb-3">
                  <ReCAPTCHA sitekey={captchaID} onChange={isNotRobot} />
                </div>
              </div>
              <div className="row">
                <div className="col-4 col-lg-8"></div>
                <div className=" col-8 col-lg-4 text-right p-0">
                  <button className="btn btn-danger" onClick={resetForm}>
                    RESET
                  </button>
                  <button
                    className="btn btn-success ml-2"
                    onClick={SubmitContract}
                  >
                    SUBMIT
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className="col-1 col-lg-2"></div>
        </div>
      </div>
    );
  } else if (loadedPage === 'submitted') {
    return (
      <div className="container adoptionAgreementContainer">
        <h3 className="text-danger font-weight-bold text-center mt-4">
          Adoption Contract has been Submitted
        </h3>
        <h5 className="text-center">
          Click "Submit Another" button to add another adoption contract or
          close this browser page
        </h5>
        <div className="row mt-5">
          <div className="col-7 col-lg-8"></div>
          <div className="col-5 col-lg-4">
            <button className="btn btn-success" onClick={newSubmission}>
              {' '}
              Submit Another
            </button>
          </div>
        </div>
      </div>
    );
  }
};

export default ContractSubmission;
