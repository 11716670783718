import React, { Fragment, useContext, useEffect } from 'react';
import BlogContext from '../../context/blog/blogContext';
import BlogItem from '../layout/BlogItem';

const Blog = () => {
  const blogContext = useContext(BlogContext);
  const { blogs, getBlogs, loading } = blogContext;

  useEffect(() => {
    getBlogs();
    // eslint-disable-next-line
  }, []);
  return (
    <Fragment>
      <div className="container">
        {blogs !== null && !loading ? (
          blogs.map(blog => <BlogItem blog={blog} key={blog._id} />)
        ) : (
          <h4>Loading</h4>
        )}
      </div>
    </Fragment>
  );
};

export default Blog;
