import React, { Fragment } from 'react';
// import Bruiser from '../../media/Bruiser.jpg';
import Bruiser from '../../media/volunteer.jpg';

const About = () => {
  return (
    <Fragment>
      <div className="container">
        <div className="col-md-3"></div>
        <div className="card horizontal my-3 shadow">
          <div className="card-image">
            <img src={Bruiser} alt=""></img>
          </div>
          <div className="card-stacked">
            <div className="card-content p-2 m-2">
              <h3 className="header">ABOUT PUPPY PAWS RESCUE</h3>
              <p>
                Puppy Paws Rescue was created in March of 2013 in order to find
                loving homes for abandoned puppies and dogs. We are a non-profit
                (501c3) Also like us on facebook to get constant updates on
                available dogs, current adoptions and rescue news. Puppy Paws
                saves dogs from high kill shelters from the eastern portion of
                the United States. With the help of many foster families we
                typically have a large inventory of puppies and dogs available
                for adoption in the Maryland and northern Virginia area.
              </p>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default About;
