import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import ReactHtmlParser from 'react-html-parser';

function formatDate(string) {
  var options = {
    year: 'numeric',
    month: 'numeric',
    day: 'numeric'
  };
  return new Date(string).toLocaleDateString([], options);
}

const BlogItem = ({ blog }) => {
  const { _id, title, post, author, post_date } = blog;
  const formatedDate = formatDate(post_date);

  return (
    <div className="card bg-light m-4 shadow rounded" key={_id}>
      <div className="row">
        <div className="col-lg-1 bg-light text-light"></div>
        <div className="col-lg-2 bg-light text-dark text-center">
          <i className="fas fa-paw fa-5x"></i>
          <h6> by: {author}</h6>
          <p> {formatedDate}</p>
        </div>
        <div className="col-lg-7 bg-light">
          <Link to={`/blog/${_id}`} style={{ textDecoration: 'none' }}>
            <h3 className="text-dark">{title}</h3>
          </Link>
          <p className="blogPost blogSummary">{ReactHtmlParser(post)}</p>
          <div className="col-lg-8"></div>
          <div className="col-lg-4">
            <Link to={`/blog/${_id}`} style={{ textDecoration: 'none' }}>
              <button className="btn btn-dark text-right mb-2">
                READ MORE
              </button>
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

BlogItem.propTypes = {
  blog: PropTypes.object.isRequired
};
export default BlogItem;
