import React, { useReducer } from 'react';
import TaskContext from './taskContext';
import taskReducer from './taskReducer';
import axios from 'axios';

import { CREATE_TASK, EDIT_TASK, GET_TASKS } from '../types';

const config = {
  headers: {
    'Content-Type': 'application/json',
  },
};

const TaskState = (props) => {
  const initialstate = {
    tasks: [],
    taskLoading: true,
  };

  const workflowTasks = [
    'Property Search',
    'Call Landlord',
    'Case Search',
    'Facebook Search',
    'Interview',
    'Home Visit',
    'Veterinarian Check'
  ];

  const [state, dispatch] = useReducer(taskReducer, initialstate);

  const createTask = async (e) => {
    workflowTasks.map((task) => {
      const taskData = {
        Task_Description: task,
        App_Id: e,
        Complete_Checkbox: '',
      };
      axios.post('/api/tasks', taskData, config);
      return null;
    });
    const res = await axios.get(`/api/tasks/app/${e}`);
    dispatch({
      type: CREATE_TASK,
      payload: res.data,
    });
  };
  const editTask = async (e) => {
    const data = {
      Completed_By: e.Completed_By,
      Completed_Date: e.Completed_Date,
      Task_Status: e.Task_Status,
      Complete_Checkbox: e.Complete_Checkbox,
    };
    try {
      await axios.put(`/api/tasks/${e._id}`, data, config);
      dispatch({
        type: EDIT_TASK,
        payload: e,
      });
    } catch (error) {
      console.log(error);
    }
  };
  const deleteTask = (e) => {};
  const getTasks = async (e) => {
    try {
      const res = await axios.get(`/api/tasks/app/${e}`);
      dispatch({
        type: GET_TASKS,
        payload: res.data,
      });
    } catch (err) {
      console.log(err);
    }
  };
  const getTask = (e) => {
    console.log(e);
  };

  return (
    <TaskContext.Provider
      value={{
        tasks: state.tasks,
        taskLoading: state.taskLoading,
        createTask,
        editTask,
        deleteTask,
        getTasks,
        getTask,
      }}
    >
      {props.children}
    </TaskContext.Provider>
  );
};

export default TaskState;
