const sendContactEmail = data => {
  const htmlMessage = `
  <br>
  <br>
FROM: <br>
  Name: ${data.name} <br>
  Phone: ${data.phone}<br>
  Email: ${data.email}<br>

  <br>
  <br>
MESSAGE: <br>
  ${data.message}
  
  `;
  return {
    subject: 'A WEBSITE CONTACT EMAIL HAS BEEN SENT',
    html: htmlMessage,
    text: 'A WEBSITE CONTACT EMAIL HAS BEEN SENT'
  };
};

export default sendContactEmail;
