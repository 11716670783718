import React, { Fragment } from 'react';
import adoptWord from '../../media/Adopt_App_Oct_2019.doc';
import adoptPDF from '../../media/Adopt_App_Oct_2019.pdf';

const InternetExplorer = props => {
  return (
    <Fragment>
      <div className="bg-dark">
        <div className="container bg-dark">
          <div className="card text-center p-2 rounded shadow mx-2">
            <h1 className="text-center text-danger">ATTENTION</h1>
            <p className="p-3 font-weight-bold">
              Thank you for your interest in our rescue. Unfortunately your
              current browser is not compatible with our site.
              <br></br>
              If you would like to view our site and fill out the online
              adoption application you will need to utilize a compatible
              browser. Some compatible browsers are Microsoft Edge, Google
              Chrome, Firefox, Safari and most mobile phones and tablet
              browsers. Please feel free to vist http://puppypawsrescue.org with
              one of these browsers.
            </p>
          </div>
          <div className="card text-center p-2 rounded shadow mx-2 mt-4">
            <h4>Download Applications</h4>
            <p>
              If you would like to apply for one of our pets and don't have
              access to one of the above compatible browsers then you can
              download one of the below applications using the below links and
              email it to applications@puppypawsrescue.org
            </p>
            <div className="row my-2">
              <div className="col-md-2"></div>
              <div className="col-md-7">
                <a href={adoptWord}>
                  <i
                    className="fa fa-file-word-o fa-5x text-primary pr-1"
                    aria-hidden="true"
                  ></i>
                </a>
                <a href={adoptPDF}>
                  <i
                    className="fa fa-file-pdf-o fa-5x ml-2 text-danger"
                    aria-hidden="true"
                  ></i>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default InternetExplorer;
