import React from 'react';
import Puppy from '../../media/puppygrass.jpg';
import '../../css/Hero.css';

const Hero = () => {
  return (
    <div className="hero_container">
      <div className="hero_image">
        <img src={Puppy} alt="" />
      </div>
      <div className="hero_callToAction">
        <h1>Don't Shop</h1>
        <h3>Rescue Instead</h3>
        <a href="/pets">
          <button className="btn btn-warning">AVAILABLE DOGS</button>
        </a>
      </div>
    </div>
  );
};

export default Hero;
