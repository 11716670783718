import {
  CREATE_BLOG,
  EDIT_BLOG,
  DELETE_BLOG,
  CLEAR_CURRENT,
  VIEW_BLOG,
  GET_BLOGS,
} from '../types';

const blogReducer = (state, action) => {
  switch (action.type) {
    case CREATE_BLOG:
      return {
        ...state,
        loading: false,
        submitComplete: true,
      };
    case GET_BLOGS:
      return {
        ...state,
        blogs: action.payload,
        loading: false,
      };
    case VIEW_BLOG:
      return {
        ...state,
        current: action.payload,
        loading: false,
      };
    case EDIT_BLOG:
      return {
        ...state,
        current: action.payload,
      };
    case DELETE_BLOG:
      return {
        ...state,
        blogs: state.blogs.filter((blog) => blog._id !== action.payload),
      };
    case CLEAR_CURRENT:
      return {
        ...state,
        current: null,
      };
    default:
      return state;
  }
};

export default blogReducer;
