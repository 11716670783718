import React, { Fragment, useEffect, useContext } from 'react';
import ReCAPTCHA from 'react-google-recaptcha';
import AppContext from '../../context/app/appContext';

export const FormFosterInterest = values => {
  const appContext = useContext(AppContext);
  const { isVerified, checkCaptcha } = appContext;

  const {
    application,
    onChange,
    cont,
    prev,
    handleCheckedBox,
    showAlert
  } = values;
  const {
    Interest_Adult,
    Interest_Puppy,
    Interest_Mom,
    Interest_Litter,
    Interest_Set2Pups,
    Interest_NoPref,
    Interest_Male,
    Interest_Female,
    Interest_Either,
    Interest_Large,
    Interest_Medium,
    Interest_Small,
    Interest_SzNoPref,
    Interest_AddNeeds,
    Foster_Length
  } = application;

  // Google Captcha Implementation
  const captchaID = '6Lf6EMMUAAAAACdn1-MLLtPckPVVFfyMLTnP6tF2';

  const on_change = e => {
    onChange(e);
  };

  const next_page = e => {
    e.preventDefault();
    if (isVerified) {
      cont();
      window.scrollTo(0, 0);
    } else {
      window.scrollTo(0, 0);
      showAlert(
        'PLEASE CHECK BOX BELOW PROVING YOUR ARE NOT A ROBOT',
        'warning'
      );
    }
  };

  let isChecked = e => {
    handleCheckedBox(e.target);
  };

  const isNotRobot = response => {
    checkCaptcha(response);
  };

  window.history.pushState({}, 'Foster', '/foster');

  useEffect(() => {
    window.onpopstate = function(ev) {
      prev();
    };
  }, [prev]);

  return (
    <Fragment>
      <div className="container my-1 border border-dark">
        <div>
          <h4 className="text-center mt-2">FOSTER APPLICATION</h4>
        </div>
        <form>
          <div className="container-fluid mt-1 mb-3 border border-dark">
            <div className="row bg-dark">
              <h6 className="text-center text-light align-middle my-1 col-lg-12">
                FOSTER INTEREST
              </h6>
            </div>
            <div>
              <div className="row pl-3">
                <h5 className="my-1">I AM INTERESTED IN FOSTERING:</h5>
                <span>
                  <p className="pl-2 mt-1">(check all that apply)</p>
                </span>
              </div>
            </div>
            <div className="row mx-2">
              <div className="form-check col-lg-2 col-xl-1">
                <input
                  type="checkbox"
                  className="form-check-input"
                  id="Interest_Adult"
                  value={Interest_Adult}
                  checked={Interest_Adult}
                  onChange={isChecked}
                />
                <label
                  className="form-check-label p-0 ml-1"
                  htmlFor="Interest_Adult"
                >
                  Adult
                </label>
              </div>
              <div className="form-check  col-lg-2 col-xl-1">
                <input
                  type="checkbox"
                  className="form-check-input"
                  id="Interest_Puppy"
                  value={Interest_Puppy}
                  checked={Interest_Puppy}
                  onChange={isChecked}
                />
                <label
                  className="form-check-label p-0 ml-1"
                  htmlFor="Interest_Puppy"
                >
                  Puppy
                </label>
              </div>
              <div className="form-check  col-lg-3 col-xl-2">
                <input
                  type="checkbox"
                  className="form-check-input"
                  id="Interest_Mom"
                  value={Interest_Mom}
                  checked={Interest_Mom}
                  onChange={isChecked}
                />
                <label
                  className="form-check-label p-0 ml-1"
                  htmlFor="Interest_Mom"
                >
                  Mom with babies
                </label>
              </div>
              <div className="form-check  col-lg-2 col-xl-2">
                <input
                  type="checkbox"
                  className="form-check-input"
                  id="Interest_Litter"
                  value={Interest_Litter}
                  checked={Interest_Litter}
                  onChange={isChecked}
                />
                <label
                  className="form-check-label p-0 ml-1"
                  htmlFor="Interest_Litter"
                >
                  Litter of puppies
                </label>
              </div>
              <div className="form-check  col-lg-2 col-xl-2">
                <input
                  type="checkbox"
                  className="form-check-input"
                  id="Interest_Set2Pups"
                  value={Interest_Set2Pups}
                  checked={Interest_Set2Pups}
                  onChange={isChecked}
                />
                <label
                  className="form-check-label p-0 ml-1"
                  htmlFor="Interest_Set2Pups"
                >
                  Set of two pups
                </label>
              </div>
              <div className="form-check col-lg-2">
                <input
                  type="checkbox"
                  className="form-check-input"
                  id="Interest_NoPref"
                  value={Interest_NoPref}
                  checked={Interest_NoPref}
                  onChange={isChecked}
                />
                <label
                  className="form-check-label p-0 ml-1"
                  htmlFor="Interest_NoPref"
                >
                  No Preference
                </label>
              </div>
            </div>
            <div className="row mt-1 pl-3">
              <h5>Gender preference</h5>
            </div>
            <div className="row">
              <div className="form-check  col-lg-2 col-xl-2">
                <input
                  type="checkbox"
                  className="Interest_Male"
                  id="Interest_Male"
                  value={Interest_Male}
                  checked={Interest_Male}
                  onChange={isChecked}
                />
                <label
                  className="form-check-label p-0 ml-1"
                  htmlFor="Interest_Male"
                >
                  Male
                </label>
              </div>
              <div className="form-check  col-lg-2 col-xl-2">
                <input
                  type="checkbox"
                  className="Interest_Female"
                  id="Interest_Female"
                  value={Interest_Female}
                  checked={Interest_Female}
                  onChange={isChecked}
                />
                <label
                  className="form-check-label p-0 ml-1"
                  htmlFor="Interest_Female"
                >
                  Female
                </label>
              </div>
              <div className="form-check  col-lg-2 col-xl-2">
                <input
                  type="checkbox"
                  className="Interest_Either"
                  id="Interest_Either"
                  value={Interest_Either}
                  checked={Interest_Either}
                  onChange={isChecked}
                />
                <label
                  className="form-check-label p-0 ml-1"
                  htmlFor="Interest_Either"
                >
                  Either
                </label>
              </div>
            </div>
            <div className="row pl-3 mt-1">
              <h5>Size preference</h5>
            </div>
            <div className="row">
              <div className="form-check  col-lg-2 col-xl-2">
                <input
                  type="checkbox"
                  className="Interest_Large"
                  id="Interest_Large"
                  value={Interest_Large}
                  checked={Interest_Large}
                  onChange={isChecked}
                />
                <label
                  className="form-check-label p-0 ml-1"
                  htmlFor="Interest_Large"
                >
                  Large
                </label>
              </div>
              <div className="form-check  col-lg-2 col-xl-2">
                <input
                  type="checkbox"
                  className="Interest_Medium"
                  id="Interest_Medium"
                  value={Interest_Medium}
                  checked={Interest_Medium}
                  onChange={isChecked}
                />
                <label
                  className="form-check-label p-0 ml-1"
                  htmlFor="Interest_Medium"
                >
                  Medium
                </label>
              </div>
              <div className="form-check  col-lg-2 col-xl-2">
                <input
                  type="checkbox"
                  className="Interest_Small"
                  id="Interest_Small"
                  value={Interest_Small}
                  checked={Interest_Small}
                  onChange={isChecked}
                />
                <label
                  className="form-check-label p-0 ml-1"
                  htmlFor="Interest_Small"
                >
                  Small
                </label>
              </div>
              <div className="form-check  col-lg-2 col-xl-2">
                <input
                  type="checkbox"
                  className="Interest_SzNoPref"
                  id="Interest_SzNoPref"
                  value={Interest_SzNoPref}
                  checked={Interest_SzNoPref}
                  onChange={isChecked}
                />
                <label
                  className="form-check-label p-0 ml-1"
                  htmlFor="Interest_SzNoPref"
                >
                  No Preference
                </label>
              </div>
            </div>
            <div className="form-group col-sm-12 col-md-6 col-lg-6 mt-1">
              <div className="row pl-3 mt-2">
                <label className="active" htmlFor="Interest_AddNeeds">
                  <h5>Please describe any additional preferences or needs:</h5>
                </label>
              </div>
              <div className="row">
                <textarea
                  id="Interest_AddNeeds"
                  type="text"
                  className="form-control"
                  rows="4"
                  value={Interest_AddNeeds}
                  onChange={on_change}
                  maxLength="1500"
                />
              </div>
            </div>
            <div className="row mt-2">
              <div className="form-group col-sm-12 col-md-6 col-lg-6 mt-1">
                <label className="active" htmlFor="Foster_Length">
                  <h5> How long can you foster? </h5>
                </label>
                <input
                  id="Foster_Length"
                  type="text"
                  className="form-control"
                  value={Foster_Length}
                  onChange={on_change}
                  maxLength="100"
                />
              </div>
              <div className="container">
                <div className="container col-md-5 col-lg-4 col-xl-3 mb-3">
                  <ReCAPTCHA sitekey={captchaID} onChange={isNotRobot} />
                </div>
              </div>
              <div className="container m-0">
                <div className="row">
                  <div className="col-6 pb-1">
                    <button className="btn btn-danger" href="/foster">
                      CANCEL
                    </button>
                  </div>
                  <div className="col-6 pb-1 text-right">
                    <button
                      type="button"
                      className="btn btn-dark block"
                      onClick={next_page}
                    >
                      CONTINUE
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
    </Fragment>
  );
};

export default FormFosterInterest;
