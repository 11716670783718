import React, { Fragment } from 'react';
import Logo from '../../media/puppypawslogo.jpg';

const Navbar = () => {
  // Internet Explorer 6-11
  var isIE = /*@cc_on!@*/ false || !!document.documentMode;

  const guestLinks = (
    <div className="navbar navbar-expand-md navbar-dark bg-dark">
      <div className=" col-md-3 col-lg-3 col-xl-3">
        <a className="navbar-brand" href="/">
          <img
            src={Logo}
            alt=""
            style={{ height: '140px', width: '140px', padding: '10px' }}
          />
        </a>

        <button
          className="navbar-toggler float-right mt-5 nav-max-width"
          type="button"
          data-toggle="collapse"
          data-target="#navbarSupportedContent"
          aria-controls="navbarSupportedContent"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span className="navbar-toggler-icon"></span>
        </button>
      </div>
      <div className=" col-lg-3 col-xl-4"></div>
      <div className="col-lg-8 col-xl-5">
        <div className="row">
          <div className=" col-lg-5 col-xl-6"></div>
          <div className="collapse navbar-collapse" id="navbarSupportedContent">
            <ul className="navbar-nav col-xl-6">
              <li className="nav-item">
                <a className="nav-link font-weight-bold" href="/">
                  HOME <span className="sr-only"></span>
                </a>
              </li>
              <li className="nav-item font-weight-bold">
                <a className="nav-link" href="/adopt">
                  ADOPT<span className="sr-only"></span>
                </a>
              </li>
              <li className="nav-item font-weight-bold">
                <a className="nav-link" href="/foster">
                  FOSTER
                </a>
              </li>

              <li className="nav-item font-weight-bold">
                <a className="nav-link" href="/about">
                  ABOUT
                </a>
              </li>
              <li className="nav-item">
                <a className="nav-link font-weight-bold" href="/contact">
                  CONTACT
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );

  const ieLinks = (
    <div className="navbar navbar-expand-lg navbar-dark bg-dark">
      <a className="navbar-brand" href="/">
        <img
          src={Logo}
          alt=""
          style={{ height: '140px', width: '140px', padding: '10px' }}
        />
      </a>
      <button
        className="navbar-toggler"
        type="button"
        data-toggle="collapse"
        data-target="#navbarSupportedContent"
        aria-controls="navbarSupportedContent"
        aria-expanded="false"
        aria-label="Toggle navigation"
      >
        <span className="navbar-toggler-icon"></span>
      </button>
      <div className="collapse navbar-collapse" id="navbarSupportedContent">
        <ul className="navbar-nav mr-2">
          <li className="nav-item">
            <a className="nav-link" href="/">
              Home <span className="sr-only"></span>
            </a>
          </li>
          <li className="nav-item">
            <a className="nav-link" href="/about">
              About PPR
            </a>
          </li>
        </ul>
      </div>
    </div>
  );

  return (
    <Fragment>
      <nav>{isIE ? ieLinks : guestLinks}</nav>
    </Fragment>
  );
};

export default Navbar;
