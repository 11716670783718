import {
  CREATE_TASK,
  EDIT_TASK,
  DELETE_TASK,
  GET_TASKS,
  GET_TASK,
} from '../types';

const taskReducer = (state, action) => {
  switch (action.type) {
    case CREATE_TASK:
      return {
        ...state,
        tasks: action.payload,
        taskLoading: false,
      };
    case GET_TASKS:
      return {
        ...state,
        tasks: action.payload,
        taskLoading: false,
      };
    case GET_TASK:
      return {
        ...state,
        tasks: action.payload,
        loading: false,
      };
    case EDIT_TASK:
      console.log(action.payload);
      return {
        ...state,
        tasks: state.tasks.map((task) =>
          task._id === action.payload._id ? action.payload : task
        ),
        taskLoading: false,
      };
    case DELETE_TASK:
      return {
        ...state,
        tasks: state.tasks.filter((task) => task._id !== action.payload),
      };
    default:
      return state;
  }
};

export default taskReducer;
