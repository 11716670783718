const sendOfflineApplication = (data) => {
  const htmlMessage = `
      <br>
      ${data.First_Name}, <br>
<p>Thank you for your interest in adoption through Puppy Paws Rescue. Please fill out the attached adoption application and email it to application@PuppyPawsRescue.org.</p>
<br>
Puppy Paws Rescue
      `;
  return {
    to: data.Email,
    subject: 'Puppy Paws Rescue Adoption Application',
    html: htmlMessage,
    text:
      'Please Fill out the attached Adoption application and email it to application@PuppyPawsRescue.org',
    attachments: [
      {
        filename: 'Adopt_App_Oct_2019.pdf',
        path: './client/src/media/Adopt_App_Oct_2019.pdf',
      },
    ],
  };
};

export default sendOfflineApplication;
