import React, { Fragment, useEffect } from 'react';

export const FormAdoptInterest = values => {
  const { application, onChange, cont, prev } = values;
  const { st_Choice, nd_Choice, Future_Dog2 } = application;

  const on_change = e => {
    onChange(e);
  };

  const next_page = e => {
    e.preventDefault();
    cont();
    window.scrollTo(0, 0);
  };

  const prev_page = e => {
    e.preventDefault();
    prev();
    window.scrollTo(0, 0);
  };

  window.history.pushState({}, 'Adopt', '/adopt');

  useEffect(() => {
    window.onpopstate = function(ev) {
      prev();
    };
  }, [prev]);

  return (
    <Fragment>
      <div className="container my-1 border border-dark">
        <div>
          <h4 className="text-center mt-2">ADOPTION APPLICATION</h4>
        </div>
        <form>
          <div className="container-fluid my-1 border border-dark">
            <div className="row bg-dark">
              <h6 className="text-center text-light align-middle my-1 col-lg-12">
                CURRENT INTEREST IN ADOPTING
              </h6>
            </div>
            <div>
              <h5>What pet are you interested in adopting from our rescue? </h5>
            </div>
            <div className="row">
              <div className="form-group col-sm-12 col-md-6 col-lg-6 mt-1">
                <label className="active" htmlFor="1st Choice">
                  1st Choice
                </label>
                <input
                  id="st_Choice"
                  type="text"
                  className="form-control"
                  value={st_Choice}
                  onChange={on_change}
                  maxLength="100"
                />
              </div>
              <div className="form-group col-sm-12 col-md-6 col-lg-6">
                <label className="active" htmlFor="2nd Choice">
                  2nd Choice
                </label>
                <input
                  id="nd_Choice"
                  type="text"
                  className="form-control"
                  value={nd_Choice}
                  onChange={on_change}
                  maxLength="100"
                />
              </div>
            </div>
            <div>
              <div className="form-group">
                <label className="active" htmlFor="Future_Dog2">
                  <h5>
                    Future Puppy Paws Rescue Dog - Please describe what you are
                    looking for?
                  </h5>
                </label>
                <textarea
                  id="Future_Dog2"
                  type="text"
                  className="form-control"
                  rows="4"
                  value={Future_Dog2}
                  onChange={on_change}
                  maxLength="1500"
                />
              </div>
            </div>
          </div>
          {/* ****************BUTTONS ARE BELOW HERE*********** */}
          <div className="container">
            <div className="row">
              <button
                type="button"
                className="btn btn-danger col-lg-2 mt-3 mb-3"
                onClick={prev_page}
              >
                <i className="fa fa-arrow-left p" aria-hidden="true" />
                Previous
              </button>
              <div className="col-lg-8"></div>
              <button
                type="button"
                className="btn btn-dark col-lg-2 mt-3 mb-3"
                onClick={next_page}
              >
                Continue
                <i className="fa fa-arrow-right p" aria-hidden="true" />
              </button>
            </div>
          </div>
        </form>
      </div>
    </Fragment>
  );
};

export default FormAdoptInterest;
