const Email = (data) => {
  const htmlMessage = `
  <br>
  A New Adoption Application have been Submitted 
  <br>
  <br>
  
  INTERESTED IN: <br>
  1st Choice: ${data.st_Choice}<br>
  2nd Choice: ${data.nd_Choice}<br>
  Future: ${data.Future_Dog2}<br>
  <br>
  <br>
  ADOPTER INFORMATION: <br>
  Name: ${data.First_Name} ${data.Last_Name}<br>
  Phone: ${data.Phone}<br>
  Email: ${data.Email}<br>
  <br>
  <br>
  
  FOR GT USE:
  OS Type: ${data.OS_Type}
  `;
  return {
    subject: 'New Adoption Application',
    html: htmlMessage,
    text: 'See Attached Adoption Application',
    attachments: [
      {
        filename: data.First_Name + ' ' + data.Last_Name + '-' + data.TimeStamp  + '.pdf',
        path: './pdf_files/' + data.First_Name + ' ' + data.Last_Name + '-' + data.TimeStamp + '.pdf',
      },
    ],
  };
};

export default Email;
