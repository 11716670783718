import React, { Fragment, useEffect, useContext } from 'react';
import ReCAPTCHA from 'react-google-recaptcha';
import AppContext from '../../context/app/appContext';

const FormAdoptDisclaimer = values => {
  const appContext = useContext(AppContext);
  const { prev, cont, showAlert } = values;
  const { isVerified, checkCaptcha } = appContext;

  // Google Captcha Implementation
  const captchaID = '6Lf6EMMUAAAAACdn1-MLLtPckPVVFfyMLTnP6tF2';

  window.history.pushState({}, 'Adopt', '/adopt');

  useEffect(() => {
    window.onpopstate = function(ev) {
      prev();
    };
  }, [prev]);

  const next_page = e => {
    e.preventDefault();
    if (isVerified) {
      cont();
      window.scrollTo(0, 0);
    } else {
      window.scrollTo(0, 0);
      showAlert(
        'PLEASE CHECK BOX BELOW PROVING YOUR ARE NOT A ROBOT',
        'warning'
      );
    }
  };

  const isNotRobot = response => {
    checkCaptcha(response);
  };

  return (
    <Fragment>
      <div className="bg-light p-2">
        <div className="container py-0 border border-dark bg-light">
          <div className="container-fluid my-1 ">
            <div className="row bg-danger align-middle">
              <h5 className="text-center text-light align-middle my-1 p-2 col-lg-12">
                APPLICATION DISCLAIMER
              </h5>
            </div>
            <p className="mt-3">
              Puppy Paws Rescue takes the processing of adoption applications
              very seriously. Prior to filling out the application please
              confirm the following:
            </p>
            <ol>
              <li>I am ready to adopt and so are all of my family members.</li>
              <li>
                I can make arrangements to let a puppy or young dog out until
                acclimated or old enough. Puppies should not be alone longer
                than their age in months plus 1 (e.g.: 3 months old puppy+1 = 4
                hours alone max at a time).
              </li>
              <li>I am willing to give it 2 weeks for a dog to adjust.</li>
              <li>
                I have spoken to my landlord or parents or other individuals
                that make decisions about allowing a dog into a home.
              </li>
              <li>
                I am calling my vet if I have or had animals in the past 5 years
                and I am allowing Puppy Paws Rescue to perform a veterinary
                check, which consists of basic vaccinations - Distemper Parvo
                (DHPP), Rabies, annual heart-worm test, dog spayed or neutered
                and on monthly heart-worm prevention.
              </li>
              <li>
                I am currently not in foreclosure nor in a situation to lose my
                home or residence.
              </li>
              <li>
                I am financially able to pay a $ 300 adoption donation, take a
                dog for a post adoption vet visit, and other cost that might
                incur when adding a dog to the household.
              </li>
            </ol>
            <div className="container col-md-5 col-lg-4 col-xl-3 mb-3">
              <ReCAPTCHA sitekey={captchaID} onChange={isNotRobot} />
            </div>
          </div>
        </div>

        <div className="container">
          <div className="row">
            <a
              href="/"
              className="btn btn-danger col-lg-2 mt-3 mb-3 align-middle"
            >
              I DON'T AGREE
            </a>

            <div className="col-lg-8"></div>
            <button
              type="button"
              className="btn btn-dark col-lg-2 mt-3 mb-3"
              onClick={next_page}
            >
              I AGREE
              <i className="fa fa-hand-paper-o p" aria-hidden="true" />
            </button>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default FormAdoptDisclaimer;
