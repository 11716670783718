import React from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import Alerts from './components/layout/Alerts';
import Navbar from './components/layout/Navbar';
import Footer from './components/layout/Footer';
import Home from './components/pages/Home';
import Adopt from './components/pages/Adopt';
import Foster from './components/pages/Foster';
// import FosterClosed from './components/pages/FosterClosed';
import Contact from './components/pages/Contact';
import Volunteer from './components/pages/Volunteer';
import Donate from './components/pages/Donate';
import PetfinderState from './context/petfinder/PetfinderState';
import AppState from './context/app/AppState';
import BlogState from './context/blog/BlogState';
import TaskState from './context/tasks/TaskState';
import AlertState from './context/alert/AlertState';
import About from './components/pages/About';
import Blog from './components/pages/Blog';
import BlogViewer from './components/pages/BlogViewer';
import Pets from './components/pets/Pets';
import SingePageAdoption from './components/forms/FormAdoptSinglePage';
import SingePageFoster from './components/forms/FormFosterSinglePage';
import setAuthToken from './utils/setAuthToken';
import FormApplicationTrouble from './components/forms/FormApplicationTrouble';
import ContractSubmission from './components/pages/ContractSubmission';
import './css/App.css';

if (localStorage.auth_token) {
  setAuthToken(localStorage.auth_token);
}

const App = () => {
  return (
    <AlertState>
      <BlogState>
        <TaskState>
          <PetfinderState>
            <AppState>
              <Router>
                <div className="App bg-light">
                  <Navbar />
                  <Alerts />
                  <Switch>
                    <Route exact path="/" component={Home} />
                    <Route
                      exact
                      path="/apptrouble"
                      component={FormApplicationTrouble}
                    />
                    <Route exact path="/adopt" component={Adopt} />
                    <Route
                      exact
                      path="/singlepageadopt"
                      component={SingePageAdoption}
                    />
                    <Route
                      exact
                      path="/singlepagefoster"
                      component={SingePageFoster}
                    />
                    <Route exact path="/about" component={About} />
                    <Route
                      exact
                      path="/contractSubmission"
                      component={ContractSubmission}
                    />

                    <Route exact path="/pets" component={Pets} />
                    <Route exact path="/foster" component={Foster} />
                    <Route exact path="/volunteer" component={Volunteer} />
                    <Route exact path="/donate" component={Donate} />
                    <Route exact path="/blog" component={Blog} />
                    <Route path="/blog/:id" component={BlogViewer} />
                    <Route path="/contact" component={Contact} />
                  </Switch>
                  <Footer />
                </div>
              </Router>
            </AppState>
          </PetfinderState>
        </TaskState>
      </BlogState>
    </AlertState>
  );
};

export default App;
