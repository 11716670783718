import React, { Fragment, useEffect } from 'react';

export const FormFosterReferences = values => {
  const { application, onChange, cont, prev } = values;
  const {
    Reference_1,
    Reference_2,
    Reference_1_Phone,
    Reference_2_Phone
  } = application;

  const on_change = e => {
    onChange(e);
  };

  const next_page = e => {
    e.preventDefault();
    cont();
    window.scrollTo(0, 0);
  };
  const prev_page = e => {
    e.preventDefault();
    prev();
    window.scrollTo(0, 0);
  };

  window.history.pushState({}, 'Foster', '/foster');

  useEffect(() => {
    window.onpopstate = function(ev) {
      prev();
    };
  }, [prev]);

  return (
    <Fragment>
      <div>
        <h4 className="text-center mt-2">FOSTER APPLICATION</h4>
      </div>
      <div className="container my-1 border border-dark">
        <div className="row bg-dark">
          <h6 className="text-center text-light align-middle my-1 col-lg-12">
            PERSONAL REFERENCES
          </h6>
        </div>
        <div className="container-fluid my-1 border border-dark">
          <div className="row mt-2">
            <h6 className="ml-1">
              Please provide two unrelated references (neighbor, friend, or
              co-worker) that we may contact:
            </h6>
          </div>
          <div className="row">
            <div className="form-group col-sm-12 col-md-8 col-lg-8">
              <label htmlFor="Reference_1">Reference 1 Name</label>
              <input
                id="Reference_1"
                type="text"
                className="form-control"
                value={Reference_1}
                onChange={on_change}
                maxLength="100"
              />
            </div>
            <div className="form-group col-sm-12 col-md-3 col-lg-3">
              <label htmlFor="Reference_1_Phone">Phone</label>
              <input
                id="Reference_1_Phone"
                type="Phone"
                className="form-control"
                value={Reference_1_Phone}
                onChange={on_change}
                maxLength="20"
              />
            </div>
          </div>
          <div className="row">
            <div className="form-group col-sm-12 col-md-8 col-lg-8">
              <label htmlFor="Reference_2">Reference 2 Name</label>
              <input
                id="Reference_2"
                type="text"
                className="form-control"
                value={Reference_2}
                onChange={on_change}
                maxLength="100"
              />
            </div>
            <div className="form-group col-sm-12 col-md-3 col-lg-3">
              <label htmlFor="Reference_2_Phone">Phone</label>
              <input
                id="Reference_2_Phone"
                type="phone"
                className="form-control"
                value={Reference_2_Phone}
                onChange={on_change}
                maxLength="20"
              />
            </div>
          </div>
        </div>
        {/* ****************BUTTONS ARE BELOW HERE*********** */}
        <div className="container">
          <div className="row">
            <button
              className="btn btn-danger col-lg-2 mt-3 mb-3"
              onClick={prev_page}
            >
              <i className="fa fa-arrow-left p" aria-hidden="true" />
              Previous
            </button>
            <div className="col-lg-8"></div>
            <button
              type="button"
              className="btn btn-dark col-lg-2 mt-3 mb-3"
              onClick={next_page}
            >
              Continue
              <i className="fa fa-arrow-right p" aria-hidden="true" />
            </button>
          </div>
        </div>
      </div>
    </Fragment>
  );
};
export default FormFosterReferences;
