import React, { Fragment, useContext, useState, useEffect } from 'react';
import ReCAPTCHA from 'react-google-recaptcha';
import AppContext from '../../context/app/appContext';
import AlertContext from '../../context/alert/alertContext';
import axios from 'axios';
import sendOfflineApplicationRequest from '../templates/sendOfflineApplicationRequest';
import sendOfflineApplication from '../templates/sendOfflineApplication';

const ApplicationTrouble = (props) => {
  const appContext = useContext(AppContext);
  const alertContext = useContext(AlertContext);
  const { isVerified, checkCaptcha } = appContext;

  const { setAlert } = alertContext;

  const [emailApplication, setEmailApplication] = useState({
    First_Name: '',
    Last_Name: '',
    Address: '',
    City: '',
    State: '',
    Zip_Code: '',
    phone: '',
    email: '',
    OS_Type: '',
    Browser: '',
    Device_Browser: '',
    Device_Type: '',
    Direct_Link: '',
    Details: '',
    Owner: { id: '35194048' },
    Owner_Id: '1045',
    Application_Type: 'Adoption',
    Application_Status: 'Email App Requested',
    Application_Current_Status: 'closed',
    Lead_Status: 'Email App Requested',
    Lead_Source: 'Email Applications',
  });

  useEffect(() => {
    setEmailApplication({
      ...emailApplication,
      OS_Type: navigator.appVersion,
      Browser: navigator.appName,
    });
    // eslint-disable-next-line
  }, []);

  const onChange = (e) => {
    setEmailApplication({ ...emailApplication, [e.target.id]: e.target.value });
  };

  // Google Captcha Implementation
  const captchaID = '6Lf6EMMUAAAAACdn1-MLLtPckPVVFfyMLTnP6tF2';

  const isNotRobot = (response) => {
    checkCaptcha(response);
  };

  const config = {
    headers: {
      'Content-Type': 'application/json',
    },
  };

  const sendMessage = () => {
    if (isVerified) {
      const message = sendOfflineApplicationRequest(emailApplication);
      const adoptMessage = sendOfflineApplication(emailApplication);
      axios.post('/api/sendContactEmail', message, config);
      axios.post(`/api/sendEmail/adopt_application`, adoptMessage, config);
      axios.post('/api/database', emailApplication, config);

      props.history.push('/');
      window.scroll(0, 0);
      setAlert(
        'Please check your email for the blank adoption application',
        'success'
      );
    } else {
      window.scroll(0, 0);
      setAlert('Confirm your not a robot!', 'danger');
    }
  };

  return (
    <Fragment>
      <div className="container my-5">
        <div className="card">
          <div className="card-content">
            <h2 className="text-center">
              OFFLINE ADOPTION APPLICATION REQUEST
            </h2>

            <p className="p-3 text-center">
              We are sorry you are having trouble with our online application.
              We would still like to get your adoption application so please
              fill out the below basic form and we will email you an application
              that you can fill out and email back to us.
            </p>
          </div>
        </div>
        <div className="card mt-2">
          <h4 className="text-center text-danger mt-2 mb-0">
            Request Application Via Email
          </h4>
          <p className="text-center text-danger m-0 p-0">
            (fill out the below form)
          </p>

          <form className="p-3">
            <div className="form-group mt-1">
              <label htmlFor="name">First Name</label>
              <input
                type="text"
                onChange={onChange}
                className="form-control"
                id="First_Name"
              />
            </div>
            <div className="form-group mt-1">
              <label htmlFor="name">Last Name</label>
              <input
                type="text"
                onChange={onChange}
                className="form-control"
                id="Last_Name"
              />
            </div>
            <div className="form-group">
              <label htmlFor="Phone">Phone</label>
              <input
                type="text"
                onChange={onChange}
                className="form-control"
                id="Phone"
              />
            </div>

            <div className="form-group">
              <label htmlFor="Email">Email address</label>
              <input
                type="email"
                onChange={onChange}
                className="form-control"
                id="Email"
              />
            </div>
            <div className="form-group">
              <label htmlFor="Street">Street Address</label>
              <input
                type="text"
                onChange={onChange}
                className="form-control"
                id="Street"
              />
            </div>
            <div className="form-group">
              <label htmlFor="City">City</label>
              <input
                type="text"
                onChange={onChange}
                className="form-control"
                id="City"
              />
            </div>
            <div className="form-group">
              <label htmlFor="State">State</label>
              <input
                type="text"
                onChange={onChange}
                className="form-control"
                id="State"
              />
            </div>
            <div className="form-group">
              <label htmlFor="Zip">Zip_Code</label>
              <input
                type="text"
                onChange={onChange}
                className="form-control"
                id="Zip_Code"
              />
            </div>
            <p>
              We would also like your help by giving us a little information
              about what device you used to try to fill out our online
              application. This will assist us in fixing our online application
              process.
            </p>
            <div className="form-group">
              <label htmlFor="Device_Type">
                What device did you use to fill out the application? (Example:
                Windows PC, MAC, iPhone, iPad, Android){' '}
              </label>
              <input
                type="text"
                onChange={onChange}
                className="form-control"
                id="Device_Type"
              />
            </div>
            <div className="form-group">
              <label htmlFor="Device_Browser">
                What browser did you use to fill out the application? (Example:
                Chrome, Safari, Edge, Internet Explorer, Firefox){' '}
              </label>
              <input
                type="text"
                onChange={onChange}
                className="form-control"
                id="Device_Browser"
              />
            </div>
            <div className="form-group">
              <label htmlFor="Direct_Link">
                Did you go to the site from Facebook Link or go directly to our
                website inside your browser?
              </label>
              <input
                type="text"
                onChange={onChange}
                className="form-control"
                id="Direct_Link"
              />
            </div>
            <div className="form-group">
              <label htmlFor="Details">
                Please provide any other details you can give. (example: what
                happen with the site? What was the last question that you
                answered before it failed? Did you try another device and got
                the same results?, Would you be interested in helping us by
                speaking to our web developer?)
              </label>
              <textarea
                type="text"
                onChange={onChange}
                className="form-control"
                id="Details"
                rows="4"
              />
            </div>
          </form>

          <div className="row mt-2">
            <div className="col-xs-5 col-sm-4"></div>
            <div className="col-5">
              <ReCAPTCHA sitekey={captchaID} onChange={isNotRobot} />
            </div>
          </div>

          <div className="row">
            <div className="col-xs-5 col-sm-3"></div>
            <div className="col-sm-6">
              <button
                type="submit"
                onClick={sendMessage}
                className="btn btn-secondary my-3 btn-block"
              >
                SUBMIT
              </button>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default ApplicationTrouble;
