import React from 'react';
import Training from '../../media/training.jpeg';

import '../../css/FosterBanner.css';

const FosterBanner = () => {
  return (
    <div className="foster_container">
      <div className="foster-left">
        <div className="foster_image">
          <img src={Training} alt="" />
        </div>
      </div>
      <div className="foster-right">
        <div className="foster_callToAction">
          <h1>BECOME A FOSTER</h1>
          <h3>Help find homes for dogs in need</h3>
          <a href="/foster">
            <button className="btn btn-warning">LEARN MORE</button>
          </a>
        </div>
      </div>
    </div>
  );
};

export default FosterBanner;
