import React, { useContext, useEffect, Fragment, useState } from 'react';
import axios from 'axios';
import PetItem from './PetItem';
import PetfinderContext from '../../context/petfinder/petfinderContext';

const Pets = () => {
  const { pets, loadingPets, getPets } = useContext(PetfinderContext);
  const [tokenLoaded, setTokenLoaded] = useState(false);

  useEffect(() => {
    // CHECK FOR AUTH TOKEN & GET ONE IF NOT
    const currentDate = new Date();
    const difference = currentDate - localStorage.getItem('token_age');

    if (difference > 3600000) {
      // GetToken();
      axios
        .get('/api/getToken')
        .then(function (res) {
          localStorage.setItem('token', res.data.data);
          const date = new Date();
          localStorage.setItem('token_age', date.getTime());
          setTokenLoaded(true);
        })
        .catch(function (error) {
          console.log(error);
        });
    } else {
      setTokenLoaded(true);
    }
  }, []);

  useEffect(() => {
    if (tokenLoaded) {
      getPets();
    }

    //eslint-disable-next-line
  }, [tokenLoaded]);

  if (loadingPets === false) {
    if (pets.length !== 0) {
      return (
        <Fragment>
          <h1 className="text-center">Currently Available For Adoption</h1>
          <div className="container">
            <div className="row">
              {pets.map((pet) => (
                <PetItem key={pet.id} pet={pet} />
              ))}
            </div>
          </div>
        </Fragment>
      );
    } else {
      return (
        <div className="container">
          <h2 className="text-center mx-5 mt-5 text-danger">
            Currently none of our dogs in rescue are ready for adoption. Check
            back soon to see when new dogs/puppies become available.
          </h2>
          <h3 className="text-center mb-5 text-danger">
            This site is updated near realtime.
          </h3>
        </div>
      );
    }
  } else {
    return (
      <div className="container">
        <div className="row mt-4">
          <div className="col-5"></div>
          <div className="col-2">
            <div
              className="spinner-border mt-5 mb-2text-secondary text-center"
              role="status"
            ></div>
          </div>
          <div className="col-5"></div>
        </div>

        <div className="row mb-4" style={{ height: '80vh' }}>
          <div className="col-3"></div>
          <h3 className="text-danger text-center font-weight-bold col-5">
            Loading...
          </h3>
          <div className="col-4"></div>
        </div>
      </div>
    );
  }
};

export default Pets;
