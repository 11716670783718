import React, { Fragment } from 'react';

const Navbar = () => {
  // Internet Explorer 6-11
  var isIE = /*@cc_on!@*/ false || !!document.documentMode;

  const guestLinks = (
    <div className=" bg-dark">
      <div className="container">
        <div className="row">
          <div className="col-xs-1 col-md-4 text-light">
            <h5 className="  mt-3 mb-0 pb-0">
              <i className="fa fa-paw pr-1"></i>PuppyPaws Rescue
            </h5>
            <p className="pt-0 mb-0 mt-0 ml-4 text-light">PO Box 45 </p>
            <p className="pt-0 mb-0 mt-0 ml-4 text-light">
              Huntingtown, MD 20639
            </p>
            <p className="pt-0 mb-0 mt-0 ml-4 text-light">
              information@puppypawsrescue.org
            </p>
            <p className="pt-0 pb-4 mb-0 mt-0 ml-4 text-light">
              (202) 630-5874
            </p>
            <p>
              {' '}
              Our adoptable pets are fostered in our foster family's homes
              around the local area. Fill out an Application to schedule a meet
              and greet.
            </p>
          </div>
          {/* ***************** COLUMN 2 **********************  */}

          <div className="col-xs-1 col-md-4">
            <div className="row">
              <h5 className="text-light pt-3">FOLLOW US: </h5>
            </div>
            <div className="row">
              <a href="https://www.facebook.com/pg/PuppyPawsRescue">
                <i className="fab fa-facebook-square fa-w-16 fa-3x text-light ml-5"></i>
              </a>
              <a href="https://www.instagram.com/puppypawsrescue/">
                <i className="fab fa-instagram fa-w-16 fa-3x text-light pl-2"></i>
              </a>
            </div>
          </div>

          {/* ***************** COLUMN 3 **********************  */}
          <div className="col-xs-1 col-sm-2 col-md-4 pl-0 pt-3">
            <div className="row">
              <div className="col-6 m-0 p-0"></div>
              <div className="col-6 mt-0 p-0 text-left">
                <a className="text-light d-none d-md-block" href="/pets">
                  Adoptable Pets
                </a>
              </div>
            </div>
            <div className="row">
              <div className="col-6 m-0 p-0"></div>

              <div className="col-6 mt-0 p-0 text-left">
                <a className="text-light d-none d-md-block" href="/adopt">
                  Adoption Application
                </a>
              </div>
            </div>

            <div className="row">
              <div className="col-6 m-0 p-0"></div>
              <div className="col-6 mt-0 p-0 text-left">
                <a className="text-light d-none d-md-block" href="/foster">
                  Foster Application
                </a>
              </div>
            </div>

            <div className="row">
              <div className="col-6 m-0 p-0"></div>
              <div className="col-6 mt-0 p-0 text-left">
                <a
                  className="text-light d-none d-md-block"
                  href="https://www.petfinder.com/search/pets-for-adoption/?shelter_id%5B0%5D=MD411&sort%5B0%5D=recently_added"
                >
                  PetFinder Page
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <p className="text-light text-center align-middle mr-2">
        {' '}
        PuppyPaw Rescue &copy; 2021
      </p>
    </div>
  );

  const ieLinks = (
    <div className=" bg-dark">
      <div className="container">
        <div className="row">
          <div className="col-xs-1 col-md-4 text-light">
            <h5 className="  mt-3 mb-0 pb-0">
              <i className="fa fa-paw pr-1"></i>PuppyPaws Rescue
            </h5>
            <p className="pt-0 mb-0 mt-0 ml-4 text-light">PO Box 45 </p>
            <p className="pt-0 mb-0 mt-0 ml-4 text-light">
              Huntingtown, MD 20639
            </p>
            <p className="pt-0 mb-0 mt-0 ml-4 text-light">
              information@puppypawsrescue.org
            </p>
            <p className="pt-0 pb-4 mb-0 mt-0 ml-4 text-light">
              (202) 630-5874
            </p>
          </div>
          {/* ***************** COLUMN 2 **********************  */}

          <div className="col-xs-1 col-md-4">
            <div className="row">
              <h5 className="text-light pt-3">FOLLOW US: </h5>
            </div>
            <div className="row">
              <a href="https://www.facebook.com/pg/PuppyPawsRescue">
                <i className="fab fa-facebook-square fa-w-16 fa-3x text-light ml-5"></i>
              </a>
              <a href="https://www.instagram.com/puppypawsrescue/">
                <i className="fab fa-instagram fa-w-16 fa-3x text-light pl-2"></i>
              </a>
            </div>
          </div>
        </div>
      </div>
      <p className="text-light text-center align-middle mr-2">
        {' '}
        PuppyPaw Rescue &copy; 2021
      </p>
    </div>
  );

  return <Fragment>{isIE ? ieLinks : guestLinks}</Fragment>;
};

export default Navbar;
