import React, { Fragment } from 'react';
import PropTypes from 'prop-types';

const PetItem = (pet) => {
  return (
    <Fragment>
      <div className="card col-xs-12 col-sm-6 col-md-4 col-lg-4 col-xl-3 border-light border-thick bg-dark text-light p-0">
        <div className="card-header bg-dark text-warning">
          <h5 className="card-title text-center pt-1">{pet.pet.name}</h5>
        </div>

        {pet.pet.photos[0] === undefined ? (
          <h3 className="text-warning text-center mb-5 mt-5">
            PHOTOS COMING SOON!
          </h3>
        ) : (
          <a href={pet.pet.url}>
            <img
              className="card-img-top"
              src={pet.pet.photos[0].medium}
              alt=""
            ></img>
          </a>
        )}

        <div className="card-body ">
          <div className="card-text text-center pb-1">
            <ul className="pet-list">
              <li>ID# {pet.pet.id}</li>
              <li>
                Breed: {pet.pet.breeds.primary} {pet.pet.breeds.secondary}
              </li>
              <li>Age: {pet.pet.age}</li>
              <li>Gender: {pet.pet.gender}</li>
            </ul>
          </div>
          <div className="text-center">
            <a
              href={pet.pet.url}
              className="btn btn-warning text-dark"
              rel="noopener noreferrer"
              target="_blank"
            >
              FULL DETAILS
            </a>
          </div>
          <div className="text-center pt-1">
            <a
              href="./adopt"
              className="btn btn-orange text-light ml-1"
              rel="noopener noreferrer"
              target="_blank"
            >
              APPLY TO ADOPT
            </a>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

PetItem.prototype = {
  pet: PropTypes.object.isRequired,
};

export default PetItem;
