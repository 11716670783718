import React, { Fragment, useState, useContext, useEffect } from 'react';
import AppState from '../../context/app/AppState';
import AlertContext from '../../context/alert/alertContext';
import AppContext from '../../context/app/appContext';
import FormFosterInterest from '../forms/FormFosterInterest';
import FormFosterApplicant from '../forms/FormFosterApplicant';
import FormFosterReferences from '../forms/FormFosterReferences';
import FormFosterPetHistory from '../forms/FormFosterPetHistory';
import FormFosterHousehold from '../forms/FormFosterHousehold';
import FormFosterSubmit from '../forms/FormFosterSubmit';
import fosterWord from '../../media/Foster_App_Oct_2019.doc';
import fosterPDF from '../../media/Foster_App_Oct_2019.pdf';
import Home from './Home';

const Foster = (props) => {
  const alertContext = useContext(AlertContext);
  const appContext = useContext(AppContext);

  function formatDate(string) {
    var options = {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
      timeZone: 'America/New_York',
    };
    return new Date(string).toLocaleDateString('en-US', options);
  }

  const today = formatDate(Date.now());

  const { setAlert } = alertContext;
  const { submitApp, submitComplete, nextStep, prevStep, step } = appContext;

  useEffect(() => {
    if (submitComplete || step === 0) {
      props.history.push('/');
    }
  }, [props.history, submitComplete, step]);

  // eslint-disable-next-line
  const [application, setApplication] = useState({
    Acclimate: '',
    Adoption_Aware_Fee: '',
    Adults_Aware: '',
    Age_of_Children: '',
    Allergies: '',
    Alone_Time: '',
    Apartment: '',
    Application_Status: 'New Application',
    Lead_Status: 'New Application',
    Application_Type: 'Foster',
    Lead_Source: 'Web Applications',
    Baby: '',
    Barks: '',
    Bills: '',
    Bladder_Issues: '',
    Chronic_Illness: '',
    City: '',
    CoApp_Full_Name: '',
    Condo: '',
    Convictions: '',
    Correction: '',
    Costs: '',
    County: '',
    Divorce: '',
    Email: '',
    Employer1: '',
    Employer2: '',
    Encourage: '',
    Exercise: '',
    Expense: '',
    First_Name: '',
    Future_dog2: '',
    Fence: '',
    Fence_Size: '',
    Foster_Length: '',
    Given_Away: '',
    Grooming: '',
    Hours1: '',
    Hours2: '',
    House: '',
    Housebreak: '',
    If_Allergies: '',
    If_Move: '',
    If_Die: '',
    Interest_Adult: '',
    Interest_Puppy: '',
    Interest_Mom: '',
    Interest_Litter: '',
    Interest_Set2Pups: '',
    Interest_NoPref: '',
    Interest_Male: '',
    Interest_Female: '',
    Interest_Either: '',
    Interest_Large: '',
    Interest_Medium: '',
    Interest_Small: '',
    Interest_SzNoPref: '',
    Interest_AddNeeds: '',
    Introductions: '',
    Killed_By_Vehicle: '',
    Kept_Where: '',
    Last_Name: '',
    Landlord_Name: '',
    Landlord_Phone: '',
    Lifetime_Commitment: '',
    Lived_Length_Home: '',
    Location_When_Home: '',
    Location_When_Not_Home: '',
    Location_When_Traveling: '',
    Location_When_Sleeping: '',
    Lost_Stolen: '',
    Medical_Care: '',
    Middle_Initial: '',
    Mobile: '',
    Mobile_Home: '',
    Moving: '',
    Move_Out_Of_State: '',
    Move_No_Pets: '',
    nd_Choice: '',
    Neighbors: '',
    None_Apply: '',
    Normal_Behavior: '',
    Nothing_Applies: '',
    Number_Household: '',
    Of_Age: '',
    Other_Adult_Names: '',
    Other_Checkbox: '',
    Other_Reasons: '',
    Other_Vet: '',
    Description: '',
    Outcome_Explain: '',
    Owner: { id: '35194048' },
    Owner_Id: '1045',
    Own_Rent: '',
    Partner_Allergies: '',
    Past_Addresses: '',
    Pet1_Age: '',
    Pet1_Breed: '',
    Pet1_Fixed: '',
    Pet1_How_long: '',
    Pet1_Kept: '',
    Pet1_Name: '',
    Pet1_Sex: '',
    Pet1_Vac: '',
    Pet1_WhereNow: '',
    Pet2_Age: '',
    Pet2_Breed: '',
    Pet2_Fixed: '',
    Pet2_How_long: '',
    Pet2_Kept: '',
    Pet2_Name: '',
    Pet2_Sex: '',
    Pet2_Vac: '',
    Pet2_WhereNow: '',
    Pet3_Age: '',
    Pet3_Breed: '',
    Pet3_Fixed: '',
    Pet3_How_long: '',
    Pet3_Kept: '',
    Pet3_Name: '',
    Pet3_Sex: '',
    Pet3_Vac: '',
    Pet3_WhereNow: '',
    Pet4_Age: '',
    Pet4_Breed: '',
    Pet4_Fixed: '',
    Pet4_How_long: '',
    Pet4_Kept: '',
    Pet4_Name: '',
    Pet4_Sex: '',
    Pet4_Vac: '',
    Pet4_WhereNow: '',
    Pet_Food: '',
    Pet_Incompatible: '',
    Pet_Not_As_Expected: '',
    Pets_Permitted: '',
    Phone: '',
    Policy_Acknowledged: '',
    Preperations: '',
    Primary_Caregiver: '',
    Reference_1: '',
    Reference_2: '',
    Reference_1_Phone: '',
    Reference_2_Phone: '',
    st_Choice: '',
    State: '',
    Street: '',
    submit_date: today,
    Townhouse: '',
    Training: '',
    Turned_In: '',
    Un_Trainable: '',
    Vac_Explain: '',
    Vet_Name: '',
    Vet_Number: '',
    Vet_Perm: '',
    When_Moving: '',
    Will_Give_Preventatives: '',
    Work: '',
    Zip_Code: '',
  });

  const singlePage = () => {
    window.scrollTo(0, 0);

    props.history.push('/singlepagefoster');
  };

  const cont = () => {
    nextStep(step);
    window.scroll(0, 0);
  };

  const prev = () => {
    prevStep(step);
  };

  const onChange = (e) => {
    setApplication({ ...application, [e.target.id]: e.target.value });
  };

  const handleCheckedBox = (prop) => {
    if (prop.value === '') {
      setApplication({ ...application, [prop.id]: 'checked' });
    } else {
      setApplication({ ...application, [prop.id]: '' });
    }
  };

  const showAlert = (msg, color) => {
    setAlert(msg, color);
  };

  switch (step) {
    case 1:
      return (
        <Fragment>
          <div className="container">
            <div className="row">
              <div className="col-lg-8">
                <div className="adoption-process card m-2">
                  <h3>FOSTER APPLICATION PROCESS</h3>
                  <ol>
                    <li>
                      If you would like to become a foster, submit our online
                      foster application by clicking the "Apply Here" link to
                      the right of this page or fill out one of the downloadable
                      versions of our application and submit it to{' '}
                      <a href="mailto:application@PuppyPawsRescue.org">
                        application@PuppyPawsRescue.org
                      </a>
                      .
                    </li>
                    <li>
                      Our team of volunteers will review your application. This
                      review includes:
                      <ol className="roman-numerals">
                        <li>
                          Review of vaccinations/spay or neuter/heartworm
                          prevention of existing or past pets by speaking with
                          your veterinarian. Prior to filling out the
                          application,{' '}
                          <span className="bold">
                            please call your vet and give your permission for us
                            to speak with them.
                          </span>
                        </li>
                        <li>Calling to speak with provided references.</li>

                        <li>
                          Phone interview to discuss your application and
                          clarify any questions.
                        </li>
                        <li>
                          Virtual or in-person home visits to ascertain the
                          current household environment. This includes the
                          living area for the pet including the outside space.
                        </li>
                        <li>
                          Once approved, a coordinator will be in contact with
                          you to discuss your availability and arranging your
                          first foster pet.
                        </li>
                      </ol>
                    </li>
                  </ol>
                </div>
              </div>
              <div className="col-lg-4">
                <div className="card text-center text-light bg-dark shadow rounded mx-2 mt-4 py-2">
                  <button
                    onClick={singlePage}
                    className="bg-dark text-light border-0"
                  >
                    <i className="fas fa-paw fa-5x m-2 text-warning"></i>
                    <h3 className="text-danger">APPLY HERE</h3>
                    <p className="text-warning">CLICK HERE</p>
                  </button>
                </div>
                <div className="card text-center p-2 rounded shadow mx-2 mt-4">
                  <div className="row my-2">
                    <div className="col-md-2"></div>
                    <div className="col-md-7">
                      <a href={fosterWord}>
                        <i
                          className="fa fa-file-word-o fa-3x text-primary pr-1"
                          aria-hidden="true"
                        ></i>
                      </a>
                      <a href={fosterPDF}>
                        <i
                          className="fa fa-file-pdf-o fa-3x ml-2 text-danger"
                          aria-hidden="true"
                        ></i>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Fragment>
      );
    // ****************************************************************
    case 2:
      return (
        <div className="container">
          <AppState>
            <FormFosterInterest
              application={application}
              onChange={onChange}
              cont={cont}
              prev={prev}
              showAlert={showAlert}
              handleCheckedBox={handleCheckedBox}
            />
          </AppState>
        </div>
      );
    case 3:
      return (
        <div className="container">
          <FormFosterApplicant
            step={step}
            application={application}
            onChange={onChange}
            cont={cont}
            prev={prev}
            showAlert={showAlert}
          />
        </div>
      );
    case 4:
      return (
        <div className="container">
          <FormFosterReferences
            step={step}
            application={application}
            onChange={onChange}
            cont={cont}
            prev={prev}
          />
        </div>
      );
    case 5:
      return (
        <div className="container">
          <FormFosterPetHistory
            step={step}
            application={application}
            handleCheckedBox={handleCheckedBox}
            onChange={onChange}
            cont={cont}
            prev={prev}
          />
        </div>
      );
    case 6:
      return (
        <div className="container">
          <FormFosterHousehold
            step={step}
            application={application}
            handleCheckedBox={handleCheckedBox}
            onChange={onChange}
            cont={cont}
            prev={prev}
          />
        </div>
      );

    case 7:
      return (
        <div className="container">
          <FormFosterSubmit
            step={step}
            application={application}
            handleCheckBox={handleCheckedBox}
            onChange={onChange}
            prev={prev}
            submitApp={submitApp}
            showAlert={showAlert}
          />
        </div>
      );
    case 99:
      return <Home />;
    default:
      return <FormFosterInterest />;
  }
};

export default Foster;
