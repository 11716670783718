import {
  SUBMIT_APP,
  SUBMIT_FAPP,
  SET_CURRENT,
  PREV_PAGE,
  NEXT_PAGE,
  GO_HOME,
  GET_APPS,
  GET_APP,
  APP_TAB,
  DELETE_APP,
  CHECK_CAPTCHA,
} from '../types';

const appReducer = (state, action) => {
  switch (action.type) {
    case SUBMIT_APP:
      return {
        ...state,
        app: action.payload,
        loading: false,
        submitComplete: true,
      };
    case SUBMIT_FAPP:
      return {
        ...state,
        app: action.payload,
        loading: false,
      };
    case GET_APPS:
      return {
        ...state,
        apps: action.payload,
        app: null,
        loading: false,
      };
    case DELETE_APP:
      return {
        ...state,
        apps: state.apps.filter((app) => app._id !== action.payload),
      };
    case SET_CURRENT:
      return {
        ...state,
        current: action.payload,
      };
    case NEXT_PAGE:
      return {
        ...state,
        step: state.step + 1,
      };
    case PREV_PAGE:
      return {
        ...state,
        step: state.step - 1,
      };
    case GO_HOME:
      return {
        ...state,
        step: 99,
      };
    case GET_APP:
      return {
        ...state,
        app: action.payload,
        appPageID: 'profile',
        loading: false,
      };
    case APP_TAB:
      return {
        ...state,
        appPageID: action.payload,
      };
    case CHECK_CAPTCHA:
      return {
        ...state,
        isVerified: action.payload,
        // isVerified: true,
      };

    default:
      return state;
  }
};

export default appReducer;
